import './scss/index.scss';
import React, { useState } from "react";
import { Table, Button, Modal, Checkbox, Progress, Pagination } from 'antd';
import { Sparklines, SparklinesLine, SparklinesSpots } from 'react-sparklines';
import _, { noop } from 'lodash';
import { useIntl } from 'react-intl';

import { useLocalePrefix } from '../../lang';
import { useGetResultsByUserid } from '../../result';
import { Loader } from '../../Loader';
import {
    ActionTableMenuComponent,
    ProfileLink,
} from '../../common';
import { ResultPreviewComponent } from './ResultPreview.component';
import { CustomMessageFormContainer } from '../../workspace/CustomMessageForm.container/CustomMessageForm.container';

export const StudentsTableComponent = (props) => {
    const {
        event,
        studentData,
        user,
        handleRemoveParticipant = noop,
        additionalColumns,
        actions,
        counter,
        limitPerPage = 0,
        onChangePage,
        isUsersLoading,
        ...other
    } = props;
    const { resultsByUserid, getResultsByUserid } = useGetResultsByUserid();
    const [localePrefix] = useLocalePrefix();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedAnswers, setSelectedAnswers] = useState([]);
    const [isLoading, setIsLoading] = useState(studentData);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const { formatMessage } = useIntl();
    const takeResByUser = (userId) => {
        setIsLoading(true)
        getResultsByUserid({
            variables: { filter: { userId: userId } },
            fetchPolicy: "network-only",
        });
        setIsLoading(false)
    }
    const showModal = (answers) => {
        setSelectedAnswers(answers);
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const getEmailsFromSelectedRows = () => {
        let fd = []
        if (selectedRowKeys?.length > 0 && studentData) {
            selectedRowKeys.forEach(key =>
                fd.push(studentData[key]?.email)
            )
            return fd;
        }
        return fd;
    };

    const options = { day: '2-digit', month: 'short', hour: '2-digit', minute: '2-digit', hour12: false };
    const columns = [
        {
            title: !!actions ? (
                <Checkbox
                    indeterminate={selectedRowKeys?.length > 0 && selectedRowKeys?.length < studentData?.length}
                    onChange={(e) => {
                        if (e.target.checked) {
                            setSelectedRowKeys(studentData.map(item => item.key));
                        } else {
                            setSelectedRowKeys([]);
                        }
                    }}
                    checked={selectedRowKeys?.length === studentData?.length}
                />
            ) : '#',
            dataIndex: 'checkbox',
            render: (_, record, index) => (
                <div className={`checkbox-container ${selectedRowKeys?.includes(record.key) ? 'selected' : ''}`}>
                    {!!actions && (
                        <Checkbox
                            className="checkbox"
                            checked={selectedRowKeys?.includes(record.key)}
                            onChange={() => {
                                const newSelectedRowKeys = selectedRowKeys?.includes(record.key)
                                    ? selectedRowKeys?.filter(key => key !== record.key)
                                    : [...selectedRowKeys, record.key];
                                setSelectedRowKeys(newSelectedRowKeys);
                            }}
                        />
                    )}
                    <span className="index">{index + 1}</span>
                </div>
            ),
        },
        {
            title: formatMessage({ id: "table_student" }),
            dataIndex: 'user',
            maxWidth: 100,
            sorter: (a, b) => _.lowerCase(a.user.name).localeCompare(_.lowerCase(b.user.name)),
            render: (user) => {
                return <ProfileLink
                    user={user}
                />
            },
            onHeaderCell: () => {
                return {
                    style: {
                        maxWidth: '200px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                    }
                };
            },
            onCell: () => {
                return {
                    style: {
                        maxWidth: '200px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                    }
                };
            },
        },

        {
            title: formatMessage({ id: "table_start" }),
            sorter: (a, b) => new Date(a.startDate) - new Date(b.startDate),
            dataIndex: 'startDate',
        },
        {
            title: formatMessage({ id: "table_visit" }),
            dataIndex: 'lastVisit',
            sorter: (a, b) => new Date(a.lastVisit) - new Date(b.lastVisit),

        },
        {
            title: formatMessage({ id: "table_notif" }),
            dataIndex: 'email',
            render: (email) => (
                < >
                    <span className="margin_right">
                        <CustomMessageFormContainer
                         {...other}
                         user={user}
                         emails={[email]} />
                    </span>
                    <small href={`mailto:${email}`}>{email}</small>
                </>
            ),
        },
        {
            title: formatMessage({ id: "table_completed" }),
            dataIndex: 'progress',
            sorter: (a, b) => a.progress.all - b.progress.all,
            defaultSortOrder: 'descend',
            sortDirections: ['descend', 'ascend'],
            render: (progress, record) => {
                const dates = record?.answers?.map(answer => new Date(answer.createDate).getTime()).sort();
                const options = { day: '2-digit', month: 'short' };
                const firstDate = dates.length > 0 ? new Date(dates[0]).toLocaleDateString('en-GB', options) : '';
                const lastDate = dates.length > 0 ? new Date(dates[dates.length - 1]).toLocaleDateString('en-GB', options) : '';
                return (

                    <div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Button type='link' style={{ marginRight: 8 }}
                                onClick={() => {
                                    takeResByUser(progress.userId)
                                    showModal(progress.answers)
                                }
                                }>{progress?.all || record?.results} </Button>

                            {progress?.all ?
                                <Progress percent={progress.progress} size="small" status="active" className="inline-el" />
                                : null}
                        </div>
                        <Sparklines data={dates} width={100} height={20}>
                            <SparklinesLine color="#E7522E" />
                            <SparklinesSpots />
                        </Sparklines>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <small className="text_secondary">{firstDate}</small>
                            <small className="text_secondary">{lastDate}</small>
                        </div>
                    </div>
                )

            },
        },
        ...additionalColumns
    ];

    return (
        <>
            <div className="table-wrapper"  
              >
                {selectedRowKeys?.length > 0 && !!actions && <ActionTableMenuComponent actions={actions}
                    emails={getEmailsFromSelectedRows()}
                    {...other}
                />}
                <Table
                    dataSource={studentData || []}
                    columns={columns}
                    pagination={false}
                    scroll={{ x: 800, }}
                    rowClassName="align-top"
                    // locale={{ emptyText: formatMessage({id: })'No data available' }}
                />
                <Pagination defaultCurrent={1}
                            total={counter}
                            pageSize={limitPerPage}
                            hideOnSinglePage={true}
                            showSizeChanger={false}
                            onChange={onChangePage}
                            disabled={isUsersLoading}/>
            </div>
            <Modal
                title="Completed Lessons:"
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                {resultsByUserid ? (
                    selectedAnswers?.length > 0 ? 
                        selectedAnswers.map((answer, index) => {
                            let result = answer ? resultsByUserid.find(res => res.task.id === answer.taskId) : resultsByUserid;
                            return (
                                <div key={index}>
                                    <ResultPreviewComponent
                                        result={result}
                                        localePrefix={localePrefix}
                                        options={options}
                                        event={event}
                                    />
                                </div>
                            )
                        }) : 
                        resultsByUserid.map((result, index) => {
                            return (
                                <div key={index}>
                                    <ResultPreviewComponent
                                        result={result}
                                        localePrefix={localePrefix}
                                        options={options}
                                        event={event}
                                    />
                                </div>
                            )
                        })
                ):
                <Loader/>
                }
            </Modal>
        </>
    );
}
