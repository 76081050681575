import React from 'react';
import { useIntl } from 'react-intl';

export const TaskProgressComponent = ({ backgroundColor, progressColor, progressPercentage, completedCount, allCount }) => {
    const { formatMessage } = useIntl();

    return (
        <div className="wrapper_progress_fixed">
            <div className='wrapper_progress' style={{ backgroundColor }}>
                <small className='progress_label'>
                    {completedCount}/{allCount}—{formatMessage({ id: 'form_is_steps' })}
                </small>
                <div className='progress_short' style={{ backgroundColor: progressColor, width: `${progressPercentage}%` }}>
                    <div className='progress_short' style={{ backgroundColor: progressColor, width: `${progressPercentage}%` }}></div>
                </div>
            </div>
        </div>
    );
};
