import React, { useState } from 'react';
import {
    getCurrentEventDate,
    useDateData,
} from '../../common';
import { useLocalePrefix } from '../../lang';
import { EventCardComponent } from '../EventCard.component';
import { EventListComponent } from '../EventList.component';
import { EventCurriculumItemComponent } from '../EventCurriculumItem.component';
import { EventCurriculumComponent } from '../EventCurriculum.component';
import { getIsLive } from '../../common/utils/getCurrentEventDate';
import { getDegColor } from '../../common/';
import { useEditEvent } from '../hooks/useEditEvent';

import moment from 'moment';

export const EventCardContainer = (props) => {
    const {
        event,
        eventId,
        id,
        user,
        subject,
        livePeriod,
        currencyStorageCode,
        levelList,
        cardsContainer,
        paymentData,
        isSubscription,
        type,
        ...other
    } = props;
    const { editEvent } = useEditEvent();
    const [draft, setDraft] = useState(event.isDraft)
    const getDateData = useDateData();
    const currentDateData = getCurrentEventDate(event?.timeTable);
    const currentDate = currentDateData?.data && getDateData(currentDateData?.data.dateUnix);
    const handlePublish = async (e) => {
        e.stopPropagation();
        e.preventDefault();
        let state = draft;
        const newData = {
            subjectIds: event?.subjects?.map(subject => subject.id),
            taskIds: event?.tasks?.map(task => task.id),
            studentsLimit: event.studentsLimit,
            isDraft: !draft,
            listTimeData: event.listTimeData || [],
            galleriesInEvent: event.galleriesInEvent,
            type: event.type,
            tagIds: event?.tags?.map((tag) => tag?.id)
        };

        const editedEvent = (
            await editEvent({
                variables: {
                    eventId: event.id,
                    eventData: newData,
                },
            })
        ).data?.event;
        setDraft(!draft)
    }
    const isLive = getIsLive(moment(event?.nextDate).valueOf(), Date.now());
    const imgUrl = event.galleriesInEvent?.filter((gInE) => gInE?.gallery?.type === 'PHOTO')[0]?.gallery?.source;

    const { subjects, tags } = event;
    const tagData = tags?.[0] || subjects?.[0];
    const tagDataColor = tagData?.subject?.color || tagData?.color
    const cardColor = { backgroundColor: tagDataColor || '#fff' };
    const tagColor = cardColor === '#fff' ? { backgroundColor: '#000' } : cardColor;
    const filteredList = [];

    levelList &&
        event.levels?.forEach((id) => {
            const t = levelList.find((item) => item.id === id);
            if (t) {
                filteredList.push(t);
            }
        });
    let eventID = eventId || event.id;
    return (
        <>
            {/* regular card */}
            {type === 'card' && (
                <EventCardComponent
                    cardColor={cardColor}
                    tagColor={tagColor}
                    event={event}
                    tagData={tagData}
                    eventID={eventID}
                    imgUrl={imgUrl}
                    currentDate={currentDate}
                    isLive={isLive}
                    filteredList={filteredList}
                    user={user}
                    deg={getDegColor(cardColor.backgroundColor)}
                    handlePublish={handlePublish}
                    draft={draft}
                    {...other}
                />
            )}
            {/* list of events */}
            {type === 'list' && (
                <EventListComponent
                    cardColor={cardColor}
                    tagColor={tagColor}
                    event={event}
                    tagData={tagData}
                    eventID={eventID}
                    imgUrl={imgUrl}
                    currentDate={currentDate}
                    isLive={isLive}
                    filteredList={filteredList}
                    user={user}
                    deg={getDegColor(cardColor.backgroundColor)}
                    handlePublish={handlePublish}
                    draft={draft}
                    {...other}
                />
            )}
            {/* list of tasks in learning objectives */}
            {type === 'list-item' && (
                <EventCurriculumItemComponent
                    cardColor={cardColor}
                    tagColor={tagColor}
                    event={event}
                    eventID={eventID}
                    imgUrl={imgUrl}
                    tagData={tagData}
                    currentDate={currentDate}
                    filteredList={filteredList}
                    user={user}
                    {...other}
                />
            )}
            {/* list of tasks in bundle*/}
            {type === 'list-curriculum' && (
                <EventCurriculumComponent
                    cardColor={cardColor}
                    tagColor={tagColor}
                    event={event}
                    eventID={eventID}
                    imgUrl={imgUrl}
                    tagData={tagData}
                    currentDate={currentDate}
                    filteredList={filteredList}
                    user={user}
                    {...other}
                />
            )}
        </>
    );
};
