import React, { useState } from "react";
import { useIntl } from "react-intl";
import { noop } from "lodash";
import { Button, Spin } from "antd";
import "./scss/index.scss";

import { WysiwygComponent, Svg } from "../../common";
import { ReactMarkdownComponent } from '../../task/ReactMarkdown.component/ReactMarkdown.component';

export const FreeResultFormComponent = (props) => {
    const {
        loadingUpdateResult,
        explainer,
        result,
        setResult,
        onUpdateResult = noop,
        setShowFreeResultForm,
    } = props;

    const setupFileList = (cover) => {
        return [{
            uid: cover.id,
            name: cover?.title || cover.source,
            url: cover.source,
        }]
    }
    const { formatMessage } = useIntl();
    const [fileList, setFileList] = useState(result?.cover?.id && setupFileList(result?.cover));
    const [isEditing, setIsEditing] = useState(false);

    return (
        <Spin spinning={loadingUpdateResult}>
            <div className="wrapper_inner-section">
                <div className="task-results__form">
                    {!isEditing ? (
                        <>
                            <Button
                                type="text"
                                onClick={() => setIsEditing(true)}
                                size="small"
                                className='link-btn_light '

                            >
                                <Svg
                                    name=':icon/edit'
                                    className='icon_16'
                                />
                                <span>
                                    {formatMessage({
                                        id: "notes",
                                    })}
                                </span>
                            </Button>
                            <ReactMarkdownComponent children={result?.description} />

                        </>
                    ) : (
                        <>
                   
                                <WysiwygComponent
                                    showToolbar={false}
                                    onChangeContent={(description) =>
                                        setResult((prev) => ({
                                            ...prev,
                                            description,
                                        }))
                                    }
                                    placeholder={formatMessage({
                                        id: "save_notes"
                                    })}
                                    editorClassNames="task-results__form__input wrapper_inner-section"
                                    initialState={result?.description}
                                />
                       
                            <Button
                                type="text"
                                size="small"
                                onClick={() => {
                                    setShowFreeResultForm((prev) => !prev);
                                    onUpdateResult(null, '', fileList);
                                    setIsEditing(false);
                                }}
                            >
                                <span>
                                    {formatMessage({
                                        id: "form_button_save_answer",
                                    })}
                                </span>
                            </Button>
                        </>
                    )}
                </div>

                {explainer && (
                    <p className="task-form__answer">{explainer}</p>
                )}
            </div>
        </Spin>
    );
};