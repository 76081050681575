/* eslint-disable react-hooks/exhaustive-deps */
import './scss/index.scss';

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { isEmpty, noop, omitBy } from 'lodash';
import { Button, Form, Input, Select, Space, Spin, Switch } from 'antd';
import classnames from 'classnames';
import {
    DeleteFilled,
    MinusCircleOutlined,
    PlusOutlined,
} from '@ant-design/icons';

import {
    Svg,
    WysiwygComponent,
    UploadImgComponent,
    VideoIframe,
    VimeoPlayer,
    youtubeParser,
    useTrackClick,
    TitleComponent,
    getConfigByKey,
    useIsAllow,
} from '../../common';
import { Loader } from '../../Loader';
import { SortQuestionsComponent } from '../SortQuestions.component';
import { QuestionExplanationComponent } from '../QuestionExplanation.component';
import { useGetTagsPrediction } from '../hooks/useGetTagsPrediction';
import { useGetTask } from '../hooks/useGetTask';
import { useCreateTask } from '../hooks/useCreateTask';
import { useEditTask } from '../hooks/useEditTask';
import { useDeleteTask } from '../hooks/useDeleteTask';
import { useLocalePrefix } from '../../lang';
import { GetTaskEdit } from '../graphQL/queries/GetTaskEdit';
import { useCreateTags } from '../hooks/useCreateTags';
import { Thread } from "../Thread/Thread";

const TYPES_QUESTION = [
    {
        label: 'Multi Select Quiz',
        value: 'MULTI_SELECT',
    },
    {
        label: 'Single Select Quiz',
        value: 'SINGLE_SELECT',
    },
    {
        label: 'Text',
        value: 'TEXT',
    },
];

const { TextArea } = Input;

export const EditTask = (props) => {
    const history = useHistory();
    const location = useLocation();
    const { onSubmitCallback = noop, user, workspace = {}, ...other } = props;
    const { id } = useParams();
    const { formatMessage } = useIntl();
    const {
        tags,
        tagsPrediction,
        loading: loadingGetTagsPrediction,
    } = useGetTagsPrediction();
    const { task, getTask } = useGetTask({}, GetTaskEdit);
    const {
        createTask,
        loading: loadingCreateTask,
        task: taskCreated,
    } = useCreateTask();
    const {
        editTask,
        loading: loadingEditTask,
        task: taskEdited,
    } = useEditTask();

    const { createTags } = useCreateTags();
    const { deleteTask, loading: loadingDeleteTask } = useDeleteTask();
    const isEditMode = useMemo(() => !!id, [id]);
    const isLoading = useMemo(() => loadingEditTask, [loadingEditTask]);
    const [form] = Form.useForm();
    const [formTask, setFormTask] = useState();
    const [localePrefix] = useLocalePrefix();
    const trackClick = useTrackClick();
    const [taskInEvents, setTaskInEvents] = useState([]);
    const [coverType, setCoverType] = useState();
    const [coverImage, setCoverImage] = useState();
    const [questionsErrors, setQuestionsErrors] = useState();
    const [activeTab, setActiveTab] = useState('video');
    const [initialTags, setInitialTags] = useState();
    const [sortMode, setSortMode] = useState();
    const [stepId, setStepId] = useState(task?.questions?.[0]?.id);
    const [buttonLabel, setButtonLabel] = useState(
        !task || task.isDraft ? 'form_button_save' : 'form_button_add_task'
    );
    const { id: uId, role } = user || {};
    const [maxTagsLimit, setMaxTagsLimit] = useState(5);
    const getMaxTagLimit = useCallback(async () => {
        const data = await getConfigByKey('task_max_tags_count');
        setMaxTagsLimit(+data?._value);
    }, [getConfigByKey]);

    const tagsList = useMemo(() => tags || initialTags, [tags, initialTags]);
    const useIsAllowd = useIsAllow();
    const isAllow = useIsAllowd("PRO");

    const handleSetActiveTab = async (tab) => {
        setActiveTab(tab);
    };

    const handleRedirectToSkills = () => {
        history.push(`${localePrefix}/tutorials`);
        return null;
    };
    const getCover = useCallback(
        (formData) => {
            const { video, coverImage, timestamps: formTimestamps, coverSummary } = formData;

            const source = coverType === 'YOUTUBE'
                ? youtubeParser(video)
                : coverType === 'VIDEO' ? video
                    : coverType === 'VIMEO' ? video
                        : coverImage;
            const fallBack = isEditMode ? { cover: null } : {};
            const timestampFormsData =
                !!formTimestamps &&
                formTimestamps.filter(
                    (item) => !!item?.title && !!item?.timestamp
                );
            const timestampsData =
                timestampFormsData && timestampFormsData.length
                    ? { timestamps: timestampFormsData }
                    : {};

            return source
                ? {
                    cover: {
                        type: coverType,
                        summary: coverSummary || null,
                        source,
                        ...timestampsData,
                    },
                }
                : fallBack;
        },
        [coverType]
    );

    const handleSortQuestions = (data) => {
        form.setFieldsValue({ questions: data });
        setFormTask((prev) => ({ ...prev, questions: data }));
        setSortMode(false);
    };

    const onRemoveQuestion = () => {
        const questions = form.getFieldValue('questions').map((q, i) => {
            q.order = i;
            return q;
        });
        handleSortQuestions(questions);
    };

    useEffect(() => {
        if (uId) {
            if (isEditMode) {
                (async () => await getTask({
                    variables: {taskId: Number(id)}
                }))();
            }
        }
    }, []);

    useEffect(() => {
        if (location.state?.eventId) {
            setTaskInEvents([
                {
                    eventId: location.state?.eventId,
                },
            ]);
        }
        getMaxTagLimit();
    }, [location.state?.eventId]);

    useEffect(() => {
        if (task) {
            // const {id: roleId } = role;
            const { id: roleId = 2 } = role || {};
            const { creatorId } = task;
            if (creatorId === uId || roleId === 1) {
                (async () => {
                    await setInitialTags(task.tags);
                    const tagIds = task.tags.map((tag) => tag.id.toString());
                    const { cover, taskInEvents } = task;
                    const taskCoverType = cover?.type;
                    setCoverType(taskCoverType);
                    const video =
                        cover && taskCoverType === 'YOUTUBE'
                            ? {
                                video: youtubeParser(cover?.source),
                                timestamps: cover?.timestamps,
                            }
                            : {
                                video: cover?.source,
                            };

                    if (cover && taskCoverType === "PHOTO") {
                        form.setFieldsValue({ coverImage: cover?.source });
                        setCoverImage([
                            {
                                url: cover?.source,
                                thumbUrl: cover?.source,
                            },
                        ]);
                    }
                    if (cover?.summary) {
                        video.coverSummary = cover?.summary;
                    }

                    setFormTask({ ...task, tagIds, ...video });
                    if (taskInEvents?.length) {
                        setTaskInEvents(taskInEvents);
                    }
                })();
            } else {
                handleRedirectToSkills();
            }
        }
    }, [task]);

    useEffect(() => {
        const { id, alias } = taskCreated || taskEdited || {};
        if (id) {
            history.push(`${localePrefix}/tutorials/${alias}-${id}`);
        }
    }, [taskCreated, taskEdited]);

    if (!uId) {
        return handleRedirectToSkills();
    }

    const handleChangeContent = (content) => {
        form.setFieldsValue({
            description: content,
        });
    };
    const isValidQuestions = (questions) => {
        if (!questions?.length) {
            setQuestionsErrors(null);
            return true;
        }

        let isValid = true;
        const errors = {};

        questions?.forEach((opt, i) => {
            if (opt.type !== 'TEXT' && !opt.options) {
                errors[i] = formatMessage({ id: 'form_question_is_required' });
                isValid = false;
                return;
            }

            if (opt.type === 'SINGLE_SELECT') {
                const hasSelectedLength = opt.options.filter(
                    (q) => !!q?.isCorrect
                ).length;

                if (!hasSelectedLength) {
                    errors[i] = formatMessage({
                        id: 'form_one_is_right',
                    });
                    isValid = false;
                }
            }

            if (opt.type === 'MULTI_SELECT') {
                const hasSelected = opt.options.find((q) => !!q?.isCorrect);
                if (!hasSelected) {
                    errors[i] = formatMessage({ id: 'form_min_one_is_right' });
                    isValid = false;
                }
            }
        });
        setQuestionsErrors(errors);
        return isValid;
    };
    const getTags = async (list) => {
        if (!list) return {};
        const newTags = [];
        const oldTags = [];

        list?.forEach((t) => {
            const cT = Number(t);
            if (isNaN(cT)) {
                newTags.push(t.trim().replace(/\s+/g, ' '));
            } else {
                oldTags.push(cT);
            }
        });

        let tags = [];

        if (newTags.length) {
            const resp = await createTags(newTags);
            tags = resp.map((tag) => tag.id);
        }

        return { tagIds: [...oldTags, ...tags] };
    };
    const handleFormSubmit = async (values) => {
        const { questions, tagIds, isDraft, isFree, isAutogenerated, source, summary } = values;
        const { coverSummary, ...taskData } = values;

        if (!isValidQuestions(questions)) {
            return;
        }
        const convertedTagIds = await getTags(tagIds);

        const convertedQuestions = {
            questions: !questions?.length
                ? []
                : questions.map((q) => ({
                    ...q,
                    options:
                        q.options &&
                        q.options.map((o) => ({
                            ...o,
                            isCorrect: !!o.isCorrect,
                        })),
                })),
        };

        const coverData = getCover(values);

        if (!task) {
            await createTask({
                variables: {
                    taskData: {
                        ...omitBy(taskData, isEmpty),
                        ...coverData,
                        video: undefined,
                        coverImage: undefined,
                        taskInEvents,
                        timestamps: undefined,
                        isFree: !!isFree,
                        isAutogenerated: isAutogenerated,
                        nextTaskId: !!taskData?.nextTaskId
                            ? parseInt(taskData?.nextTaskId, 10)
                            : null,
                        ...convertedQuestions,
                        ...convertedTagIds,
                        ...(workspace?.id && { workspaceId: workspace?.id }),
                    },
                },
            });
        } else {
            await editTask({
                variables: {
                    taskId: task.id,
                    taskData: {
                        ...omitBy(taskData, isEmpty),
                        ...coverData,
                        coverImage: undefined,
                        video: undefined,
                        timestamps: undefined,
                        isFree: !!isFree,
                        isDraft,
                        isAutogenerated: isAutogenerated,
                        source: source || null,
                        summary: summary || null,
                        description: !!taskData.description
                            ? taskData.description
                            : null,
                        nextTaskId: !!taskData?.nextTaskId
                            ? parseInt(taskData?.nextTaskId, 10)
                            : null,
                        ...convertedQuestions,
                        ...convertedTagIds,
                        alias: null,
                        ...(workspace?.id && { workspaceId: workspace?.id }),
                    },
                },
            });
        }

        form.resetFields();
        setFormTask(null);
        onSubmitCallback();

        trackClick(
            'add-task',
            task?.id || 'add-task',
            `https://tutorials/edit-task/`
        );
    };
    const handleSetCoverImage = (data) => {
        const { response } = data?.[0] || {};
        if (response?.url) {
            form.setFieldsValue({ coverImage: response?.url });
            setCoverType('PHOTO');
        }

        if (!data) {
            form.setFieldsValue({ coverImage: null });

            if (formTask?.video) {
                if (formTask?.video.includes('vimeo')) {
                    setCoverType('VIMEO');
                }
                if (formTask?.video.includes('youtube')) {
                    setCoverType('YOUTUBE');
                } else {
                    setCoverType('VIDEO');
                }
            } else {
                setCoverType('YOUTUBE');
            }
        }
    };
    const handleRemoveImage = (item) => {
        form.setFieldsValue({ coverImage: null });
        setCoverImage(null);
    }
    const handleDelete = async () => {
        try {
            await deleteTask({ variables: { taskId: task.id } });
            history.push(`${localePrefix}/`);
        } catch (e) {
            console.warn(e);
        }
    };
    const onChange = () => {
        let valuesNew = (values) => {
            if (values.video) {
                if (values.video.includes('vimeo')) {
                    setCoverType('VIMEO');
                }
                if (values.video.includes('youtube')) {
                    setCoverType('YOUTUBE');
                    values.video = values.video && youtubeParser(values.video);
                } else {
                    setCoverType('VIDEO');
                }
            } else if (values.coverImage) {
                setCoverType("PHOTO");
            }
            return values;
        };
        setFormTask(valuesNew(form.getFieldsValue()));
    };

    if (isLoading || (isEditMode && task && !formTask)) return <Loader />;

    const getQuestionContent = (key) => task?.questions?.[key]?.question || '';
    const getTabClassNames = (tab) =>
        classnames('edit-task-component__tab inline-el', {
            'edit-task-component__tab_active': tab === activeTab,
        });
    const getTabContentClassNames = (tab) =>
        classnames('edit-task-component__tab-content', {
            'edit-task-component__tab-content_active': tab === activeTab,
        });
    const getInitialValue = (name, index, value) =>
        formTask?.[name]?.[index] ? {} : { initialValue: value };
    const formInitialValue = formTask ? formTask : { isFree: false };

    const isAdvanced = true;
    return (
        <div className=''>
            <Spin
                spinning={
                    loadingCreateTask || loadingEditTask || loadingDeleteTask
                }
            >
                <div >
                    {/* <div className='edit-task-component__header'> */}
                    {/* <TitleComponent
                            title={formatMessage({
                                id: id
                                    ? 'form_task_edit_title'
                                    : 'form_task_create_title',
                            })}
                        /> */}

                    {/* </div> */}

                    <Form
                        initialValues={formInitialValue}
                        form={form}
                        layout='vertical'
                        name='basic'
                        onFinish={handleFormSubmit}
                        onChange={onChange}
                        onBlur={onChange}
                        requiredMark='hidden'
                        scrollToFirstError={true}
                    // className='flex-container'
                    >

                        <div className=' page edit-task-component'>
                            <div className='wrapper_content'>

                                <Form.Item
                                    name='title'
                                    rules={[
                                        {
                                            required: true,
                                            message: formatMessage({
                                                id: 'form_title_task_error_message',
                                            }),
                                        },
                                    ]}
                                >

                                    <Input
                                        autoComplete='off'
                                        placeholder={formatMessage({id: 'form_title_task',})}
                                        size="large"
                                    />
                                </Form.Item>
                                <h3>{formatMessage({ id: 'form_text_data_title' })}</h3>
                                <Form.Item name='source'>
                                    <TextArea
                                        autoComplete='off'
                                        maxLength={50000}
                                        autoSize={{
                                            minRows: 3,
                                            maxRows: 16,
                                        }}
                                        showCount
                                        placeholder={formatMessage({ id: 'form_text_data_placeholder' })}
                                    />
                                </Form.Item>

                                {task?.summary && <>
                                    <h3>{formatMessage({ id: 'form_text_learning' })}</h3>
                                    <Form.Item name='summary'>
                                        <TextArea
                                            autoComplete='off'
                                            placeholder={formatMessage({ id: 'form_text_learning_placeholder' })}
                                            autoSize={{
                                                minRows: 3,
                                                maxRows: 16,
                                            }}
                                        />
                                    </Form.Item>
                                </>
                                }

                                <Form.Item
                                    name='tagIds'
                                    label={formatMessage({
                                        id: 'form_theme_placeholder',
                                    })}
                                    rules={[
                                        {
                                            required: false,
                                            message: formatMessage({
                                                id: 'form_theme_error_message',
                                            }),
                                        },
                                        () => ({
                                            validator(_, value) {
                                                const isEmpty = !value;
                                                const isValid =
                                                    !isEmpty &&
                                                    value.length <= maxTagsLimit;

                                                if (isEmpty || isValid) {
                                                    if (
                                                        form
                                                            .getFieldsError()
                                                            .filter(
                                                                ({ errors }) =>
                                                                    errors.length
                                                            ).length
                                                    ) {
                                                        form.validateFields();
                                                    }
                                                    return Promise.resolve();
                                                } else {
                                                    return Promise.reject(
                                                        new Error(
                                                            formatMessage(
                                                                {
                                                                    id: 'form_task_max_tags_limit_error',
                                                                },
                                                                { count: maxTagsLimit }
                                                            )
                                                        )
                                                    );
                                                }
                                            },
                                        }),
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        autoClearSearchValue
                                        loading={loadingGetTagsPrediction}
                                        placeholder={formatMessage({
                                            id: 'career_select_skills',
                                        })}
                                        mode={isAllow ? 'tags' : 'multiple'}
                                        filterOption={false}
                                        onSearch={(value) => {
                                            tagsPrediction({
                                                variables: {
                                                    filter: {
                                                        title: value,
                                                    },
                                                },
                                            });
                                        }}
                                        notFoundContent={
                                            loadingGetTagsPrediction && tags?.length ? (
                                                <Spin size='small' />
                                            ) : null
                                        }
                                        maxTagCount={20}
                                        className='select_minor'
                                    >
                                        {tagsList?.map((d) => (
                                            <Select.Option key={d.id}>
                                                {d?.title}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </div>


                            <div className='edit-task-component__tabs'>
                                <div className='edit-task-component__tabs__header'>

                                </div>
                                <hr />

                                {/* tabscontent */}
                                <div className='edit-task-component__tabs__tabs flex-container'>
                                    {/* left column */}
                                    {/* <div className='edit-task-component__source'>
                                        {task?.id &&
                                            <Thread
                                                task={task}
                                                taskId={task?.id}
                                                stepId={stepId}
                                                setStepId={setStepId}
                                                user={user}
                                                {...other}
                                            />
                                        }
                                    </div> */}
                                    {/* right column */}
                                    <div className='flex-left-column edit-task-component__content'>
                                        <div className={getTabContentClassNames('video')}>
                                            <Button
                                                type='text'
                                                disabled={!!formTask?.video}
                                                className={getTabClassNames('cover')}
                                            // onClick={() => handleSetActiveTab('cover')}
                                            >

                                                <Svg
                                                    name=':icon/youtube'
                                                    className='icon_24'
                                                />
                                                <span className='edit-task-component__tab__label'>
                                                    {formatMessage({ id: 'ai_popup_video' })}
                                                </span>
                                            </Button>
                                            <Button
                                                type='link'
                                                // disabled={!!formTask?.video}
                                                className={getTabClassNames('cover')}
                                                // onClick={() => handleSetActiveTab('cover')}
                                                disabled
                                            >
                                                <Svg
                                                    name=':icon/cover'
                                                    className='icon_24'
                                                />
                                                <span className='edit-task-component__tab__label'>
                                                    {formatMessage({ id: 'ai_popup_image' })}
                                                </span>
                                            </Button>
                                            <Form.Item name='video'>
                                                <Input
                                                    autoComplete='off'
                                                    placeholder={formatMessage({
                                                        id: 'form_video_placeholder',
                                                    })}
                                                />
                                            </Form.Item>
                                            <div className={getTabContentClassNames('cover')}>
                                                <div className='edit-task-component__cover'>
                                                    <Form.Item name='coverImage'>
                                                        <UploadImgComponent
                                                            onSetImages={
                                                                handleSetCoverImage
                                                            }
                                                            onRemove={handleRemoveImage}
                                                            limitImages='1'
                                                            savedImages={coverImage}
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>

                                            {formTask?.video && (
                                                <>
                                                    {coverType === 'YOUTUBE' &&
                                                        <VideoIframe
                                                            videoSrc={formTask?.video}
                                                            timestampsList={
                                                                formTask?.timestamps
                                                                    ? formTask?.timestamps
                                                                    : null
                                                            }
                                                        />
                                                    }
                                                    {coverType === 'VIMEO' &&
                                                        <VimeoPlayer
                                                            {...other}
                                                            videoSrc={formTask?.video}
                                                        />
                                                    }
                                                    {coverType === 'VIDEO' &&
                                                        <VimeoPlayer
                                                            {...other}
                                                            videoSrc={formTask?.video}
                                                        />
                                                    }
                                                    <Form.Item name='coverSummary'>
                                                        <TextArea
                                                            autoComplete='off'
                                                            placeholder={formatMessage({ id: 'form_text_videosum_placeholder' })}
                                                            disabled
                                                            className='visually-hidden'
                                                            autoSize={{
                                                                minRows: 1,
                                                                maxRows: 16,
                                                            }}
                                                        />
                                                     
                                                    </Form.Item>

                                                    <Form.List
                                                        name='timestamps'
                                                        rules={[
                                                            {
                                                                required: false,
                                                            },
                                                        ]}
                                                    >
                                                        {(
                                                            timestamps,
                                                            { add, remove }
                                                        ) => (
                                                            <div className='timestamps-container'>
                                                                {timestamps.map(
                                                                    (field) => (
                                                                        <Space
                                                                            key={
                                                                                field.key
                                                                            }
                                                                            style={{
                                                                                display:
                                                                                    'flex',
                                                                                width: '100%',
                                                                            }}
                                                                            align='baseline'
                                                                        >
                                                                            <Form.Item
                                                                                {...field}
                                                                                name={[
                                                                                    field.name,
                                                                                    'timestamp',
                                                                                ]}
                                                                                fieldKey={[
                                                                                    field.fieldKey,
                                                                                    'timestamp',
                                                                                ]}
                                                                                rules={[
                                                                                    {
                                                                                        required: false,
                                                                                        message:
                                                                                            formatMessage(
                                                                                                {
                                                                                                    id: 'form_field_is_required',
                                                                                                }
                                                                                            ),
                                                                                    },
                                                                                ]}
                                                                            >
                                                                                <Input
                                                                                    autoFocus={
                                                                                        true
                                                                                    }
                                                                                    maxLength='8'
                                                                                    placeholder='0:56'
                                                                                />
                                                                            </Form.Item>
                                                                            <Form.Item
                                                                                {...field}
                                                                                name={[
                                                                                    field.name,
                                                                                    'title',
                                                                                ]}
                                                                                fieldKey={[
                                                                                    field.fieldKey,
                                                                                    'title',
                                                                                ]}
                                                                                rules={[
                                                                                    {
                                                                                        required: false,
                                                                                        message:
                                                                                            formatMessage(
                                                                                                {
                                                                                                    id: 'form_field_is_required',
                                                                                                }
                                                                                            ),
                                                                                    },
                                                                                ]}
                                                                                initialValue=''
                                                                            >
                                                                                <Input
                                                                                    autoComplete='off'
                                                                                    placeholder='1-3 words title'
                                                                                />
                                                                            </Form.Item>
                                                                            <Form.Item>
                                                                                <MinusCircleOutlined
                                                                                    onClick={() => {
                                                                                        remove(
                                                                                            field.name
                                                                                        );
                                                                                        onChange();
                                                                                    }}
                                                                                />
                                                                            </Form.Item>
                                                                        </Space>
                                                                    )
                                                                )}
                                                                {/* <Form.Item> */}
                                                                {/* <Button
                                                                        onClick={() =>
                                                                            add()
                                                                        }
                                                                        type='secondary'
                                                                        block
                                                                        icon={
                                                                            <PlusOutlined />
                                                                        }
                                                                    >
                                                                        {formatMessage({
                                                                            id: 'form_add_timestamp',
                                                                        })}
                                                                    </Button> */}
                                                                {/* </Form.Item> */}
                                                            </div>
                                                        )}
                                                    </Form.List>
                                                </>
                                            )
                                            }


                                            <Form.Item
                                                name='description'
                                                className='wysiwyg-field'

                                            >
                                                <h3>{formatMessage({ id: 'form_text_description' })}:</h3>
                                                <WysiwygComponent
                                                    onChangeContent={
                                                        handleChangeContent
                                                    }
                                                    initialState={formTask?.description}
                                                    placeholder={formatMessage({
                                                        id: 'form_description_task_placeholder',
                                                    })}
                                                />
                                            </Form.Item>

                                        </div>


                                        <div
                                        // className={getTabContentClassNames('quiz')}
                                        >
                                            <Form.List name='questions'>
                                                {(questions, { add, remove }) => (
                                                    <div className='questions'>
                                                        {questions.map(
                                                            (question, i) => (
                                                                <div
                                                                    key={question.key}
                                                                    className='question'
                                                                >

                                                                    <hr />
                                                                    <div className='question__header'>
                                                                        <h2>{formatMessage({ id: 'form_text_step' })} {i + 1}</h2>
                                                                        <Form.Item >
                                                                            <Button
                                                                                className='question__remove'
                                                                                icon={<DeleteFilled />}
                                                                                type='text'
                                                                                onClick={() => {
                                                                                    remove(question.name);
                                                                                    onRemoveQuestion();
                                                                                }}
                                                                            >
                                                                                {formatMessage({ id: 'form_remove_question' })}
                                                                            </Button>
                                                                        </Form.Item>
                                                                    </div>
                                                                    <div className='question__top'>
                                                                        <Form.Item
                                                                            name={[
                                                                                question.name,
                                                                                'title',
                                                                            ]}
                                                                            label={formatMessage(
                                                                                { id: 'form_title_prompt' })}
                                                                            className='edit-task-component__question-type'
                                                                        >
                                                                            <TextArea
                                                                                autoComplete='off'
                                                                                placeholder={formatMessage(
                                                                                    { id: 'form_title_prompt' })}
                                                                            />
                                                                        </Form.Item>
                                                                    </div>
                                                                    <div className='question__bottom'>
                                                                        <Form.Item
                                                                            name={[
                                                                                question.name,
                                                                                'question',
                                                                            ]}
                                                                            label={formatMessage({ id: 'form_question_label', })}

                                                                            rules={[
                                                                                {
                                                                                    required: false,
                                                                                    message:
                                                                                        formatMessage({ id: 'form_field_is_required', }),
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <WysiwygComponent
                                                                                onChangeContent={(
                                                                                    content
                                                                                ) => {
                                                                                    const questions =
                                                                                        [
                                                                                            ...formTask.questions,
                                                                                        ];
                                                                                    if (questions[i]) questions[i].question = content;
                                                                                    form.setFieldsValue({ questions: questions, });
                                                                                }}
                                                                                initialState={getQuestionContent(question.key)}
                                                                            />
                                                                        </Form.Item>
                                                                        <Form.Item
                                                                            name={[
                                                                                question.name,
                                                                                'type',
                                                                            ]}

                                                                            {...getInitialValue(
                                                                                'questions',
                                                                                question.name,
                                                                                'SINGLE_SELECT'
                                                                            )}
                                                                            className='edit-task-component__question-type'
                                                                        >
                                                                            <Select
                                                                                options={TYPES_QUESTION}
                                                                            />
                                                                        </Form.Item>
                                                                        {formTask?.questions &&
                                                                            formTask
                                                                                .questions[
                                                                            i
                                                                            ] &&
                                                                            formTask
                                                                                .questions[
                                                                                i
                                                                            ].type ===
                                                                            'TEXT' ? (
                                                                            <Form.Item
                                                                                name={[
                                                                                    question.name,
                                                                                    'answer',
                                                                                ]}
                                                                                label={formatMessage({ id: 'form_text_challenge' })}


                                                                            >
                                                                                <Input
                                                                                    autoComplete='off'
                                                                                    placeholder={formatMessage(
                                                                                        { id: 'form_text_question_answer_placeholder', }
                                                                                    )}
                                                                                />
                                                                            </Form.Item>
                                                                        ) : (
                                                                            <Form.List
                                                                                name={[
                                                                                    question.name,
                                                                                    'options',
                                                                                ]}
                                                                            >
                                                                                {(
                                                                                    options,
                                                                                    { add, remove, }
                                                                                ) => (
                                                                                    <div className='options'>
                                                                                        {options.map(
                                                                                            (option) => (
                                                                                                <div
                                                                                                    key={
                                                                                                        option.key
                                                                                                    }
                                                                                                    className='edit-task-component__question__option'
                                                                                                >
                                                                                                    <div className='edit-task-component__question__option__body'>
                                                                                                        <Form.Item
                                                                                                            name={[
                                                                                                                option.name,
                                                                                                                'text',
                                                                                                            ]}
                                                                                                            rules={[
                                                                                                                {
                                                                                                                    required: true,
                                                                                                                    message:
                                                                                                                        formatMessage({ id: 'form_field_is_required', }),
                                                                                                                },
                                                                                                            ]}
                                                                                                        >
                                                                                                            <Input autoComplete='off' />
                                                                                                        </Form.Item>
                                                                                                        <Form.Item
                                                                                                            name={[
                                                                                                                option.name,
                                                                                                                'isCorrect',
                                                                                                            ]}
                                                                                                            label={formatMessage({ id: 'form_field_is_right', }
                                                                                                            )}
                                                                                                            className='edit-task-component__question__switcher'
                                                                                                            valuePropName='checked'
                                                                                                        >
                                                                                                            <Switch />
                                                                                                        </Form.Item>
                                                                                                        <Button
                                                                                                            type='link'
                                                                                                            onClick={() => remove(option.name)}
                                                                                                            icon={<MinusCircleOutlined />}
                                                                                                            className='edit-task-component__question__remove'
                                                                                                            size='middle'
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>
                                                                                            )
                                                                                        )}

                                                                                        {!!questionsErrors?.[
                                                                                            i
                                                                                        ] && (
                                                                                                <div className='ant-form-item-explain'>
                                                                                                    <div
                                                                                                        className='ant-form-item-explain-error'
                                                                                                        role='alert'
                                                                                                    >
                                                                                                        {
                                                                                                            questionsErrors?.[i
                                                                                                            ]
                                                                                                        }
                                                                                                    </div>
                                                                                                </div>
                                                                                            )}
                                                                                        <Button
                                                                                            onClick={() =>
                                                                                                add({ isCorrect: 'checked', text: 'Next', })
                                                                                            }
                                                                                            type="secondary btn_light"
                                                                                        >
                                                                                            + {formatMessage(
                                                                                                { id: 'form_add_new_answer', }
                                                                                            )}
                                                                                        </Button>
                                                                                    </div>
                                                                                )}
                                                                            </Form.List>
                                                                        )}
                                                                        <div className='question__explanation'>
                                                                            <QuestionExplanationComponent
                                                                                Form={Form}
                                                                                form={form}
                                                                                question={
                                                                                    question
                                                                                }
                                                                                task={task}
                                                                                onChangeExplanation={
                                                                                    form.setFieldsValue
                                                                                }
                                                                            />
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            )
                                                        )}

                                                        <Form.Item>
                                                            <Button
                                                                onClick={() => add()}

                                                                type="text"
                                                            >
                                                                <span>
                                                                    {formatMessage({
                                                                        id: 'form_add_question',
                                                                    })}
                                                                </span>
                                                            </Button>
                                                        </Form.Item>

                                                        {formTask?.questions?.length >
                                                            1 && (
                                                                <div className='edit-task-sort-button'>
                                                                    <Button
                                                                        type='link'
                                                                        onClick={() =>
                                                                            setSortMode(true)
                                                                        }
                                                                    >
                                                                        {formatMessage({ id: 'form_text_step_order' })}
                                                                    </Button>
                                                                </div>
                                                            )}
                                                    </div>
                                                )}
                                            </Form.List>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* wrapper_next-step */}
                        <div className='edit-task-component__footer'>
                            <Form.Item shouldUpdate={true}>
                                <Button
                                    onSubmit={handleFormSubmit}
                                    type='primary'
                                    htmlType='submit'
                                    className='edit-task-component__save'
                                    disabled={
                                        form
                                            .getFieldsError()
                                            .filter(({ errors }) => errors.length)
                                            .length
                                    }
                                >
                                    {formatMessage({
                                        id: buttonLabel,
                                    })}
                                </Button>
                            </Form.Item>

                            <div className='edit-task-component__tabs__btns'>
                                <Form.Item
                                    name='isAutogenerated'
                                    label={formatMessage({
                                        id: 'form_is_autogenerate',
                                    })}
                                    className='edit-task-component__is-free'
                                    valuePropName='checked'
                                >
                                    <Switch
                                        onChange={(flag) => {
                                            setButtonLabel(
                                                flag
                                                    ? 'form_button_save'
                                                    : 'form_button_add_task'
                                            );
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item
                                    name='isDraft'
                                    label={formatMessage({
                                        id: 'form_is_draft',
                                    })}
                                    className='edit-task-component__is-free'
                                    valuePropName='checked'
                                >
                                    <Switch
                                        onChange={(flag) => {
                                            setButtonLabel(
                                                flag
                                                    ? 'form_button_save'
                                                    : 'form_button_add_task'
                                            );
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item
                                    name='isFree'
                                    label={formatMessage({
                                        id: 'form_is_free_label',
                                    })}
                                    className='edit-task-component__is-free'
                                    valuePropName='checked'
                                >

                                    <Switch />
                                </Form.Item>
                            </div>

                            {task && (
                                <Button
                                    onClick={() => handleDelete(task.id)}
                                    className='edit-task-component__remove'
                                    type='text'
                                >
                                    <Svg name=':icon/delete' className='icon_24' />
                                    {formatMessage({ id: 'form_remove_task' })}
                                </Button>
                            )}
                        </div>
                    </Form>
                </div>
                {
                    form.getFieldValue('questions') && (
                        <SortQuestionsComponent
                            list={form.getFieldValue('questions')}
                            showModal={sortMode}
                            onSort={handleSortQuestions}
                            onCloseModal={setSortMode}
                        />
                    )
                }
            </Spin >
        </div >
    );
};
