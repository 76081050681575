import { useMutation } from "@apollo/client";
import { EditTask } from "../graphQL/mutations/EditTask";

export function useEditTask(mutation = EditTask) {
    const [editTask, { data, error, loading }] = useMutation(mutation);

    return {
        task: data?.editTask,
        error, loading,
        editTask
    };
}