import { gql } from '@apollo/client';

export const GetCertificate = gql`
    query GetCertificate($id: Int, $eventId: Int) {
        getCertificate(id: $id, eventId: $eventId) {
            id
            title
            description
            label
            icon
            type
            creatorId
            eventId
            isAppliedForCurrentUser
            event {
                id
                title
                alias
                creator {
                    id
                    name
                }

                isSubscribe
                tags {
                    subject {
                        color
                    }
                    id
                    title
                }
 
                tasks {
                    id
                    title
                    stateResult
                    alias
                    description
                    tags {
                        subject {
                            color
                        }
                        id
                        title
                    }
                }
            }
            users {
                id
                userName
            }
            isAppliedForCurrentUser
        }
    }
`;