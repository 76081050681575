import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button } from 'antd';

import { TaskInfoComponent } from './';
import translate from '../../lang/translate';
import { useTrackClick } from '../common';
import { useLocalePrefix } from '../lang';

export const TaskClosed = (props) => {
    const {
        task,
        actionHidden,
        user,
        viewer,
        handleParticipate,
        topLevel,
        ...other
    } = props;
    const trackClick = useTrackClick();

    return (
        <div className='answer-component_card'>
            <div className='task_overlay_blocked'>
                {task.events &&
                    Object.keys(task.events).map((key) => (
                        <Link
                            key={key}
                            to={`/online-courses/${key}`}
                            onClick={() =>
                                trackClick(
                                    'click-event-button',
                                    task.id,
                                    `https://tasks/comminities/${task.id}`
                                )
                            }
                        >
                            <Button className='' type='primary'>
                                {translate('answer_link_event')}
                            </Button>
                        </Link>
                    ))}
            </div>
            <div
                className='answer-component__link answer-component__content'
                onClick={() =>
                    trackClick(
                        'click-task',
                        task.id,
                        `https://tasks/comminities/${task.id}`
                    )
                }
            >
                <TaskInfoComponent
                    task={task}
                    actionHidden={actionHidden}
                    user={user}
                    viewer={viewer}
                    handleParticipate={handleParticipate}
                    topLevel={topLevel}
                    btnClass='z-index_up'
                    taskClosed={true}
                    {...other}
                />
            </div>
        </div>
    );
};
