import gql from 'graphql-tag';

export const GetResultsByUserid = gql`
    query GetResults($filter: FilterResult) {
        results(filter: $filter) {
            id
            createDate
            description
            answers {
                answer
                options {
                    isCorrect
                    id
                }
                question{
                    id
                    type
                    question
                }
                createDate
            }
            state
            isLiked
            task {
                id
                title
            }
            modifyDate
            createDate
        }
    }
`;
