import React from 'react';
import _ from 'lodash';
import { Progress } from 'antd';

import { Svg } from '../common';
import { SPEC_SUBJECTSID } from '../constant';

export const AchievementsCard = ({ achievement, width, type }) => {
    const specIndex = _.findIndex(SPEC_SUBJECTSID, function (spec) { return spec.subjectIds.includes(achievement.subject?.id) });

    return (
        <div className='achievement_card'
            style={{ backgroundImage: `url("https://cdn.unschooler.me/${SPEC_SUBJECTSID[specIndex]?.title}.svg")` }}
        >
            <div className='card_header'>
                <Progress type="circle"
                    percent={(achievement?.counter?.done / achievement?.counter?.all * 100)}
                    strokeColor={achievement?.color}
                    format={(percent) => <><Svg name=':icon/star' fill={achievement?.color} width={16} /> {achievement?.counter?.done} / {achievement?.counter?.all}</>} />
            </div>
            <div className='achievement_card__icon'>
                {/* <Svg name={`:icon/${SPEC_SUBJECTSID[specIndex]?.title || `tech`}`} className="achievement-svg-icon"
                    fill={achievement?.color}
                    width={30}
                /> */}
                <h4 className='card_achevement__title'>{achievement?.title}</h4>
                <small className='block-el addition-text'>
                    {achievement?.description}
                </small>
            </div>
        </div>
    );
};




