import './scss/index.scss'
import React, { useState, useEffect } from 'react';
import { AvatarCustom, Svg } from '../../common';
import { Checkbox, Button } from 'antd';
import { MentorEditFormContainer } from '../../profile/MentorEditForm.container/MentorEditForm.container';
import { useGetTeacher } from '../../common/graphQL';
import { ReactMarkdownComponent } from '../../task/ReactMarkdown.component/ReactMarkdown.component';

export const MentorCardContainer = ({ teacher, user, handleChangeTeacher, selectedTeachers, isEdit }) => {
  const [editTeacher, setEditTeacher] = useState(false);
  const { getTeacher, loading: loadingGetTeacher, teacher: teacherDB } = useGetTeacher();
  const [teacherState, setTeacherState] = useState(teacher);
  const isActive = selectedTeachers?.includes(teacherState?.userId || teacherState?.id)

  const checkMentor = async () => {
    if (teacher?.id) {
      try {
        const resp = await getTeacher(teacher?.id);
        if (resp) {
          setTeacherState(resp);
        }
      } catch (err) {
        console.warn("Failed to fetch teacher:", err);
      }
    }
  };

  useEffect(() => {
    (async () => await checkMentor())();
  }, [teacher]);

  return (
    <div className={`mentor-profile wrapper_content-section ${isActive ? 'mentor-profile-active' : ''}`}>
      {editTeacher ?
        <MentorEditFormContainer
          user={user}
          teacher={teacherState}
          onClose={() => setEditTeacher(false)}
          setTeacherState={setTeacherState}
        />
        :
        <div className="profile">
          <div className="profile-image">
            <AvatarCustom user={teacherState} size={160} />
          </div>
          <div className="profile-info">
            <h2>{teacherState?.name}</h2>
            {/* <p>{teacherState?.description}</p> */}
            <ReactMarkdownComponent
              children={teacherState?.description}
            />
          </div>

          {isEdit &&
            <>
              <Checkbox
                onChange={() => handleChangeTeacher(teacherState?.userId || teacherState?.id)}
                className={selectedTeachers?.includes(teacherState?.userId || teacherState?.id) ? 'selected-event-checkbox' : 'event-checkbox'}
                checked={isActive}
              />
            </>
          }
          {(teacherState?.userId || teacherState?.id) === user?.id &&
            <Button type='text'
              onClick={() => setEditTeacher(!editTeacher)}
            >
              <Svg name=':icon/edit' width={24} className='margin_right' />
            </Button>
          }
        </div>
      }
    </div>
  );
};