import './scss/index.scss';
import React from 'react';
import { Link } from 'react-router-dom';
import { useIntl } from "react-intl";
import { VideoAnyPlayer } from '../VideoIframe/VideoAnyPlayer'

export const PromoBannerPaid = (props) => {
    const { header, subhead, paragraph, style } = props;
    const { formatMessage } = useIntl();
    return (
        <>
            <div className='flex-container flex-promo_wrapper ' style={style}>
                <div className='promo__text'>
                    {header && <h2 className=''>{header}</h2>}
                    <h4>{subhead}</h4>
                    <p className='wrapper_inner-section'>{paragraph}</p>
                    <Link to={`/payments`} className='link-btn_primary' >
                        <span>{formatMessage({ id: 'payment_button' })}</span>
                    </Link>
                </div>
                <VideoAnyPlayer
                    video={{ source: 'https://storage.googleapis.com/cdn.unschooler.me/web_app/tech_pictures/How%20to%20start%20a%20Pro%20plan.mp4' }}
                    allow="fullscreen"
                />
            </div>
            <div
                className='profile_cover'
                style={{ backgroundImage: `url('https://storage.googleapis.com/cdn.unschooler.me/web_app/profile_cover/${`info`}_trans.png')`, }}
            ></div>
        </>
    );
};
