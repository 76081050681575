import {useEffect} from 'react';
import GA4React from 'ga-4-react';
import {isDevelopment} from '../utils';

const GA_KEY =
    process.env.NODE_ENV === 'production'
        ? process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
        : process.env.REACT_APP_FIREBASE_MEASUREMENT_ID_DEV;
const ga4react = new GA4React(GA_KEY);
let GA_4 = null;

export const useAnalytics = () => {
    const pageViewTrack = (pathname) => {
        if (isDevelopment()) return;

        if (GA_4) {
            GA_4.pageview(pathname);
        }
    };

    const eventTrack = (name, data) => {
        if (isDevelopment()) return;

        if (GA_4) {
            GA_4.gtag('event', name, data);
        }
    };

    useEffect(() => {
        if (isDevelopment()) return;

        if (!GA_4) {
            try {
                (async () => GA_4 = await ga4react.initialize())();
            } catch (e) {
                console.warn(e);
            }
        }
    }, []);

    return {pageViewTrack, eventTrack};
};
