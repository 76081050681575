import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useGetAssessmentAi } from '../hook/useGetAssessmentAi';
import {  getTitle } from '../../common';


export const useGetAssessmentQuestionAi = () => {
  const [error, setError] = useState(null);
  const { formatMessage } = useIntl();
  const { getAssessmentAi } = useGetAssessmentAi();

  const getAssessment = async ({
    questionProps,
    autoload,
    handleReplaceQuestion,
    source,
    handleChangeProgress,
  }) => {
    
    handleChangeProgress({
      message: formatMessage({ id: 'loader_nextstepai_async' }),
      key: 'assessment',
    });

    const steptitle = questionProps?.title?.substring(0, 8000);
    try {
      const apiType = 'createTutorialAssessment';
      const resp = await getAssessmentAi(steptitle, apiType, source);

      if (!autoload) {
        const newQuestion = {
          answer: `${resp}`,
          type: 'TEXT',
          question: `${questionProps?.question}\n\n## ${getTitle(questionProps?.title)}`,
        };
        await handleReplaceQuestion(newQuestion, questionProps.id, );
      }

      handleChangeProgress({ key: 'assessment' });
      return resp;
    } catch (error) {
      handleChangeProgress({ key: 'assessment' });
      setError(error);
      throw error;
    } finally {
      handleChangeProgress(false);
    }
  };

  return { getAssessment, error };
};