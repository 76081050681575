import { useCallback } from 'react';
import { useAiChatResponse } from '../graphQL/useAiChatResponse';

export const useGetAssessmentAi = () => {
    const { getChatResponse, error } = useAiChatResponse();

    const getAssessmentAi = useCallback(
        async ( step, type, source = '') => {
            const settings = {
                args: {
                    task: step || '',
                    source: source?.substring(0,200) || '',
                },
                type: type,
            };

            const response = await getChatResponse({
                variables: {
                    input: settings,
                },
            });

            const responseJSON = response?.data
                ? JSON.parse(response?.data?.requestChatApi)
                : null;
            if (responseJSON?.choices[0]) {
                let responseTrimmed =
                    responseJSON.choices[0].message.content.trim();
                return responseTrimmed;
            }
        },
        [getChatResponse]
    );

    return { getAssessmentAi, error };
};
