import React from 'react';
import { Modal, Input, Button, Row, Col } from 'antd'; // Import Row and Col
import { useAddEmbedHelper } from './helpers/EditorHelpers';

const EmbedModalComponent = ({ editor, isVisible, onClose }) => {
    const {
        iframeUrl,
        iframeWidth,
        iframeHeight,
        setIframeUrl,
        setIframeWidth,
        setIframeHeight,
        handleOk,
    } = useAddEmbedHelper(editor);

    const handleInsertAndClose = () => {
        handleOk();
        onClose();
    };

    // TODO: Need translations

    return (
        <>
            <Modal
                title="Insert Embed Content"
                visible={isVisible}
                onCancel={onClose}
                footer={[
                    <Button key="back" onClick={onClose}>
                        Cancel
                    </Button>,
                   <Button key="submit" type="primary" onClick={handleInsertAndClose}>
                        Insert
                    </Button>,
                ]}
            >
                <Input
                    label="URL"
                    title="URL"
                    value={iframeUrl}
                    onChange={(e) => setIframeUrl(e.target.value)}
                    placeholder="Enter iframe URL"
                />
           <Row gutter={16}> {/* Use Row and Col to organize inputs in one row */}
                    <Col span={12}>
                        <Input
                            value={iframeWidth}
                            onChange={(e) => setIframeWidth(e.target.value)}
                            placeholder="iframe width"
                        />
                    </Col>
                    <Col span={12}>
                        <Input
                            value={iframeHeight}
                            onChange={(e) => setIframeHeight(e.target.value)}
                            placeholder="iframe height"
                        />
                    </Col>
                </Row>
            </Modal>
        </>
    );
};

export default EmbedModalComponent;