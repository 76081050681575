import './scss/index.scss';
import React from 'react';

export const BeltComponent = ({string, color}) => {
    const spanString = string
        ? string +
          ' ' +
          string +
          ' ' +
          string +
          ' ' +
          string +
          ' ' +
          string +
          ' ' +
          string
        : '  Any course can be started for free ✴︎ Any course can be started for free ✴︎';
    return (
        <div className='belt_wrapper'>
            <div className='belt_pink' style={{backgroundColor: color}}>
                <span>{spanString}</span>
            </div>
            <div className='belt_yellow'></div>
        </div>
    );
};
