import './scss/index.scss';
import React, { useState, useEffect } from 'react';
import { Button, Checkbox, Input, Radio } from 'antd';
import { useIntl } from 'react-intl';
import { orderBy } from 'lodash';

import classnames from 'classnames';
import { removetagsFromString } from '../../common/utils/getTitle';
import { ReactMarkdownComponent } from '../../task/ReactMarkdown.component/ReactMarkdown.component';
import { useCheckQuestion } from '../hooks/useCheckQuestion';
import translate from '../../../lang/translate';
import {
    Confetti,
    useTrackClick,
    ProfileLink,
} from '../../common';

const { TextArea } = Input;
const initFormAnswer = {
    optionIds: undefined,
    answer: undefined,
};

export const TaskTestQuestionComponent = (props) => {
    const {
        indexOfQuestion,
        question,
        handleClickContinue,
        user,
        task,
        event,
        result,
        results,
        inProgress,
        handleCheckResultAi,
        onUpdateResult,
        updateTask,
        highlightedRequest,
        handleExampleAi,
        handleStepsAi,
        handleExplainAi,
        handleCodeAi,
        handleContextAi,
        handleVideo,
        handleImage,
        inProgressHighlight,
        topMemo,
        coordsMemo,
        creatorOrAdmin,
        ...other
    } = props;

    let answerIsExist = result?.answers?.find(
        (answer) => answer.questionId === question.id
    );

    let stepTitle = question?.question;
    const indexBreak = stepTitle?.indexOf('</h2>') > 0 ?
        (stepTitle?.indexOf('</h2>') + 5) : stepTitle?.indexOf('\n') > 0 ?
            stepTitle?.indexOf('\n') : stepTitle?.indexOf('<br>') > 0 ? stepTitle?.indexOf('<br>') + 4 : stepTitle?.substring(0, 80);

    let header = removetagsFromString(indexBreak ? stepTitle?.substring(0, indexBreak) : stepTitle?.substring(0, 50));

    const resultsInQuestion = [];
    results?.forEach((result) => {
        const answerIsExist = result.answers.find(
            (answer) => answer.questionId === question.id
        );

        if (answerIsExist) {
            let initFormAnswer = {
                answer: answerIsExist.answer,
                user: result.user,
                date: result.createDate,
                state: result.state,
                id: result.id,
            };
            resultsInQuestion.push(initFormAnswer);
        }
    });

    const { formatMessage } = useIntl();
    const { checkQuestion, loading } = useCheckQuestion();
    const trackClick = useTrackClick();
    const [answerLocal, setAnswerLocal] = useState(answerIsExist);
    const [formAnswer, setFormAnswer] = useState(initFormAnswer);
    const [isCheck, setIsCheck] = useState(result?.state || false);
    const [isCorrect, setIsCorrect] = useState(null);
    const [showConfetty, setShowConfetty] = useState(false);
    const [showExplanation, setShowExplanation] = useState(answerIsExist);

    const handleCheck = async (optionId) => {
        trackClick("click-question", question.id, `https://tasks/${task.id}`);

        const answer = {
            ...formAnswer,
            questionId: question.id,
            optionIds: optionId ? [optionId] : formAnswer.optionIds,
        };
        const { data } = await checkQuestion({
            variables: { answer },
            fetchPolicy: "no-cache",
        });

        if (question.type === "TEXT") {
            if (answer?.answer?.length > 2) {
                const aiResponse =
                    question?.answer &&
                    (await handleCheckResultAi(question.id, formAnswer.answer));

                answer.answer = aiResponse
                    ? answer.answer + aiResponse
                    : answer.answer;
            }

            data.checkQuestion = true; //what does it mean?
            setAnswerLocal(answer);
        }

        setShowExplanation(true);
        setShowConfetty(!!data?.checkQuestion);
        setIsCorrect(!!data?.checkQuestion);
        setTimeout(() => {
            setShowConfetty(false);
        }, 3000);
        user?.id && onUpdateResult( answer);
        setIsCheck(true);
    };

    const FormContainer = classnames("Answer__info answer-results__form", {
        // 'visually-hidden': !showArray.includes(id),
        card_not_done: isCorrect === false,
        card_done: isCorrect,
    });

    const cardColor =
        isCorrect === false
            ? !!task.tags?.length && task.tags[0]?.subject?.color
            : isCorrect
                ? !!task?.tags?.length && task.tags[0]?.subject?.color
                : null;

    useEffect(() => {
        if (answerIsExist) {
            if (question.type === "TEXT") {
                setIsCorrect(true);
            } else {
                let weFindElement = question?.options?.find((o) =>
                    answerIsExist.options?.find((o2) => o.id === o2.id)
                );
                if (weFindElement) {
                    setIsCorrect(weFindElement.isCorrect);
                }
            }
        }
        setShowExplanation(answerIsExist);
    }, [result]);

    let answer = question?.answer ? `${question?.answer}` : null;
    let explain = question?.explanation ? `${question?.explanation}` : null;

    return (
        <div
            className={FormContainer}
            style={{ backgroundColor: cardColor + "30", outline: `16px solid ${cardColor}30` }}
        >
            <div
                className="wysiwyg-content wrapper_inner-section"
                tabIndex={0}
            >
                <ReactMarkdownComponent children={question?.question} />
            </div>

            <div>
                {question.type === "SINGLE_SELECT" && (
                    <Radio.Group
                        name="radiogroup"
                        optionType="button"
                        className="radio_quesion"
                    >
                        {showConfetty && <Confetti />}
                        {orderBy(question.options, "order").map(
                            (option, i) => (
                                <span key={option.id + i}>
                                    <Radio

                                        value={option.id}
                                        onClick={() =>
                                            handleCheck(option.id)
                                        }
                                        className="radio_question__option"
                                        style={
                                            option.isCorrect
                                                ? {
                                                    color: "#4CC05E",
                                                }
                                                : {
                                                    color: "#E7522E",
                                                }
                                        }
                                    >
                                        {option.text}
                                    </Radio>
                                </span>
                            )
                        )}
                    </Radio.Group>
                )}
                {question.type === "MULTI_SELECT" && (
                    <div className="qustion-actions">
                        {showConfetty && <Confetti />}

                        <div className="multi-select">
                            <Checkbox.Group
                                name="optionIds"
                                onChange={(value) =>
                                    setFormAnswer((prev) => ({
                                        ...prev,
                                        optionIds: value,
                                    }))
                                }
                            >
                                {question.options.map((option, i) => (
                                    <Checkbox
                                        key={option.id + i}
                                        name="t"
                                        value={option.id}
                                        className="radio_question__option"
                                        style={
                                            option.isCorrect
                                                ? {
                                                    color: "#4CC05E",
                                                }
                                                : {
                                                    color: "#E7522E",
                                                }
                                        }
                                    // onChange={
                                    //     handleClearAnswer
                                    // }
                                    >
                                        {option.text}
                                    </Checkbox>
                                ))}
                            </Checkbox.Group>
                        </div>
                        <Button
                            type="secondary"
                            onClick={() => handleCheck(null)}
                        >
                            Send
                        </Button>
                    </div>
                )}
                {question.type === "TEXT" &&
                    (
                        <div
                            className="result-content qustion-actions"
                        >
                            {showConfetty && <Confetti />}
                            <TextArea
                                placeholder={formatMessage({
                                    id: "solution_placeholder",
                                })}
                                className="task-results__form__input"
                                // value={value}
                                name="answer"
                                onChange={(e) => {
                                    setFormAnswer((prev) => ({
                                        ...prev,
                                        answer: e.target.value,
                                    }));
                                }}
                                autoSize
                            />
                            <div>
                                <Button
                                    onClick={(e) => {
                                        handleCheck(null);
                                    }}
                                    className="margin_right"
                                >
                                    {
                                        "Send "
                                    }
                                </Button>

                            </div>
                            <ReactMarkdownComponent children={user?.id
                                ? answerIsExist?.answer
                                : answerLocal?.answer} />
                        </div>
                    )}

                {/* students results */}
                {showExplanation && (
                    <div className="task-form__answer wysiwyg-content">
                        {creatorOrAdmin && !!resultsInQuestion?.length
                            ? resultsInQuestion?.map(
                                (res) =>
                                    res.user.id !== user.id && (
                                        <div
                                            key={res.id}
                                            className="wysiwyg-content"
                                        >
                                            <hr />
                                            <ProfileLink
                                                user={res.user}
                                            />{" "}
                                            — {res.state}
                                            <ReactMarkdownComponent children={res.answer} />
                                        </div>
                                    )
                            )
                            : null}
                    </div>
                )}
                {isCheck && isCorrect === false && (
                    <small className="feedback-text_invert feedback-text_notcorrect">
                        {translate("task_try_again")}
                    </small>
                )}
                {showExplanation &&
                    <div className="task-form__answer wysiwyg-content">
                        <ReactMarkdownComponent children={explain} />
                    </div>
                }
            </div>
        </div>
    );
};
