import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookReader, faDumbbell, faCheckCircle, faCheckDouble } from '@fortawesome/free-solid-svg-icons';

<FontAwesomeIcon icon={faDumbbell} />
export const getTitle = (stepTitle) => {
    const indexBreak =
        // stepTitle?.indexOf(':') > 0 ?
        //     (stepTitle?.indexOf(':'))

        //     :
        stepTitle?.indexOf('</h2>') > 0 ?
            (stepTitle?.indexOf('</h2>') + 5)
            :
            (stepTitle?.indexOf('\n') > 0 && stepTitle?.substring(0, stepTitle.indexOf('\n'))?.trim()?.length) ?
                stepTitle?.indexOf('\n')
                :
                stepTitle?.indexOf('<br>') > 0 ?
                    stepTitle?.indexOf('<br>') + 4
                    : 80;
    const head = removetagsFromString(indexBreak ? stepTitle?.substring(0, indexBreak) : stepTitle?.substring(0, 80))
    return head;
}
export const getType = (type, question) => {
    if (type === 'DEFAULT') {
        return <FontAwesomeIcon icon={faBookReader} />;
    } else if (type === 'TEXT') {
        return <FontAwesomeIcon icon={faDumbbell} />;
    } else if (type === 'SINGLE_SELECT') {
        return <FontAwesomeIcon icon={faCheckCircle} />;
    } else if (type === 'MULTI_SELECT') {
        return <FontAwesomeIcon icon={faCheckDouble} />;
    }
}

export const removetagsFromString = (string) => {
    let newString = string?.replace(/<[^>]*>|&nbsp;|#|/g, "").trim();
    let stringWithoutNumbers = newString?.replace(/^\d+./gm, "").trim();
    return stringWithoutNumbers;
};

