import './scss/index.scss';
import React, {useState, useEffect, useMemo} from 'react';
import { Button, Form, Input, Checkbox, Select, Switch } from 'antd';
import { MinusCircleOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';
import classnames from 'classnames';
import { Popconfirm } from 'antd';

import {
    LoginContainer,
    useIsAllow,
    UploadImgComponent,
    LoaderComponent,
    Svg,
    WysiwygComponent,
    WorkspaceTitleComponent,
    useResolutions
} from '../../common';
import { SubscriptionButtonComponent } from '../../subscription';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { openNotification } from '../../notification/Notification.component';
import { StripeConnectedAccountContainer, StripeWorkspaceSubscriptionContainer } from '../../stripe';
import { ResendContainer } from '../../resend';
import { termsContent } from '../../footer/DefaultTerms.component';
import { IntegrationsContainer } from '../../integrations';
import {AddCustomDomainContainer} from '../../azure';

import CURRENCIES from '../../../constants/stripe-currencies.json';
const ARECORD = '51.12.92.210'
const TXT = '4FD4776CBF52C1383853612F4C289AC1C31F78BDF708E179478E490A6C876231'
const CNAME = 'front-unschooler.greenwave-a7b7af58.swedencentral.azurecontainerapps.io';

export const WorkspaceCreateComponent = (props) => {
    const {
        workspace,
        emptyWorkspace,
        setEditmode,
        form,
        handleCreateWorkspace,
        isDomain,
        hostError,
        inProgress,
        removeDomainLoading,
        handleDeleteWorkspace,
        onRemoveDomain
    } = props;

    const {isMD} = useResolutions();
    const { TextArea } = Input;
    const { formatMessage } = useIntl();
    const [showLogin, setShowLogin] = useState();
    const [showUpload, setShowUpload] = useState(false);
    const [activeTab, setActiveTab] = useState(0);
    const [titleData, setTitleData] = useState();
    const [customDomain, setCustomDomain] = useState(workspace?.customDomain);
    const [customDomainValid, setCustomDomainValid] = useState(true);
    const workspaceSource = workspace || emptyWorkspace;

    const useIsAllowed = useIsAllow();
    const isAllow = useIsAllowed("PRO") ;

    const recordType = useMemo(() => {
        if (!customDomain || !customDomainValid) {
            return 'both';
        }
        const isCname = customDomain && customDomain?.split('.')?.length > 2;
        return isCname ? 'cname' : 'a';
    }, [customDomain, customDomainValid]);

    const initialExternalScripts = useMemo(() => (
        workspaceSource?.externalScripts
            ? workspaceSource?.externalScripts.map((item) => (
                {
                    title: item
                }
            ))
            : []
    ), [workspaceSource?.externalScripts])

    const handleLoginUser = () => {
        setShowLogin(true);
    };

    const getTabClassNames = (index) => classnames('flex-sidebar__tab', {
        'flex-sidebar__tab_active': activeTab === index
    });

    const getTabContentClassNames = (index) => classnames('tab__content', {
        'tab__content_active': activeTab === index
    });

    const domainBlockClassnames = classnames('wrapper_inner-section transition-half', {
        'select-none': !customDomain
    });

    const aRecordClassNames = classnames('wrapper_inner-section transition-half', {
        'select-none': recordType !== 'a'
    });

    const cnameRecordClassNames = classnames('record wrapper_inner-section transition-half', {
        'select-none': recordType !== 'cname'
    });

    const resendClassNames = classnames('', {
        'select-none': !workspaceSource?.status
    })

    const onFormError = (err) => {
        if (err) {
            const isTitleError = !!err.errorFields.find((item) => item.name.includes('title'));
            const isHostError = !!err.errorFields.find((item) => item.name.includes('host'));
            const tabNumber = isTitleError || isHostError ? 0 : activeTab;
            setActiveTab(tabNumber);
        }
    };

    const handleRemoveDomain = async () => {
        const resp = await onRemoveDomain();
        form.setFieldsValue({
            customDomain: resp
        });
        setCustomDomain(resp);
    }

    useEffect(() => {
        if (titleData) {
            setTimeout(() => {
                setShowUpload(true);
            }, [200]);
        }
    }, [form, titleData]);

    useEffect(() => {
        if (workspaceSource) {
            setTitleData(workspaceSource);
        }
    }, [workspaceSource, setTitleData])

    if (!titleData) {
        return <LoaderComponent type="overlay" />
    }

    return (
        <div className="flex_direction-column min-h-100vh">
            <div className="flex-shrink_0">
                <WorkspaceTitleComponent workspace={titleData} />
            </div>
            <Form onFinish={handleCreateWorkspace}
                onFinishFailed={onFormError}
                form={form}
                layout="vertical"
                className="ai-component__form flex-grow_1 flex_direction-column"
                name='create-workspace'
                initialValues={
                    {
                        logo: workspaceSource?.logo,
                        title: workspaceSource?.title,
                        hideTitle: !!workspaceSource?.hideTitle,
                        cover: workspaceSource?.cover,
                        description: workspaceSource?.description,
                        host: workspaceSource?.host,
                        customDomain: workspaceSource?.customDomain,
                        loginOnly: workspaceSource?.loginOnly || false,
                        inviteOnly: workspaceSource?.inviteOnly || false,
                        currencyCode: workspaceSource?.currencyCode || 'USD',
                        stripeProductId: workspaceSource?.stripeProductId,
                        copyright: workspaceSource?.copyright || workspaceSource?.title,
                        contactEmail: workspaceSource?.contactEmail || 'info@unschooler.me',
                        address: workspaceSource?.address,
                        customPolicy: workspaceSource?.customPolicy,
                        customTerms: workspaceSource?.terms || termsContent,
                        customScript: workspaceSource?.customScript ? JSON.parse(workspaceSource?.customScript) : '',
                        externalScripts: initialExternalScripts
                    }
                }
            >
                <div className="flex-container edit-workspace sidebar-wrapper flex_align-stretch_d h-full_d two-side-container flex-grow_1">
                    <div className="flex-sidebar">
                        <div className="flex-sidebar__tabs">
                            {!isMD && (
                                <h2>{formatMessage({ id: "setup_your_school" })}</h2>
                            )}
                            <div className={getTabClassNames(0)}
                                 onClick={() => setActiveTab(0)}>
                                {formatMessage({ id: "workspace_title_name" })}
                            </div>

                            {/* <div className={getTabClassNames(1)}
                                    onClick={() => setActiveTab(1)}>
                                    {formatMessage({ id: "workspace_title_content" })}
                                </div> */}
                            {isAllow && (
                                <div className={getTabClassNames(2)}
                                     onClick={() => setActiveTab(2)}>
                                    {formatMessage({ id: "workspace_title_payments" })}
                                </div>
                            )}
                            <div className={getTabClassNames(3)}
                                 onClick={() => setActiveTab(3)}>
                                {formatMessage({ id: 'form_workspace_custom_domain' })}
                            </div>
                            <div className={getTabClassNames(4)}
                                 onClick={() => setActiveTab(4)}>
                                {formatMessage({ id: 'access_and_display' })}
                            </div>

                            <div className={getTabClassNames(5)}
                                 onClick={() => setActiveTab(5)}>
                                {formatMessage({ id: 'footer_and_terms' })}
                            </div>
                            <div className={getTabClassNames(6)}
                                 onClick={() => setActiveTab(6)}>
                                {formatMessage({ id: 'script_section' })}
                            </div>
                            <div className={getTabClassNames(7)}
                                 onClick={() => setActiveTab(7)}>
                                {formatMessage({ id: 'integrations' })}
                            </div>
                            {!isMD && (
                                <hr/>
                            )}
                        </div>
                    </div>
                    <div className="flex-left-column flex-left-body">
                        {isMD && (
                            <h2>{formatMessage({ id: "setup_your_school" })}</h2>
                        )}
                        <div className={getTabContentClassNames(0)}>
                            <Form.Item
                                name="host"
                                label={formatMessage({ id: 'Subdomain_insert_title' })}
                                rules={[
                                    { required: true, message: formatMessage({ id: 'workspace_host_required' }) },
                                    () => ({
                                        validator(_, value) {
                                            const m = value && value.match(/[^a-z0-9]/g);
                                            const isValid = !m ? true : !m.filter((l) => l !== '-').length;
                                            const isValidStart = value?.[0] && value?.[0] !== '-';
                                            if (!!value && !isValid || !isValidStart) {
                                                return Promise.reject(new Error(formatMessage({ id: 'workspace_host_is_not_valid' })));
                                            }
                                            return Promise.resolve();
                                        },
                                    }),
                                ]}
                                extra={formatMessage({ id: 'workspace_host_extra' })}
                            >
                                <div>
                                    <Input
                                        className=" wrapper_inner-section input-inline"
                                        defaultValue={workspace?.host}
                                    />
                                    <span>.unschooler.me</span>
                                    {hostError && (
                                        <div role="alert" className="ant-form-item-explain-error">
                                            {formatMessage({ id: 'workspace_host_exist' })}
                                        </div>
                                    )}
                                </div>
                            </Form.Item>
                            <hr />
                            <Form.Item
                                name="title"
                                label={formatMessage(
                                    { id: 'form_workspace_placeholder' })}
                                rules={[{ required: true, message: formatMessage({ id: "name_of_school_error_message" }) }]}
                                // extra="It will show in the browser tab"
                            >
                                <Input
                                    placeholder={formatMessage({ id: "form_workspace_placeholder" })}
                                    className="inline-el wrapper_inner-section"
                                />
                            </Form.Item>

                            <Form.Item
                                name="hideTitle"
                                valuePropName="checked"
                            >
                                <Checkbox>{formatMessage({ id: 'hide_title_checkbox' })}</Checkbox>
                            </Form.Item>
                            <hr />
                            <Form.Item
                                name="description"
                                label={formatMessage(
                                    { id: 'form_workspace_description' })}
                            >
                                <Input
                                    placeholder={formatMessage({ id: "form_workspace_description_placeholder" })}
                                    // disabled={!user?.id ? true : false}
                                    className="inline-el wrapper_inner-section"
                                />
                            </Form.Item>
                            <hr />
                            <Form.Item
                                label={formatMessage({ id: 'form_workspace_logo' })}
                                name="logo"
                                className="inline-el"
                            >
                                <Input type="hidden" />
                                {showUpload && (
                                    <UploadImgComponent limitImages="1"
                                                        savedImages={workspaceSource?.logo ? [{ url: workspaceSource.logo }] : []}
                                                        onRemove={() => {
                                                            form.setFieldsValue({ logo: '' });
                                                        }}
                                                        onSetImages={(list) => {
                                                            const url = list?.[0]?.response?.url;
                                                            form.setFieldsValue({ logo: url });
                                                            setTitleData((prev) => {
                                                                const newData = { ...prev };
                                                                newData.logo = url;
                                                                return newData;
                                                            });
                                                        }}
                                                        title='152x152 png, jpg,'
                                    />
                                )}
                            </Form.Item>

                            <Form.Item
                                label={formatMessage(
                                    { id: 'form_workspace_cover' })}
                                name="cover"
                                className="inline-el"
                            >
                                <Input type="hidden" />
                                {showUpload && (
                                    <UploadImgComponent limitImages="1"
                                                        savedImages={workspaceSource?.cover ? [{
                                                            url: workspaceSource.cover
                                                        }] : []}
                                                        onRemove={() => {
                                                            form.setFieldsValue({ cover: '' });
                                                        }}
                                                        onSetImages={(list) => {
                                                            const url = list?.[0]?.response?.url;
                                                            form.setFieldsValue({ cover: url });
                                                            setTitleData((prev) => {
                                                                const newData = { ...prev };
                                                                newData.cover = url;
                                                                return newData;
                                                            });
                                                        }}
                                                        title='1800x640 png, jpg,'
                                    />
                                )}
                            </Form.Item>
                            <Form.Item
                                label={formatMessage({ id: 'form_workspace_favicon' })}
                                name="favicon"
                                className="inline-el"
                            >
                                <Input type="hidden" />
                                {showUpload && (
                                    <UploadImgComponent limitImages="1"
                                                        savedImages={workspaceSource?.favicon ? [{ url: workspaceSource.favicon }] : []}
                                                        onRemove={() => { form.setFieldsValue({ favicon: '' }); }}
                                                        onSetImages={(list) => {
                                                            const url = list?.[0]?.response?.url;
                                                            form.setFieldsValue({ favicon: url });
                                                            setTitleData((prev) => {
                                                                const newData = { ...prev };
                                                                newData.favicon = url;
                                                                return newData;
                                                            });
                                                        }}
                                                        title='32x32 png,'

                                    />
                                )}
                            </Form.Item>
                            <Form.Item
                                label={formatMessage({ id: 'sharing' })}
                                name="sharing"
                                className="inline-el"
                            >
                                <Input type="hidden" />
                                {showUpload && (
                                    <UploadImgComponent limitImages="1"
                                                        savedImages={workspaceSource?.sharing ? [{ url: workspaceSource.sharing }] : []}
                                                        onRemove={() => { form.setFieldsValue({ sharing: '' }); }}
                                                        onSetImages={(list) => {
                                                            const url = list?.[0]?.response?.url;
                                                            form.setFieldsValue({ sharing: url });
                                                            setTitleData((prev) => {
                                                                const newData = { ...prev };
                                                                newData.sharing = url;
                                                                return newData;
                                                            });
                                                        }}
                                                        title='1200 x 630 png, jpg'
                                    />
                                )}
                            </Form.Item>
                            <Form.Item
                                label={formatMessage({ id: 'login_cover' })}
                                name="loginCover"
                                className="inline-el"
                            >
                                <Input type="hidden" />
                                {showUpload && (
                                    <UploadImgComponent limitImages="1"
                                                        savedImages={workspaceSource?.loginCover ? [{ url: workspaceSource.loginCover }] : []}
                                                        onRemove={() => { form.setFieldsValue({ loginCover: '' }); }}
                                                        onSetImages={(list) => {
                                                            const url = list?.[0]?.response?.url;
                                                            form.setFieldsValue({ loginCover: url });
                                                            setTitleData((prev) => {
                                                                const newData = { ...prev };
                                                                newData.loginCover = url;
                                                                return newData;
                                                            });
                                                        }}
                                                        title='600 x 1000 png, jpg'
                                    />
                                )}
                            </Form.Item>
                        </div>
                        <div className={getTabContentClassNames(2)}>
                            <StripeConnectedAccountContainer isDisabled={!workspace?.host} />
                            <hr />
                            <Form.Item name="currencyCode"
                                       label={formatMessage({ id: 'currency' })}
                            >
                                <Select options={CURRENCIES}
                                        style={{
                                            width: 'auto',
                                            minWidth: '200px'
                                        }} />
                            </Form.Item>
                            <StripeWorkspaceSubscriptionContainer workspace={workspace} />
                        </div>
                        <div className={getTabContentClassNames(3)}>
                            <h2>1. {formatMessage({ id: 'form_workspace_custom_domain' })}</h2>
                            <Form.Item
                                name="customDomain"
                                rules={[
                                    () => ({
                                        validator(_, value) {
                                            const rx = /^\w+[\w-]+(\.[\w-]+)?\.[a-z]+/;
                                            const matchedString = value?.match(rx)?.[0];
                                            const isValid = !value || (matchedString === value && !value?.startsWith('www.'));
                                            if (!isValid) {
                                                setCustomDomainValid(false);
                                                return Promise.reject(new Error(formatMessage({ id: 'workspace_domain_is_not_valid' })));
                                            }
                                            setCustomDomainValid(true);
                                            return Promise.resolve();
                                        },
                                    }),
                                ]}
                                extra={`${formatMessage({ id: 'workspace_domain_is_not_extra' })} devschool.org, dev-school.org, dev.school.org`}
                            >
                                {isDomain ?
                                    <h3>{workspace?.customDomain}</h3> :
                                    <Input
                                        // placeholder={formatMessage({id: "form_workspace_placeholder"})}
                                        // disabled={!user?.id ? true : false}
                                        className="inline-el wrapper_inner-section"
                                        onChange={(e) => {
                                            const val = e.target.value;
                                            setCustomDomain(val);
                                        }}
                                    />
                                }
                            </Form.Item>

                            <div className={domainBlockClassnames}>
                                {!workspaceSource?.status && (
                                    <div>
                                        <h3>
                                            {formatMessage({ id: 'form_workspace_add_records' })}
                                        </h3>
                                        <p>1. {formatMessage({ id: 'workspace_cname_message' })}</p>
                                        <div className={aRecordClassNames}>
                                            <p><span className='text_secondary'>Type:</span> A </p>
                                            <p><span className='text_secondary'>Name:</span> @ </p>
                                            <p><span className='text_secondary'>Value:</span><CopyToClipboard
                                                text={ARECORD}
                                                onCopy={() => {
                                                    openNotification('topLeft', {
                                                        title: formatMessage({
                                                            id: 'notif_copy_link_title',
                                                        }),
                                                        // description: formatMessage({
                                                        //     id: 'notif_copy_event_link_workspace',
                                                        // }),
                                                        icon: '💬',
                                                        duration: 3,
                                                    });
                                                }}
                                            >
                                                <Button type='text'
                                                        size="small"
                                                        className='btn-record btn-left'
                                                >
                                                    {ARECORD}
                                                </Button>
                                            </CopyToClipboard></p>

                                        </div>
                                        <small className='text_secondary'>or</small>
                                        <div className={cnameRecordClassNames}>
                                            <p> <span className='text_secondary'>Type:</span> CNAME</p>
                                            <p><span className='text_secondary'>Name:</span> {customDomain}</p>
                                            <p><span className='text_secondary'>Value:</span>
                                                <CopyToClipboard
                                                    text={CNAME}
                                                    onCopy={() => {
                                                        openNotification('topLeft', {
                                                            title: formatMessage({
                                                                id: 'notif_copy_link_title',
                                                            }),
                                                            // description: formatMessage({
                                                            //     id: 'notif_copy_event_link_workspace',
                                                            // }),
                                                            icon: '💬',
                                                            duration: 3,
                                                        });
                                                    }}
                                                >
                                                    <Button type='text' size="small"
                                                            className='btn-record btn-left'>
                                                        {CNAME}
                                                    </Button>
                                                </CopyToClipboard>
                                            </p>
                                        </div>
                                        <p>2. {formatMessage({ id: 'workspace_asuid_message' })}</p>
                                        <div className='record wrapper_inner-section'>
                                            <p><span className='text_secondary'>Type:</span> TXT</p>
                                            <p><span className='text_secondary'>Name:</span> {recordType !== 'a' ? `asuid.${workspace?.customDomain}` : 'asuid'} </p>
                                            <p>
                                                <span className='text_secondary'>Value:</span>
                                                <CopyToClipboard
                                                    text={TXT}
                                                    onCopy={() => {
                                                        openNotification('topLeft', {
                                                            title: formatMessage({
                                                                id: 'notif_copy_link_title',
                                                            }),
                                                            // description: formatMessage({
                                                            //     id: 'notif_copy_event_link_workspace',
                                                            // }),
                                                            icon: '💬',
                                                            duration: 3,
                                                        });
                                                    }}
                                                >
                                                    <Button type='text'
                                                            size="small"
                                                            className='btn-record btn-left'
                                                    >
                                                        {TXT}
                                                        {/* <Svg
                                                        name=':icon/share'
                                                        className='icon_32'
                                                    />  */}
                                                    </Button>
                                                </CopyToClipboard>
                                            </p>
                                        </div>
                                    </div>
                                )}
                           
                                <div>
                                    <AddCustomDomainContainer customDomain={customDomain}/>
                                </div>
                                <small
                                    className='wrapper_inner-section'>
                                    {formatMessage({ id: 'workspace_domain_status' })}: {' '}
                                    {workspace?.status ? '✅' : `❌ ${formatMessage({ id: 'workspace_domain_updates' })}`}</small>
                           
                            </div>
                            <div className={resendClassNames}>
                                <ResendContainer/>
                            </div>
                        </div>
                        <div className={getTabContentClassNames(4)}>
                            <h2>{formatMessage({ id: 'access_and_display' })}</h2>
                            <Form.Item
                                name='loginOnly'
                                label={formatMessage({ id: 'login_only' })}
                                className='edit-task-component__is-free'
                                valuePropName="checked"

                            >
                                <Switch
                                    // name='loginOnly'
                                    defaultChecked={!!workspaceSource?.loginOnly || false}
                                    onChange={(flag) => {
                                        form.setFieldsValue({ loginOnly: flag });
                                    }}
                                />

                            </Form.Item>
                            <p className='text_secondary'>
                                {formatMessage({ id: 'logged_only_message' })}
                            </p>
                            <Form.Item
                                name='inviteOnly'
                                label={formatMessage({ id: 'invite_only' })}
                                className='edit-task-component__is-free'
                                valuePropName="checked"
                            >
                                <Switch
                                    // name='inviteOnly'
                                    defaultChecked={!!workspaceSource?.inviteOnly || false}
                                    onChange={(flag) => { form.setFieldsValue({ inviteOnly: flag }); }}
                                />
                            </Form.Item>
                            <p className='text_secondary'>
                                {formatMessage({ id: 'invited_only_message' })}
                            </p>
                            <hr />
                            {workspaceSource?.id &&
                            <Popconfirm
                                title={formatMessage({ id: 'confirm_delete' })}
                                onConfirm={handleDeleteWorkspace}
                                okText='OK'
                                cancelText={formatMessage({ id: 'payment_request_modal_cancel' })}
                                icon={null}
                            >
                                <Button
                                    type='link'
                                    loading={inProgress}
                                    className=' wrapper_content-section'
                                >
                                    <Svg name=':icon/delete' className='icon_16' />
                                    {formatMessage({ id: 'delete_workspace' })}
                                </Button>
                            </Popconfirm>
                            }

                        </div>
                        <div className={getTabContentClassNames(5)}>
                            <Form.Item
                                name='copyright'
                                label={formatMessage({ id: "workspace_create_footer_copyright" })}>
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name='contactEmail'
                                label={formatMessage({ id: "email_title" })}>
                                <Input type="email" />
                            </Form.Item>
                            <Form.Item
                                name='address'
                                label={formatMessage({ id: "workspace_create_address" })}>
                                <TextArea />
                            </Form.Item>
                            <hr />
                            <h3>{formatMessage({ id: "workspace_create_custom_privacy_policy" })}</h3>
                            <Form.Item
                                name='customPolicy'>
                                <WysiwygComponent
                                    onChangeContent={(content) => {
                                        form.setFieldsValue({ customPolicy: content });
                                    }}
                                    initialState={form.getFieldValue('customPolicy')}
                                    placeholder={formatMessage({ id: "workspace_create_privacy_policy_placeholder" })}
                                />
                            </Form.Item>
                            <hr />
                            <h3>{formatMessage({ id: "workspace_create_custom_terms" })}</h3>
                            <small className='text_secondary wrapper_content-section'>
                                {formatMessage({ id: "workspace_create_custom_terms_info" })}
                            </small>

                            <Form.Item
                                name='customTerms'>
                                <WysiwygComponent
                                    onChangeContent={(content) => {
                                        form.setFieldsValue({ customTerms: content });
                                    }}

                                    initialState={form.getFieldValue('customTerms')}
                                    placeholder={formatMessage({ id: "workspace_create_terms_placeholder" })}
                                />
                            </Form.Item>
                        </div>
                        <div className={getTabContentClassNames(6)}>
                            <h2>{formatMessage({ id: 'script_section' })}</h2>
                            <div className="mb-m-l">
                                {formatMessage({ id: 'external_scripts_example' })} <pre>https://www.googletagmanager.com/gtag/js?id=UA-XXXXXXXX-XX</pre>
                            </div>
                            <Form.List name="externalScripts">
                                {(scriptsList, { add, remove }) => (
                                    <div>
                                        <div className="mb-m-l">
                                            {scriptsList.map(
                                                (field, index) => {
                                                    return (
                                                        <div key={`external-script_${field.key}`} className="flex flex_align-center">
                                                            <Form.Item
                                                                name={[index, "title"]}
                                                                rules={[{
                                                                    required: true,
                                                                    message: formatMessage({ id: 'wrksp_add_section_plchldr' })
                                                                }]}
                                                                className="flex-grow_1"
                                                            >
                                                                <Input placeholder={formatMessage({ id: 'external_scripts_placeholder' })} />
                                                            </Form.Item>
                                                            <Button
                                                                type='link'
                                                                onClick={() => remove(index)}
                                                                icon={
                                                                    <MinusCircleOutlined />
                                                                }
                                                                size='middle'
                                                            />
                                                        </div>
                                                    )
                                                }
                                            )}
                                        </div>

                                        <Button
                                            onClick={add}
                                            type="secondary"
                                        >
                                            {formatMessage({ id: 'external_scripts_btn' })}
                                        </Button>
                                    </div>
                                )}
                            </Form.List>
                            <hr/>
                            <Form.Item
                                name='customScript'
                                rules={[
                                    {
                                        validator: (_, value) => {
                                            const includesScript = value.includes('<script');
                                            return includesScript
                                                ? Promise.reject(new Error('The field cannot contain the tag "script"'))
                                                : Promise.resolve();
                                        }
                                    }
                                ]}>
                                <TextArea style={{ minHeight: '300px' }} placeholder={formatMessage({ id: 'custom_script_placeholder' })}/>
                            </Form.Item>
                            <p>
                                {formatMessage({ id: 'custom_script_message_1' })} &lt;script&gt;
                            </p>
                            <p>
                                {formatMessage({ id: 'custom_script_message_2' })} <a href="https://www.site24x7.com/tools/javascript-validator.html" target="_blank" rel="noreferrer">
                                {formatMessage({ id: 'custom_script_message_3' })}
                            </a>, {formatMessage({ id: 'custom_script_message_4' })}
                            </p>

                            <hr />
                            <p>
                                {formatMessage({ id: 'custom_script_message_5' })}
                            </p>
                            <pre>
                                    var userName = prompt('What is your name?');
                                    var host = window.location.host;
                                    alert('Hello, ' + userName + '! Welcome to ' +  host + '!');
                            </pre>
                        </div>
                        <div className={getTabContentClassNames(7)}>
                            <IntegrationsContainer />
                        </div>
                    </div>
                    <div className="wrapper_next-step">
                        <div> </div>
                        <div className="next-step_btns">
                            {isAllow
                                ?
                                <div>
                                    <Button
                                        type={'secondary'}
                                        htmlType='submit'
                                        disabled={inProgress}
                                        onClick={() => setEditmode(false)}
                                    >
                                        {formatMessage({ id: 'payment_request_modal_cancel' })}
                                    </Button>
                                    <Button
                                        type={'primary'}
                                        htmlType='submit'
                                        disabled={inProgress}
                                    >
                                        {formatMessage({ id: 'form_workspace_create' })}
                                    </Button>
                                </div>

                                :
                                <SubscriptionButtonComponent
                                    type='primary'
                                    title={formatMessage({ id: 'payment_button' })}
                                />
                            }
                        </div>
                    </div>

                    <LoginContainer
                        showLogin={showLogin}
                        setCancelLogin={setShowLogin}
                        onLoginUser={handleLoginUser}
                    />
                </div>
            </Form>
            {inProgress && <LoaderComponent type="overlay" />}
        </div>
    );
}
