import React from 'react';
import { MentorEditContainer } from '../../profile/MentorEdit.container'
import { MentorCardContainer } from '../MentorBlock.component/MentorCard.container';

export const MentorBlockContainer = ({ user, event, instructorSubscriptions, ...other }) => {
    return (
        <>
            {event.creator?.id === user?.id && (
                <MentorEditContainer
                    user={user}
                    instructorSubscriptions={instructorSubscriptions}
                    event={event}
                    {...other}
                />
            )}

            {!!instructorSubscriptions?.length ?
                instructorSubscriptions?.map((el) => (
                    <MentorCardContainer key={el?.student?.id} teacher={el?.student} user={user} {...other} />
                )) :
                user?.canTeach && (
                    <MentorCardContainer teacher={user} user={user} {...other} />
                )
            }
        </>
    )
}