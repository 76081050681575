export const LOCALES = {
    ENGLISH: 'en',
    RUSSIAN: 'ru',
    SPANISH: 'es',
    HINDI: 'hi',
    GERMAN: 'de',
    CHINESE_SIMPLIFIED: 'zhc',
    CHINESE_TRADITIONAL: 'zht',
    BRAZILIAN_PORTUGUESE: 'ptb',
    POLISH: 'pl',
    FRENCH: 'fr',
    ARABIC: 'ar',
    SWEDISH: 'sv',
    FINNISH: 'fn',
    MALAYALAM: 'ml',
    ITALIAN: 'it',
    HEBREW: 'he',
    JAPANESE: 'ja',
    DUTCH: 'nl',
    INDONESIAN: 'id',
    ROMANIAN: 'ro',
    MALAY: 'ms',
    TURKISH: 'tr',
    FILIPINO: 'fil',
    NORWEGIAN: 'no',
    CROATIAN: 'hr',
    CZECH: 'cs',
    KOREAN: 'ko',
    KHMER: 'km',
    UKRAINIAN: 'uk',
    PORTUGUESE: 'pte'
}
