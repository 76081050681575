import React from 'react';
import { Button } from 'antd';
import { useIntl } from 'react-intl';
import { useProgress, Svg } from "../../common";
import { useNextStepAi } from "../hook/useNextStepAi";


export const NextStepButton = ({
  userIsAllowGenerate,
  question,
  task,
  handleReplaceQuestion,
  regenerate
}) => {
  const { inProgress, handleChangeProgress } = useProgress();
  const { formatMessage } = useIntl();
  const { getNextStep } = useNextStepAi();

  const handleClick = async () => {
    handleChangeProgress({
      message: formatMessage({ id: 'loader_nextstepai_async' }),
      key: 'assessment',
    });

    const content = await getNextStep({
      goal: task?.title || "",
      steptitle: question?.question?.substring(0, 30000),
      source: task.summary || task.source,
      curriculum: task?.questions?.map(q => q?.title?.split('\n')[0])?.join(', '),
      handleChangeProgress,
    }) || "";

    if (content) {
      await handleReplaceQuestion({ question: `${content}\n${question?.question}` }, question.id);
    }
    handleChangeProgress({ key: 'assessment' });
  };

  return (
    <Button
      className="btn-left"
      disabled={inProgress || !userIsAllowGenerate}
      type={regenerate ? 'secondary' : 'tertiary'}
      size="small"
      onClick={handleClick}
    >
      <Svg name=':icon/logo-full_star' className='icon_16' />
      {regenerate ? formatMessage({ id: "regenerate" }) : formatMessage({ id: "generate" })}
      {' '}
      {formatMessage({ id: 'learning_text' })}
    </Button>
  );
};