import './scss/index.scss';
import React, { useState } from 'react';
import { getRandomInteger } from '../../common/utils';
import { BgProfileComponent } from '../../headers/BgProfile.component';
import { SPEC_SUBJECTSID } from "../../constant";
import { LandingVideoDemo } from "../LandingVideoDemo";

export const LandingPromoComponent = ({ handleScrollClick, trackClick, ...other }) => {
    // const [spec, setSpec] = useState(SPEC_SUBJECTSID[getRandomInteger(0,6)]);
    const [spec, setSpec] = useState(SPEC_SUBJECTSID[3]);
    return (
        <>
            <div className=' wrapper_hero '>
                <div className='wrapper_hero-text quote'>
                    <div className='wrapper_supertitle '>
                        {/* <h1 className='supertitle'>Learn & Teach with AI video courses</h1> */}
                        <h1 className='supertitle'>Video courses you can talk to</h1>
                        <p>
                            Teach and Learn with AI: generate courses with educational videos for any question 
                            {/* <br />Play with it, ask something: */}
                        </p>
                    </div>
                </div>
            </div>

            <LandingVideoDemo
                taskId={281900}
                setSpec={setSpec}
                {...other}
            />

            <BgProfileComponent specialization={spec} />
            {/* <div className='wrapper_inner-section'>
                <AIAllFeaturesContainer
                    tabs={[
                        formatMessage({ id: 'tab_ai_course' }),
                        formatMessage({ id: 'tab_ai_lesson' }),
                        formatMessage({ id: 'tab_ai_mentor' }),
                        formatMessage({ id: 'tab_ai_test' }),
                    ]}
                    sayMeSpec={setSpec}
                    className=' tab_center'
                />
            </div> */}
        </>
    );
};
