import React from 'react';
import { useIntl } from 'react-intl';
import { cloneDeep } from 'lodash';

import { WysiwygComponent } from '../../common';

export const QuestionExplanationComponent = (props) => {
    const { Form, form, task, question = {}, onChangeExplanation } = props;
    const { key } = question;
    const { formatMessage } = useIntl();
    const explanation = task?.questions?.[key]?.explanation || '';

    const handleChange = (content) => {
        const questions = form.getFieldValue('questions');
        if (questions[key]) {
            questions[key].explanation = content;
        }
        onChangeExplanation({ questions });
    };

    return (
        <div className='question-explanation-component__field'>
            <Form.Item
                // label={formatMessage({ id: 'form_explanation_label' })}
                name={[question.name, 'explanation']}>
                <WysiwygComponent initialState={explanation}
                    onChangeContent={handleChange}
                    placeholder={formatMessage({ id: 'form_explanation_label' })}
                />
            </Form.Item>
        </div>
    )
}