import React from 'react';
import { Link } from 'react-router-dom';
import { CircleIndexComponent } from '../../../common';
import { ReactMarkdownComponent } from '../../../task/ReactMarkdown.component/ReactMarkdown.component';
import { checkTask, getTitle } from '../../../common/utils';

export const ResultPreviewComponent = ({ result, localePrefix, options, event }) => {
    const task = event ? event?.tasks.find(el => el.id === result?.task?.id) : result?.task;
    return (
        <div>
            <p><strong>{result.state} lesson</strong>, {new Date(result.createDate).toLocaleString('en-GB', options)}</p>
            {checkTask(result.state)} 

            <Link className="connected-panel__Link" to={`${localePrefix}/tutorials/${task?.alias}-${task?.id}`}>
                {result.task.title}
            </Link>

            <ul>
                {result.answers?.map((answer, answerIndex) => (
                    <div key={answerIndex} className="wrapper_content-section">
                        {answer.options && answer.options.length > 0 &&
                            answer.options.map((option, optionIndex) => (
                                <div key={optionIndex}>
                                    <p><strong>{option.isCorrect ? "CORRECT" : "INCORRECT"} step</strong>, {new Date(answer.createDate).toLocaleString('en-GB', options)}</p>
                                    <div className='connected-panel__subitem__item'>
                                        <CircleIndexComponent className="margin_right" check={option.isCorrect ? "CORRECT" : "INCORRECT" || null} />
                                        <Link className="connected-panel__Link" to={`${localePrefix}/tutorials/${task?.alias}-${task?.id}?step=${answer?.question?.id}`}>
                                            {getTitle(answer.question?.question?.replace(/#/g, ''))}
                                        </Link>
                                    </div>
                                </div>
                            ))}
                        <ReactMarkdownComponent
                            children={answer.answer}
                            className='Answer__info'
                        />
                    </div>
                ))}
            </ul>
            {result.description && ` Notes: ${result.description}`}
            <hr /> 
        </div>
    );
};