import React from 'react';
import './scss/index.scss';
import { Button } from 'antd';
import { useIntl } from 'react-intl';
import { AchievementsCard } from '../../achevements/Achievements.component';
import { TaskProgressComponent } from '../TaskProgress.component';
import { Svg } from '../../common';

export const SuccessScreenComponent = ({ onClose, taskResult, tagsRenew, event, progressCounter, user }) => {
    const mainTag = event?.tags?.[0]
    const totalQuestions = user?.id ? progressCounter?.all : taskResult?.reduce((acc, el) => acc + (el.questions?.length || 0), 0);
    const done = user?.id ? progressCounter?.done : totalQuestions
    const { formatMessage } = useIntl()
    return (
        <div className="loader-component_full">
            <div className="success_container">
                <h1>{formatMessage({ id: 'welldone' })} {user?.name}!</h1>
                <p>{formatMessage({ id: 'you_have_completed' })} {done} / {totalQuestions} {formatMessage({ id: 'lessons' })} in {taskResult?.length} {formatMessage({ id: 'modules' })}:</p>
                <div className="wrapper_outer-section">
                    <TaskProgressComponent
                        backgroundColor={mainTag?.subject?.color + 30 || ''}
                        progressColor={mainTag?.subject?.color || ''}
                        progressPercentage={done ? (done /totalQuestions * 100) : 100}
                        completedCount={done}
                        allCount={totalQuestions}
                    />

                    {!!tagsRenew?.length &&
                        <div className='wrapper_content-section achievement_cards '>
                            {tagsRenew?.map((tag, i) => (
                                <AchievementsCard
                                    type={`small`}
                                    key={tag?.id}
                                    achievement={tag}
                                    width={80}
                                />
                            ))}
                        </div>
                    }

                </div>
                {/* <Button type='tertiary' onClick={onClose}>Back to the main course page</Button> */}
                <Button type='text' onClick={onClose}>
                    <Svg name=':icon/cross' width={30}  />
                </Button>
            </div>
        </div>
    );
};
