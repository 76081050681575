import React, {useEffect, useState, useMemo, useContext} from 'react';
import {useHistory} from 'react-router-dom';
import {LoaderComponent} from '../Components/common';
import {useConfirmEmailToken} from '../Components/user';
import {FirebaseContext} from '../context/firebase/firebaseContext';
import { useIntl } from 'react-intl';

export const ConfirmEmail = () => {
    const { user, updateUserData } = useContext(FirebaseContext);
    const history = useHistory();
    const {confirmEmailToken, loading, data} = useConfirmEmailToken();
    const queryParams = new URLSearchParams(history.location.search);
    const [error, setError] = useState();
    const { formatMessage } = useIntl();

    const isVerified = useMemo(() => user?.emailVerified || data?.confirmEmailToken, [user, data]);

    useEffect(() => {
        const {id, emailVerified} = user;
        if (id) {
            if (!emailVerified) {
                const token = queryParams.get('token');
                if (token) {
                    setError(false);
                    (async () => {
                        const resp = await confirmEmailToken({
                            variables: {
                                token
                            }
                        });
                        if (!resp?.data?.confirmEmailToken) {
                            setError(true);
                        } else {
                            await updateUserData(id);
                        }
                    })();
                } else {
                    setError(true);
                }
            } else {
                setError(false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    return (
        <div className="page">
            <h1>{formatMessage({id: 'email_confirmation_title'})}</h1>
            {loading && (
                <LoaderComponent type="overlay"/>
            )}
            {(!isVerified && error) && (
                <div className="error">
                    {formatMessage({id: 'email_insert_warning'})}
                </div>
            )}
            {isVerified && (
                <div>{formatMessage({id: 'email_insert_success'})}</div>
            )}
        </div>
    )
}