import React from 'react';
import { ProfileLink } from '../../common';
import { RoleChangeContainer } from '../RoleChange.container/RoleChange.container';
import { DeleteMemberContainer } from '../DeleteMember.container/DeleteMember.container';

export const WorkspaceMembersComponent = (props) => {
    const { membersList = [], memberGroup, onChangePermission, handleDeleteUser, workspace, user, isHidden, ...other } = props;

    if (!membersList.length) return null;

    return (
        <div className="workspace-members-component">
            {membersList.map((member) => {
                return (
                    <div key={member.id} className='wrapper_inner-section flex-between'>
                        <ProfileLink
                            user={member}
                            isEllipsis={true}
                            style={{ maxWidth: 200 }}
                        />
                        {memberGroup !== 'Owner' && (
                            <div>
                                <RoleChangeContainer
                                    memberId={member.id}
                                    user={member}
                                    onChangePermission={onChangePermission}
                                    permission={memberGroup}
                                    workspace={workspace}
                                    {...other}
                                />
                                <DeleteMemberContainer
                                    memberId={member.id}
                                    memberGroup={memberGroup}
                                    workspaceMembers={membersList}
                                    workspace={workspace}
                                    {...other}
                                />
                            </div>
                        )}
                    </div>
                )
            })}
        </div>
    )
}