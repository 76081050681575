import { gql } from "@apollo/client";

export const GetEventForThread = gql`
    query GetEvent($id: Int, $alias: String, $workspaceId: Int,) {
        event(id: $id, alias: $alias, workspaceId: $workspaceId) {
            id
            type
            alias
            isDraft
            isModerated
            isHideAi
            title
            description
            langId
            drip
            dripFrequency
            dripGranularity
            certificate {
                id
                title
                isAppliedForCurrentUser
            }
            tags {
                id
                title
                subject {
                    color
                }
            }
            createDate
            creator {
                id
                name
                picture
                canTeach
            }
            galleriesInEvent {
                gallery {
                    type
                    source
                }
                order
            }
            studentsLimit
            countUserSubscriptions
            countAllUserSubscriptions
            isSubscribe
            room {
                type
                link
            }
            tasks {
                id
                alias
                isFree
                description
                title
                stateResult
                isDraft
                tags {
                    id
                    title
                    subject {
                        color
                    }
                }
                questions {
                    id
                }
                isAvailable
                isAutogenerated
            }
            workspaceId
            workspaceIds
            isAccessDenied
            userSubscriptions {
                studentId
                start
                end
                isCanceled
                role
            }
        }
    }
`;
