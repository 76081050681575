/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { ResultComponent } from '../Result.component';
import { checkResult } from '../../common/utils';

export const ResultContainer = (props) => {
    const { result, user, question, ...other } = props;
    const [showResult, setShowResult] = useState();
    const answer = result?.answers?.find((answ) => answ.questionId === question?.id);
    let state = question ? checkResult(result, question) : result?.state;

    useEffect(() => {
        setShowResult(question ? !!result?.answers?.find((answ) => answ.questionId === question?.id) : false)
    }, [question])

    return (
        // showResult
        // ? 
        <ResultComponent {...other}
            result={result}
            user={result.user}
            answer={answer}
            state={state}
            isMe={user} />
        // : null
        );
};
