
import "./scss/index.scss"
import React from 'react';
import { Link } from 'react-router-dom';
import { TagComponent } from '../../common';
import { TasksListComponent } from '../../task';
import { getGalleriesInEvent } from '../../event/getGalleriesInEvent';

export const EventCurriculumComponent = ({ event, localePrefix, tagColor, ...other }) => {
    const imgUrl = event?.galleriesInEvent?.find(gallery => gallery.gallery?.type === "PHOTO")?.gallery?.source;
    return (
        <div
            className='wrapper_event-cur-item'
            style={{ backgroundColor: tagColor }}
        >
            <Link
                className='event-cur-item'
                to={`/online-courses/${event.id}`}>
                <div className='event-cur-image' style={{
                    color: tagColor?.color,
                    backgroundImage: `url(${imgUrl || getGalleriesInEvent(event?.id)})`,
                    backgroundSize: 'cover',
                    backgroundPosition: '50%',
                }}>
                </div>
                <div className='event-card-component__body'>
                    <h2 className='event-cur-item__title'>
                        {event?.title}
                    </h2>
                    {(event.tags || event.subjects)?.map((tag, i) => (
                        <TagComponent
                            key={i}
                            tag={tag}
                            colorful={true}
                            link={false}
                        />
                    ))}
                </div>
            </Link>
            <TasksListComponent
                {...other}
                tasks={event?.tasks}
                actionHidden={true}
                type={event.isSubscribe ? 'link' : null}
            />
        </div>
    );
};
