import './scss/index.scss';
import React from 'react';
import { useIntl } from 'react-intl';
import { VideoGenerator, Confetti, LoaderTypeComponent, FilesGalleryComponent } from '../../common';
import { ReactMarkdownComponent } from '../../task/ReactMarkdown.component/ReactMarkdown.component';
import { AudioPlayerComponent, TextToAudioContainer } from '../../audio';

export const TaskDescriptionComponent = (props) => {
    const {
        task,
        result,
        user,
        updateTask,
        taskContent,
        showConfetty,
        showAiForStudents,
        ...other
    } = props;

    const isCreator = user?.id === task?.creatorId;
    const galleriesList = task?.galleries?.filter((gal) => gal?.type === 'FILE');
    const { formatMessage } = useIntl();

    return (
        <div className='answer-results__form' >
            <h2>{task.title}</h2>
            <VideoGenerator
                task={task}
                user={user}
                showAiForStudents={showAiForStudents}
                updateTask={updateTask}
                {...other}
            />
            {task?.cover?.type === "PHOTO" && (
                <div className="task-article-component__cover">
                    <img src={task?.cover?.source} alt={task?.title} />
                </div>
            )}

            {showConfetty && <Confetti />}
            <section className="Answer__article">
                {task?.description && <div
                    className="Answer__info wysiwyg-content wrapper_outer-section"
                    tabIndex={0}
                >
                    {isCreator && !task?.audioDescription && (
                        <TextToAudioContainer text={task?.description}
                            savedAudio={task?.audioDescription}
                            taskId={task?.id} />
                    )}
                    {!!task?.audioDescription && (
                        <AudioPlayerComponent
                            audioUrl={task?.audioDescription}
                            entityId={task.id}
                            typeEntity="task"
                            user={user}
                            text={task?.description}
                            isCreator={isCreator}
                            {...other}
                        />
                    )}
                    <ReactMarkdownComponent children={taskContent} />
                </div>}
                {!!galleriesList?.length && (
                    <div className='wrapper_outer-section'>
                        <h3 className='wrapper_inner-section'>{formatMessage({ id: 'additional_materials' })}</h3>
                        <FilesGalleryComponent
                            galleriesList={galleriesList}
                        />
                    </div>
                )}
            </section>
        </div>
    );
};
