import { useMutation } from "@apollo/client";
import { EditQuestion } from "../graphQL/mutations/EditQuestion";

export function useEditQuestion(mutation = EditQuestion) {
    const [editQuestion, { data, error, loading }] = useMutation(mutation);

    return {
        question: data?.editQuestion,
        error, loading,
        editQuestion
    };
}