import React, {useEffect, useMemo} from 'react';
import {Link} from 'react-router-dom';
import {useIntl} from 'react-intl';
import {chunk} from 'lodash';

import {Loader} from '../Loader';
import {CustomMessageFormContainer} from '../workspace/CustomMessageForm.container/CustomMessageForm.container';
import {useIsPermit} from '../roles/hook/useCheckPermission';
import {RoleChangeContainer} from '../workspace/RoleChange.container/RoleChange.container';
import {DeleteMemberContainer} from '../workspace/DeleteMember.container/DeleteMember.container';
import {StudentsTableComponent} from './StudentsTable.component/'
import {InvitesFormCourseContainer} from '../event/InvitesFormCourse.container/';
import {useGetUsers} from '../common/graphQL';
import {GET_USERS_RESULTS} from '../common/graphQL/user/useGetUsers';

const LIMIT_PER_PAGE = 20;

export const StudentsWorkspaceContainer = ({filter, workspace, columnKeys, ...other}) => {
    const {members = []} = workspace || {};
    const {getUsers, users = [], loading} = useGetUsers(GET_USERS_RESULTS);
    const {formatMessage} = useIntl();
    const {checkPermit} = useIsPermit();
    const inviteCreators = checkPermit(workspace, 'inviteCreators');

    const options = {day: '2-digit', month: 'short', hour: '2-digit', minute: '2-digit', hour12: false};

    const {idsList, idsCounter} = useMemo(() => {
        const ids = members.filter(member => {
            if (filter === 'Team') {
                return member.permission.includes('Owner') || member.permission.includes('Creator') || member.permission.includes('Admin');
            }
            return member.permission === filter;
        }).map(member => parseInt(member.userId));
        const idsList = chunk(ids, LIMIT_PER_PAGE);
        return {
            idsList,
            idsCounter: ids?.length || 0
        }
    }, [members]);

    const studentData = useMemo(() => {
        return users?.map((user, i) => {
            const permission = members?.find(member => member.userId === user.id)?.permission || 'User';
            return {
                user: {
                    name: user.name,
                    id: user.id,
                    picture: user.picture,
                },
                progress: {
                    all: 0,
                    // progress: progress,
                    answers: [],
                    userId: user.id
                },
                permission: permission,
                key: user.id !== 10 ? i : user.id,
                startDate: new Date(user.createDate).toLocaleString('en-GB', options),
                lastVisit: new Date(user.lastVisit).toLocaleString('en-GB', options),
                events: user?.events || [],
                results: user?.results?.length,
                answers: user?.results || [],
                email: user.email,
                delete: user,
            }
        });
    }, [users]);

    const getUserList = async (index) => {
        if (!!members?.length) {
            await getUsers(idsList[index - 1], workspace?.id, true);
        }
    };

    useEffect(() => {
        if (members?.length) {
            (async () => await getUserList(1))();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [members?.length])


    const additionalColumns = [
        {
            title: formatMessage({id: "table_role"}),
            sorter: (a, b) => a.permission.localeCompare(b.permission),
            dataIndex: 'permission',
            render: (text, record) => (
                <>
                    {text !== 'Owner' && inviteCreators ? (
                            <RoleChangeContainer
                                memberId={record.user.id}
                                user={record.user}
                                permission={text}
                                workspace={workspace}
                                spaceId={workspace.id}
                                {...other}
                            />
                        ) :
                        <span>{text}</span>
                    }
                </>
            ),
        },
        {
            title: formatMessage({id: "table_events"}),
            dataIndex: 'events',
            render: (events) => (
                <ul className="wrapper_list">
                    {!!events.length ? events.slice(0, 10).map(event => (
                            <li key={event.id}>
                                <Link to={`/online-courses/${event.id}`}>
                                    {event.title.length > 16 ? `${event?.title?.substring(0, 16)}…` : event.title}
                                </Link>
                            </li>))
                        : 0
                    }
                </ul>
            )
        },
        {
            title: '',
            dataIndex: 'permission',
            render: (text, record) => (
                workspace.id !== record.user.id && (
                    <DeleteMemberContainer
                        memberId={record.user.id}
                        memberGroup={text}
                        workspaceMembers={users}
                        spaceId={workspace.id}
                        workspace={workspace}
                        {...other}
                    />
                )
            ),
        }
    ]

    const actions = [
        {
            component: CustomMessageFormContainer,
        },
        {
            component: InvitesFormCourseContainer,
        }
    ];

    return (
        <div>
            {loading && <Loader />}
            <StudentsTableComponent
                actions={actions}
                studentData={studentData || []}
                additionalColumns={additionalColumns || []}
                workspace={workspace}
                counter={idsCounter}
                limitPerPage={LIMIT_PER_PAGE}
                onChangePage={getUserList}
                isUsersLoading={loading}
                {...other}
            />
        </div>
    );
};