import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import { Button } from 'antd';
import { FEATURESLIST } from '../../constant.js';
import { PricingCard } from '../PricingCard.js';
import { EmailVerificationContainer } from '../../user';

const getDate = (d) => {
  const dateData = new Date(d);
  return dateData.toLocaleDateString();
};
export const PaymentPlansComponent = ({
  isStart,
  user,
  handleUnSubscribe,
  isPremium,
  isCreator,
  subscriptionType,
  isAllow,
  isExpired,
  isCanceled,
  isPro,
  isUniversity,
  ...other
}) => {
  const { formatMessage } = useIntl();
  const [showVerificationModal, setShowVerificationModal] = useState();
  const { emailVerified, subscriptionUser } = user;

  return (
    <div>
      <div>
        <h1>
          {(!isAllow || !!isExpired) ? formatMessage({
            id: "payment_title",
          }) : formatMessage({ id: "premium_your" })}: {subscriptionUser?.type}
        </h1>

        {/* PaymentStatus */}
        {(isAllow && !isExpired && !isUniversity) && (
          <>
            <p className='wrapper_inner-section'>
              {formatMessage({
                id: "premium_valid",
              })}{" "}
              {getDate(subscriptionUser?.endDate)}
            </p>
            {isCanceled ?
              "Canceled"
              :
              <div>
                <Button type="tertiary" onClick={handleUnSubscribe}>
                  {formatMessage({
                    id: "payment_request_modal_cancel",
                  })}
                </Button>
              </div>
            }
          </>
        )}
        <hr />
      </div>

      <div className="pricing-section">
        <div className='sticky'>
          <div className='pricing-item'>
            <div className='pricing-item__col _1'>
              <h4> {formatMessage({ id: "features" })}</h4>
            </div>
            <div className='pricing-item__col'>
              <PricingCard
                isMyPlan={ user?.id && !user?.subscriptionUser}
                title={formatMessage({ id: "free_title" })}
                desciption={formatMessage({ id: "free_description" })}
                price={0}
                plan="FREE"
                planUpgrade={subscriptionType < 2}
                activeSubscriber={isAllow && !isExpired}
                trialSubscriber={isAllow && isExpired}
                isCanceled={isCanceled}
                handleUnSubscribe={handleUnSubscribe}
                onShowVerificationModal={setShowVerificationModal}
              />

            </div>
            {/* <div className='pricing-item__col'>
              <PricingCard
                isMyPlan={isCreator}
                title={formatMessage({ id: "premium_creator" })}
                desciption={formatMessage({ id: "premium_creator_description" })}
                price={60}
                plan="CREATOR"
                planUpgrade={subscriptionType < 3}
                activeSubscriber={isAllow && !isExpired}
                trialSubscriber={isAllow && isExpired}
                isCanceled={isCanceled}
                handleUnSubscribe={handleUnSubscribe}
                onShowVerificationModal={setShowVerificationModal}
              />
            </div> */}
            <div className='pricing-item__col'>
              <PricingCard
                isMyPlan={isPro}
                title={formatMessage({ id: "premium_pro" })}
                desciption={formatMessage({ id: "premium_pro_description" })}
                price={100}
                plan="PRO"
                planUpgrade={subscriptionType < 4}
                activeSubscriber={isAllow && !isExpired}
                trialSubscriber={isAllow && isExpired}
                handleUnSubscribe={handleUnSubscribe}
                isCanceled={isCanceled}
                onShowVerificationModal={setShowVerificationModal}
              />

            </div>
            <div className='pricing-item__col'>
              <PricingCard
                isMyPlan={isUniversity}
                title={formatMessage({ id: "premium_university" })}
                desciption={formatMessage({ id: "premium_university_description" })}
                price={300}
                plan="UNIVERSITY"
                planUpgrade={subscriptionType < 5}
                activeSubscriber={isAllow && !isExpired}
                trialSubscriber={isAllow && isExpired}
                handleUnSubscribe={handleUnSubscribe}
                isCanceled={isCanceled}
                onShowVerificationModal={setShowVerificationModal}
              />
            </div>
            {/* <div className='pricing-item__col'>
              <div className="pricing-item__col-text top">
                <h2>
                  {formatMessage({
                    id: "premium_university",
                  })}
                </h2>
                <small>
                  {formatMessage({
                    id: "premium_university_description",
                  })}
                </small>

                <div className='wrapper_inner-section'>
                  <h3 className="event-card-component__title inline-el ">
                    {formatMessage({
                      id: "custom",
                    })}
                  </h3>
                </div>
                <>
                  {emailVerified && (
                    <a
                      href='https://calendly.com/lena_unschooler/interview'
                      target='_blank'
                      rel='noreferrer'
                      className='link-btn-secondary ant-btn ant-btn-secondary block-el'
                    >
                      <span>
                        {formatMessage({
                          id: "contact_sales",
                        })}</span>
                    </a>
                  )}
                  {!emailVerified && (
                    <Button 
                    type="secondary"
                      onClick={() => setShowVerificationModal(true)}>
                      {formatMessage({
                        id: "contact_sales",
                      })}
                    </Button>
                  )}
                </>
              </div>
            </div> */}

          </div>
        </div>
    

        {FEATURESLIST.map((feature, index) => (
          <div key={`feature_${index}`}>
            {feature.divider && (
              <>
                <hr />
                <h4>{feature?.divider}</h4>
              </>
            )}
            <div className='pricing-item'>
              <div className='pricing-item__col _1'>
                <div className="pricing-item__col-text text-left">
                  {feature?.link ?
                    <a href={feature?.link}>
                      {feature?.title}
                    </a>
                    :
                    feature?.title
                  }

                </div>
              </div>
              {feature?.options?.map((option, i) => (
                <div key={`feature-option_${index}_${i}`}
                  className='pricing-item__col'>
                  <div className="pricing-item__col-text top">
                    {option}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )
        )}
      </div>
      <EmailVerificationContainer showModal={showVerificationModal}
        setShowModal={setShowVerificationModal} />
    </div>
  )
}
