import './scss/index.scss';

import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { noop } from 'lodash';

import { getChunksFromData } from './getChunksFromData'
import { useProgress } from '../hooks/useProgress';

import {
    AIAskContainer,
    useGetChunk,
    useGetImage,
    useGetImagesDalle,
    useGetAiVideo,
    useGenerateAudioChunks,
} from '../../ai';
import { VideoCustomPlayer } from './VideoCustomPlayer';
import { VideoCustomPlaceholder } from './VideoCustomPlaceholder';
import { VideoEditorContainer } from './VideoEditor.container/VideoEditor.container'
import { useEditTask } from '../../task/hooks/useEditTask';

export const VideoGeneratorButton = ({
    task,
    coverFromTask,
    user,
    handleEditVideoTask = noop,
    setVideoSrc = noop,
    setCover = noop,
    setIsModalVisible = noop,
    userIsAllowGenerateContent,
    contentForVideo,
    showAiForStudents,
    event,
    onAfterSetIsEditMode = noop,
    ...other
}) => {

    const [chunks, setChunks] = useState();
    const { inProgress, handleChangeProgress } = useProgress();
    const [isEditMode, setIsEditMode] = useState(false);
    const [error, setError] = useState();
    const [mermaidCode, setMermaidCode] = useState();
    const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
    const [isMobile, setIsMobile] = useState(false);
    const [voiceMode, setVoiceMode] = useState();

    const { getChunk } = useGetChunk();
    const { getImage } = useGetImage();
    const { getAiImage } = useGetImagesDalle();
    const { formatMessage } = useIntl();
    const { generateAudioForChunks } = useGenerateAudioChunks();
    const { getGenerateVideoAndIntro } = useGetAiVideo(task, handleChangeProgress);
    const { getGenerateVideo } = useGetAiVideo(task, handleChangeProgress);
    const { editTask } = useEditTask();

    const currentSlide = chunks?.length && chunks[currentSlideIndex];

    const generateImagesForChunks = async (updatedChunks) => {
        handleChangeProgress({
            message: formatMessage({ id: 'progress_images' }),
            color: event?.subjects?.[0]?.color || task?.tags?.[0]?.subject?.color,
            key: 'progress_images'
        })
        const imagePromises = updatedChunks.map(async (chunk) => {
            if (chunk.images) {
                chunk.images = await getImage(chunk.images || chunk.title);
            }
            if (chunk.ill) {
                chunk.ill = await getAiImage(`Create a minimalistic 3D visualisation with dark red, blue, green for this topic: ${chunk?.ill} ${chunk?.title} `);
            }
            return chunk;
        })
        try {
            const updatedChunksImages = await Promise?.all(imagePromises);
            handleChangeProgress({ key: 'progress_images' })
            return updatedChunksImages;
        }
        catch (error) {
            handleChangeProgress({ key: 'progress_images' })
            setError(error);

        }
    }

    const getDescriptionToChunk = async (ask, topicVideo) => {
        handleChangeProgress({
            message: formatMessage({ id: 'progress_chunk' }),
            color: event?.subjects?.[0]?.color || task?.tags?.[0]?.subject?.color,
            key: 'progress_chunk'
        })


        let jsonString = await getChunk(ask, topicVideo)
        handleChangeProgress({ key: 'progress_chunk' })
        let startIndex = jsonString.indexOf('{');
        let endIndex = jsonString.lastIndexOf('}');

        if (startIndex !== -1 && endIndex !== -1) {
            let validJsonString = jsonString?.substring(startIndex, endIndex + 1);
            try {
                return JSON.parse(validJsonString);
            } catch (error) {
                console.error("Parsing error:", error);
            }
        } else {
            console.error("No valid JSON found in the response");
        }

    };

    const handleGenerateVideo = async () => {
        try {

            if (task?.description?.length) {
                let { coverData, updatedChunksWithImages } = await getGenerateVideo(task?.description, voiceMode, handleChangeProgress)
                if (coverData) {
                    handleChangeProgress({ key: 'progress_video_saving' })
                    setCover(coverData.cover);
                    handleEditVideoTask(coverData);

                    setVideoSrc('');
                    setChunks(updatedChunksWithImages);
                }
                setIsModalVisible(false)
            } else {

                const resp = await getGenerateVideoAndIntro(task, event, voiceMode);
                const { coverData, markdown, error, updatedChunksWithImages } = resp || {};
                if (error) { setError(error) }
                handleChangeProgress({ key: 'progress_video_saving' })
                handleEditVideoTask(coverData, markdown);

                setCover(coverData.cover);
                setVideoSrc('');
                setChunks(updatedChunksWithImages);
                setIsModalVisible(false)
            }

        } catch (error) {
            setError(error)
        }
    }

    //save
    const editGallery = async (updatedChunksWithImages) => {
        if (updatedChunksWithImages) {
            handleChangeProgress({
                message: formatMessage({ id: 'progress_video_saving' }),
                color: event?.subjects?.[0]?.color || task?.tags?.[0]?.subject?.color,
                key: 'progress_video_saving'
            })
            let timeStampsList = [];
            setChunks(updatedChunksWithImages);
            for (const [i, chunk] of updatedChunksWithImages.entries()) {
                let description = {
                    description: chunk.description,
                    main_idea: chunk.main_idea,
                    ill: chunk.ill,
                    images: chunk.images,
                    audio: chunk.audio,
                    example: chunk.example,
                    code: chunk.code,
                    diagram: chunk.diagram,
                };
                timeStampsList.push({
                    title: chunk.title,
                    description: JSON.stringify(description),
                    timestamp: `${i}`,
                    id: chunk.id
                });
            }

            let coverData = {
                cover: {
                    type: "VIDEO",
                    source: "",
                    raw: "",
                    id: task?.cover?.id,
                    timestamps: [...timeStampsList]
                },
            };

            let tagIdsArray = [];
            task?.tags?.forEach((tag) => tagIdsArray.push(tag.id));

            await editTask({
                variables: {
                    taskId: task?.id,
                    taskData: {
                        tagIds: tagIdsArray,
                        ...coverData,
                    },
                    isOwner: userIsAllowGenerateContent,
                },
            });
            handleChangeProgress({ key: 'progress_video_saving' })
        }
    }


    const handleGenerateSlide = async (value) => {
        const newChunk = await getDescriptionToChunk(value.ask, `${currentSlide.title} ${currentSlide.description} in the topic ${task.title}`);

        if (!!newChunk?.description) {
            let currentIndexNumber = parseInt(currentSlideIndex);
            handleChangeProgress({
                message: formatMessage({ id: 'progress_audio' }),
                color: event?.subjects?.[0]?.color || task?.tags?.[0]?.subject?.color,
                key: 'progress_audio'
            });
            let updatedChunks = await generateAudioForChunks([newChunk], voiceMode, setError);
            handleChangeProgress({ key: 'progress_audio' });

            let updatedChunksWithImages = await generateImagesForChunks(updatedChunks);
            let chunk = updatedChunksWithImages[0];
            chunk.diagram = chunk?.diagram?.replace('mermaid', '')?.replaceAll('```', '');
            chunk.timestamp = `${currentIndexNumber + 1}`;

            let f = [...chunks];
            f = f.map((item) => {
                let index = parseInt(item?.timestamp);
                if (index > currentIndexNumber) {
                    return {
                        ...item,
                        timestamp: `${index + 1}`
                    }
                } else {
                    return item;
                }
            })

            f.splice(currentIndexNumber + 1, 0, chunk);
            setChunks(f);
            setCurrentSlideIndex(currentIndexNumber + 1)

            if (user?.id === task?.creator?.id || user?.role?.id === 1) {
                //normalise for saving
                let timeStampNew = {
                    title: chunk.title,
                    description: JSON.stringify({
                        description: chunk.description,
                        main_idea: chunk.main_idea,
                        ill: chunk.ill,
                        images: chunk.images,
                        audio: chunk.audio,
                        example: chunk.example,
                        code: chunk.code,
                        diagram: chunk.diagram,
                    }),
                    timestamp: chunk.timestamp,
                };
                let timeStampsListClone = [...task?.cover?.timestamps];

                //save
                timeStampsListClone = timeStampsListClone.map((item) => {
                    let index = parseInt(item?.timestamp)
                    if (parseInt(item?.timestamp) > currentIndexNumber) {
                        return {
                            ...item,
                            timestamp: `${index + 1}`
                        }
                    } else {
                        return item;
                    }
                })
                timeStampsListClone.splice(currentIndexNumber, 0, timeStampNew);
                let coverData = {
                    cover: {
                        type: "VIDEO",
                        source: "",
                        id: task?.cover?.id,
                        timestamps: [...timeStampsListClone]
                    },
                };

                handleEditVideoTask(coverData);
            }
        }
    }
    
    const handleSetEditMode = (isEdit) => {
        setIsEditMode(isEdit);
        onAfterSetIsEditMode(isEdit);
    }

    useEffect(() => {
        const cover = coverFromTask;
        if (cover?.type === 'VIDEO' && cover?.source === '') {
            const transformedData = getChunksFromData(cover)
            setChunks(transformedData);
        } else {
            setChunks(null);
        }
    }, [task?.cover]);

    return (
        <div >
            {(!inProgress && !!chunks?.length) && (
                <VideoCustomPlayer
                    currentSlideIndexProp={currentSlideIndex}
                    setCurrentSlideIndexProp={setCurrentSlideIndex}
                    chunks={chunks}
                    setInProgress={handleChangeProgress}
                    mermaidCode={mermaidCode}
                    setIsMobile={setIsMobile}
                    isMobile={isMobile}
                    setIsEditMode={handleSetEditMode}
                    isEditMode={isEditMode}
                    userIsAllowGenerateContent={userIsAllowGenerateContent}
                    setVoiceMode={setVoiceMode}
                    voiceMode={voiceMode}
                    task={task}
                    {...other}
                />
            )}
            {(inProgress || (!chunks?.length && userIsAllowGenerateContent)) &&
                <VideoCustomPlaceholder
                    inProgress={inProgress}
                    task={task}
                    handleGenerateVideo={handleGenerateVideo}
                    userIsAllowGenerateContent={userIsAllowGenerateContent}
                    error={error}
                    setVoiceMode={setVoiceMode}
                    voiceMode={voiceMode}
                    user={user}
                />
            }

            {showAiForStudents && currentSlide ?
                <AIAskContainer
                    task={task}
                    inProgressHighlight={inProgress}
                    handleRequests={handleGenerateSlide}
                    placeholder={formatMessage({ id: 'ask_ai_video' })}
                    disabled={!user?.id}
                    {...other}
                />
                : null
            }

            {isEditMode &&
                <VideoEditorContainer
                    {...other}
                    chunks={chunks}
                    isEditMode={isEditMode}
                    currentSlideIndex={currentSlideIndex}
                    setCurrentSlideIndex={setCurrentSlideIndex}
                    setInProgress={handleChangeProgress}
                    mermaidCode={mermaidCode}
                    setIsMobile={setIsMobile}
                    isMobile={isMobile}
                    setIsEditMode={handleSetEditMode}
                    editGallery={editGallery}
                    setChunks={setChunks}
                    task={task}
                    user={user}
                />
            }
        </div>
    );
}
