import './scss/index.scss';
import React from 'react';
import { useIntl } from 'react-intl';

export const TagStringComponent = ({ tag, countResults }) => {
    const { formatMessage } = useIntl();
    return (
        <>
            {tag?.title}️
            {countResults?.done > 0 ? (
                <>
                    <small
                        style={{
                            fontSize: '12px',
                            verticalAlign: 'middle',
                            opacity: 0.4,
                        }}
                    >
                        &nbsp;
                        {/* ★ */}
                        {countResults?.done}/{countResults.all}&nbsp;
                        {formatMessage({ id: 'ai_popup_steps' })}
                    </small>
                    {/* ∙ */}
                </>
            ) : null}
        </>
    );
};
