import './scss/index.scss';
import React from 'react';
import {LazyLoadImage} from 'react-lazy-load-image-component';
import {CircleIndexComponent} from '../../common/CircleIndex.component/CircleIndex.component';
import {SuperTextComponent} from '../../common/SuperText.component/SuperText.component';

export const StepCardComponent = (props) => {
    const {description, src, index, shear, color} = props;

    return (
        <div
            className='step_wrapper'
            style={{ backgroundColor: color || "#2962FF"}}
        >
            <div className='step_picture'>
                <LazyLoadImage src={src}
                               alt={description}/>
            </div>
            <div className='step_description'>
                <SuperTextComponent description={description} />

                <CircleIndexComponent index={index} />
            </div>
        </div>
    );
};
