import React from 'react';
import { useIntl } from "react-intl";
import { useGetLangs } from "../../common/graphQL";
import {IncludesComponent} from '../Includes.component/Includes.component';
import {DripExplanation} from '../../common/';

export const IncludesContainer = (props) => {
    const { event, reading } = props;
    const { formatMessage } = useIntl();
    const { langs } = useGetLangs();

    const showHDVideoLectures = event?.tasks?.some(task => (
        (task.cover?.type === 'VIDEO' && !!task.cover?.timestamps?.length) ||
        task.cover?.type === 'YOUTUBE' ||
        task.cover?.type === 'VIMEO'
    ));
    const numberOfPracticalTasks = event?.tasks?.reduce((acc, task) =>
        acc + (task?.questions?.filter(question => question.type === 'TEXT').length || 0), 0
    );
    const numberOfQuizTypeQuestions = event?.tasks?.reduce((acc, task) =>
        acc + (task?.questions?.filter(question => question.type === 'SINGLE_SELECT').length || 0), 0
    );
    const language = langs?.find(lang => lang.id === event?.langId) ; 
    const features = [
        {
            icon: 'faGlobe',
            text: formatMessage({ id: 'includes_language' }, { language: language?.title || 'English'})
        },
        {
            icon: 'faUserClock',
            text: formatMessage({ id: 'includes_pace' })
        },
        {
            icon: 'faMobileAlt',
            text: formatMessage({ id: 'includes_availability' })
        },

        ...(event?.isHideAi ? [] : [
            { icon: 'faRobot', text: formatMessage({ id: 'includes_ai_bot' }) },
            { icon: 'faComments', text: formatMessage({ id: 'includes_ai_video' }) }
        ]),

        {
            icon: 'faBookReader',
            text: formatMessage({ id: 'includes_subtitles' })
        },
        {
            icon: <DripExplanation formatMessage={formatMessage} event={event}/>,
            text: <DripExplanation formatMessage={formatMessage} event={event} />
        },

    ];
    const includes = [
        {
            icon: 'faClock',
            text: `${formatMessage({ id: 'includes_duration' })}: ${reading}`
        },

        ...(showHDVideoLectures ? [{
            icon: 'faVideo',
            text: formatMessage({ id: 'includes_videoLectures' })
        }] : []),

        ...(numberOfPracticalTasks > 0 ? [{
            icon: 'faDumbbell',
            text: `${formatMessage({ id: 'includes_practice' })}: ${numberOfPracticalTasks}`
        }] : []),

        ...(numberOfQuizTypeQuestions > 0 ? [{
            icon: 'faCheckCircle',
            text: `${formatMessage({ id: 'includes_assessments' })}: ${numberOfQuizTypeQuestions}`
        }] : [])
    ];

    return <IncludesComponent includes={includes} features={features} />;
}