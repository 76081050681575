import "./scss/index.scss";
import React, { useState, useEffect, useMemo, useContext } from 'react';
import { useIntl } from 'react-intl';
import { Modal } from 'antd';

import { LoginContainer, useTrackClick, getStorageManager } from '../../common';
import { checkResult } from '../../common/utils';
import { QuestionsCourseFormComponent } from '../QuestionsCourseForm.component';
import { QuestionsTestFormComponent } from '../QuestionsTestForm.component';
import { openNotification } from '../../notification/Notification.component';
import { ProgramsSubjectIconComponent } from '../../program/ProgramsSubjectIcon.component';
import { useLocalePrefix } from '../../lang';
import { useUpdateResult } from '../../result/hooks/useUpdateResult';
import { NextStepComponent } from '../../task/NextStep.component/';
import { TodoListContainer } from '../../profile/TodoList.container/TodoList.container'
import { useCreateUserCertificate } from '../../certificates/hook/useCreateUserCertificate';
import { useCheckQuestion } from '../hooks/useCheckQuestion';
import { SuccessScreenComponent } from '../SuccessScreen.component/SuccessScreen.component';
import {FirebaseContext} from '../../../context/firebase/firebaseContext';

const initFormAnswer = {
    optionIds: undefined,
    answer: undefined,
};
export const QuestionsFormContainer = (props) => {
    const {
        user,
        task,
        setUpdate,
        updateTask,
        event,
        sayMeAnswers,
        result,
        stepId,
        results,
        inProgress,
        inProgressNextStep,
        setResult,
        singleMode,
        singleLesson,
        taskResult,
        handleCheckResultAi,
        ...other
    } = props;

    // const { getStorageData } = getStorageManager();
    // const currentTodoList = getStorageData('currentTodoList');
    const {workspace} = useContext(FirebaseContext);
    const findQuestion = task?.questions?.find((question) => question.id == stepId);
    const findIndexQuestion = task?.questions?.findIndex((question) => question.id == stepId);
    let answerIsExist = result?.answers?.find((answer) => answer?.questionId === findQuestion?.id);
    const resultsInQuestion = [];
    results?.forEach((result) => {
        const answerIsExist = result.answers.find(
            (answer) => answer.questionId === findQuestion?.id
        );

        if (answerIsExist) {
            let initFormAnswer = {
                answer: answerIsExist.answer,
                user: result.user,
                date: result.createDate,
                state: result.state,
                id: result.id,
            };
            resultsInQuestion.push(initFormAnswer);
        }
    });
    const questionsLength = useMemo(() => task?.questions?.length, [task?.id]);
    const isInteractive = (findQuestion?.type === "SINGLE_SELECT" || findQuestion?.type === "MULTI_SELECT" || (findQuestion?.type === "TEXT" && findQuestion?.answer)) && !answerIsExist;

    const { formatMessage } = useIntl();
    const [localePrefix] = useLocalePrefix();
    const trackClick = useTrackClick();
    const { updateResult, loading: loadingUpdateResult, error, } = useUpdateResult();
    const { checkQuestion, loading } = useCheckQuestion();
    const { createUserCertificate } = useCreateUserCertificate();

    const [indexOfQuestion, setIndexOfQuestion] = useState(findIndexQuestion);
    const [showLogin, setShowLogin] = useState();
    const [currentResult, setCurrentResult] = useState();
    const [showFreeResultForm, setShowFreeResultForm] = useState(false);
    const [showConfetty, setShowConfetty] = useState(false);
    const [feedbackRegenerate, setFeedbackRegenerate] = useState();
    const [gotAchievement, setGotAchievement] = useState();
    const [answerLocal, setAnswerLocal] = useState(answerIsExist);
    const [formAnswer, setFormAnswer] = useState(initFormAnswer);
    const [showExplanation, setShowExplanation] = useState(answerIsExist);
    const [showSuccessScreen, setShowSuccessScreen] = useState(false);

    const showNotificationTag = (tag) => {
        openNotification('topRight', {
            title: (
                <div>
                    {formatMessage({ id: 'notif_achieve_subject' })}
                    <h2>{`${tag.title}`}</h2>
                </div>
            ),
            description: (
                <p>
                    {formatMessage({ id: 'congrats' })}
                    <a href={`${localePrefix}/skills`}>
                        {' '}
                        {formatMessage({ id: 'notif_achieve_theme_skills' })}
                    </a>
                </p>
            ),
            duration: 5,
            style: { backgroundColor: `${tag.subject?.color}` },
            icon: (
                <ProgramsSubjectIconComponent
                    subject={{
                        color: '#ffca05',
                        icon: tag.icon || tag?.subject?.icon,
                        subjectUid: tag.id,
                        title: tag.title,
                    }}
                    style={{ position: 'static' }}
                />
            ),
        });
    };

    // add notification for course creator and instructor
    const checkTasksAndIssueCertificate = async () => {
        const allTasksValid = taskResult.every(task => task.stateResult === 'CORRECT' || task.stateResult === 'INCORRECT');
        const { id, workspaceId } = event || {};
        const {id: wId} = workspace || {};

        if (allTasksValid && event?.certificate?.id) {
            await createUserCertificate({
                variables: {
                    certificateId: event?.certificate?.id,
                    userId: user?.id,
                    userName: user?.name,
                    ...(wId && {workspaceId: wId})
                }
            }, { id, workspaceId });
        }
    };
    const onUpdateResult = async (answerAtr, newPartOfDescription, fileList) => {
        trackClick(
            'click-resultform',
            task.id,
            `https://unschooler.me${localePrefix}/tutorials/${task.id}`
        );

        let answers = [...result?.answers || []];
        let answerExisted = answers?.findIndex(
            (el) => el?.questionId === answerAtr?.questionId
        );

        if (answerAtr) {
            answerAtr.options = answerAtr?.optionIds?.map((optionId) => {
                return findQuestion?.options?.find((option) => option.id === optionId);
            });
            if (answerExisted >= 0) {
                answers[answerExisted] = answerAtr;
            } else {
                answers = [...answers, answerAtr];
            }
        }
        sayMeAnswers(answers);

        if (user?.id) {
            const answersData = answers ? answers.filter((answ) => !!answ) : [];
            let normalisedAnswers = [];
            answersData?.forEach((ans) => {
                normalisedAnswers.push({
                    answer: ans.answer,
                    questionId: ans.questionId,
                    optionIds: ans.optionIds
                        ? ans.optionIds
                        : ans.options?.map((option) => option.id),
                });
            });

            let newResult = {
                answers: normalisedAnswers,
                taskId: task.id,
                description: (result?.description ? result?.description : '') + '\n\n' + (newPartOfDescription ? newPartOfDescription : '') || '',
                ...((fileList && !!fileList[0]) && {
                    cover: {
                        source: fileList[0]?.url,
                        title: fileList[0]?.title,
                        type: 'TEXT',
                    }
                })
            }
            const { id, workspaceId } = event || {};
            const updatedResult = await updateResult({
                variables: {
                    resultData: {
                        ...newResult,
                    },
                },
            }, { id, workspaceId });

            if (
                result?.state === 'NOT_VERIFIED' && updatedResult.data.updateResult.state === 'CORRECT'
            ) {
                const subjects = [];
                for (const tag of task.tags) {
                    if (subjects.findIndex((e) => e.id === tag.subject.id) === -1)
                        subjects.push(tag.subject);
                    showNotificationTag(tag);
                }
            }

            await setResult((prev) => {
                let cloneResult = { ...result || {} };
                cloneResult.answers = answers;
                cloneResult.state = updatedResult.data.updateResult.state;
                return cloneResult
            });

            if (!event?.certificate?.isAppliedForCurrentUser) {
                await checkTasksAndIssueCertificate();
            }
        }
    };
    const handleNextCheck = async () => {
        trackClick("click-question", findQuestion?.id, `https://tutorial/${task.id}`);
        const answer = { ...formAnswer, questionId: findQuestion?.id, };

        const sendUpd = () => {
            setShowExplanation(true);
            setShowConfetty(true);
            setTimeout(() => { setShowConfetty(false); }, 1000);
            user?.id && onUpdateResult(answer);

        }
        if ((findQuestion?.type === "TEXT" && !findQuestion?.answer && !answerIsExist) || (findQuestion?.type === "DEFAULT" && !answerIsExist)) {
            answer.answer = "Done";
            sendUpd()
        }

        if (!findQuestion && !task?.questions?.length) {
            sendUpd()
        }
    };
    const handleCheck = async (optionId) => {
        trackClick("click-question", findQuestion?.id, `https://tutorial/${task.id}`);
        const answer = {
            ...formAnswer,
            questionId: findQuestion?.id,
            optionIds: optionId ? [optionId] : formAnswer.optionIds,
        };

        if (findQuestion?.type === "TEXT") {
            if (!!answer?.answer?.length) {
                // transfer on backend someday
                const aiResponse = findQuestion?.answer && (await handleCheckResultAi(findQuestion?.id, formAnswer?.answer));
                answer.answer = aiResponse ? answer.answer + aiResponse : answer.answer;
                setAnswerLocal(answer);
            }
        }

        if (findQuestion?.type === "SINGLE_SELECT" || findQuestion?.type === "MULTI_SELECT") {
            await checkQuestion({ variables: { answer }, fetchPolicy: "no-cache", });
        }

        setShowExplanation(true);
        setShowConfetty(true);
        setTimeout(() => { setShowConfetty(false); }, 1000);
        user?.id && onUpdateResult(answer);
    };

    //set result for this page 
    useEffect(() => {
        if (result) {
            setResult(result);
        }
    }, [result?.description]);

    useEffect(() => {
        if (event?.isSubscribe && !event?.certificate?.isAppliedForCurrentUser) {
            (async () => await checkTasksAndIssueCertificate())();
        }
    }, [event?.isSubscribe]);

    useEffect(() => {
        setIndexOfQuestion(findIndexQuestion);
    }, [stepId]);

    useEffect(() => {
        if (findQuestion) {
            setCurrentResult(checkResult(result, findQuestion));
        }
        else {
            setCurrentResult(result?.state);
        }
    }, [stepId, result]);

    return (
        <div className='question-content'>
            {!!task?.taskInEvents?.length ?
                <QuestionsCourseFormComponent
                    loadingUpdateResult={loadingUpdateResult}
                    user={user}
                    task={task}
                    setResult={setResult}
                    result={result}
                    onUpdateResult={onUpdateResult}
                    isSingleMode={!questionsLength}
                    inProgress={inProgress}
                    inProgressNextStep={inProgressNextStep}
                    event={event}
                    question={findQuestion}
                    stepId={stepId}
                    indexOfQuestion={indexOfQuestion || findIndexQuestion}
                    showFreeResultForm={showFreeResultForm}
                    results={results}
                    setShowFreeResultForm={setShowFreeResultForm}
                    showConfetty={showConfetty}
                    currentResult={currentResult}
                    feedbackRegenerate={feedbackRegenerate}
                    setFeedbackRegenerate={setFeedbackRegenerate}
                    updateTask={updateTask}

                    showExplanation={showExplanation}
                    answerIsExist={answerIsExist}
                    answerLocal={answerLocal}
                    formAnswer={formAnswer}
                    handleCheck={handleCheck}
                    setShowExplanation={setShowExplanation}
                    setFormAnswer={setFormAnswer}
                    {...other}
                />
                :
                <QuestionsTestFormComponent
                    loadingUpdateResult={loadingUpdateResult}
                    user={user}
                    task={task}
                    setResult={setResult}
                    result={result}
                    onUpdateResult={onUpdateResult}
                    isSingleMode={!questionsLength}
                    event={event}
                    question={findQuestion}
                    inProgress={inProgress}
                    currentResult={currentResult}

                    stepId={stepId}
                    indexOfQuestion={indexOfQuestion || findIndexQuestion}
                    showFreeResultForm={showFreeResultForm}
                    results={results}
                    setShowFreeResultForm={setShowFreeResultForm}
                    {...other}
                />
            }

            {(!inProgress && !inProgressNextStep && !singleMode) &&
                <NextStepComponent
                    {...other}
                    findQuestion={findQuestion}
                    task={task}
                    setIndexOfQuestion={setIndexOfQuestion}
                    indexOfQuestion={indexOfQuestion}
                    event={!singleLesson && event}
                    currentResult={currentResult}
                    result={result}
                    handleCheck={handleNextCheck}
                    taskResult={taskResult}
                    isInteractive={isInteractive}
                    setShowSuccessScreen={setShowSuccessScreen}
                    showSuccessScreen={showSuccessScreen}
                    user={user}
                />
            }

            {showLogin && (
                <LoginContainer
                    showLogin={showLogin}
                    setCancelLogin={setShowLogin}
                />
            )}
            {showSuccessScreen && (
                <SuccessScreenComponent
                    onClose={() => setShowSuccessScreen(false)}
                    taskResult={taskResult}
                    user={user}
                    {...other}
                />
            )}

            <Modal
                visible={gotAchievement}
                title='You unlocked a new achievement!'
                onOk={() => {
                    setGotAchievement(false)
                }}
                footer={null}
                header={null}
                onCancel={() => setGotAchievement(false)}>

                <TodoListContainer
                    user={user}
                    task={task}
                    gotAchievement={gotAchievement}
                />

            </Modal>
        </div>
    );
};
