import {useMemo} from 'react';
import { gql, useQuery } from '@apollo/client';

const IS_ACTIVE = true;

export function useGetLangs() {
    const { data, loading, error } = useQuery(GET_LANGS, {
        fetchPolicy: 'cache-first',
        nextFetchPolicy: 'cache-only'
    });

    const langs = useMemo(() => (
        IS_ACTIVE ? data?.langs?.filter((lang) => lang?.isActive) : data?.langs
    ), [data]);

    return {
        langs,
        loading,
        error
    }
}

const GET_LANGS = gql`
    query GetLangs {
        langs {
            id
            code
            langUid
            title
            isActive
        }
    }
`;
