import React, {
  useEffect,
  useReducer,
  useState,
  useMemo,
  useCallback,
} from "react";
import { FirebaseContext } from "./firebaseContext";
import { firebaseReducer } from "./firebaseReducer";
import { message } from 'antd';

import {
  checkUserAgent,
  getLocaleFromUrl,
  getStorageManager,
  useTrackClick,
  redirectToUserLocale,
  getGeoLocation,
  hostname,
  workspaceCustomDomain
} from "../../Components/common";
// import { openNotification } from '../../Components/notification/Notification.component';

import firebase, { googleProvider } from "../../model/firebase";

import { NEW_ANSWER } from "../types";
import {
  useCreateUser,
  useGetLangs,
  useGetUser,
} from "../../Components/common/graphQL";
import { useGetPreference } from "../../Components/common/graphQL/preference/useGetPreference";
import { useGenerateCourse } from '../../Components/ai';
import { trackPartneroSignup } from '../../Components/common/utils/trackPartneroSignup';
import {useGetWorkspace} from '../../Components/workspace';

// const REDIRECT_UA = ["Instagram", "Facebook", "FBAN", "FBAV", "mobile"];
const REDIRECT_UA = ["Instagram", "Facebook"];
const DEFAULT_LANG_ID = 2;
let inProgressStatus = false;
let isCreateUserMode = false;
const STORAGE_KEY = "coins";

const DELAY_DATE = 2 * 60 * 1000;

const isNewUser = (d) => {
  const now = Date.now();
  const createDate = new Date(d).valueOf();
  return now - createDate < DELAY_DATE;
};

export const FirebaseState = ({ children }) => {
  const urlLocale = getLocaleFromUrl();
  const trackEvent = useTrackClick();
  const { getStorageData, setStorageData } = getStorageManager();
  const { generateCourse } = useGenerateCourse();
  const {getWorkspace, workspace, loading: workspaceLoading} = useGetWorkspace();

  const [user, setUser] = useState({});
  const [currencyId, setCurrencyId] = useState();
  const [currencyCode, setCurrencyCode] = useState();
  const [lang, setLang] = useState();
  const [locale, setLocale] = useState(urlLocale || "en");
  const [coinsLocal, setCoinsLocal] = useState();
  const [pref, setPref] = useState();
  const [loadingCourse, setLoadingCourse] = useState(false);
  const [generatedCourse, setGeneratedCourse] = useState();
  const [error, setError] = useState();
  const [inProgress, setInProgress] = useState();

  const { user: getUserData, getUser, loading: loadingGetUser } = useGetUser();
  const updateUser = () => getUser();

  const { createUser } = useCreateUser();
  const { preference, getPreference } = useGetPreference({
    fetchPolicy: "no-cache",
  });

  const [loadingData, setLoadingData] = useState(true);
  const [loading, setLoading] = useState(true);
  const [waitLoadingUser, setWaitLoadingUser] = useState(getStorageData('userId'));

  const loadingUser = useMemo(
    () => waitLoadingUser || loadingGetUser || loadingData,
    [loadingGetUser, waitLoadingUser, loadingData]
  );

  const initialState = {
    users: [],
    user: {},
    answerAdded: false,
    newAnswer: null,
  };

  const [state, dispatch] = useReducer(firebaseReducer, initialState);
  const isRedirect = checkUserAgent(REDIRECT_UA);
  const loadingOff = () => setLoadingData(false);

  const fetchUser = async () => {
    setLoading(true);
    firebase.auth().onAuthStateChanged(async (userLogged) => {
      if (!userLogged) {
        setWaitLoadingUser(false);
        isCreateUserMode = true;
        return loadingOff();
      }

      if (!inProgressStatus && !isCreateUserMode) {
        await loginOrCreateUser(userLogged);
      }
    });
  };

  const fetchIp = async () => {
    let storageData = getStorageData('country_code');

    if (!storageData) {
      const d = await getGeoLocation();
      setStorageData('country_code', `${d?.country_code}`);
    }
  };

  const fetchCoinsLocal = async () => {
    let storageData = getStorageData(STORAGE_KEY);
    setCoinsLocal(storageData);
  };

  const fetchPref = async () => {
    const d = await getPreference(user?.id);
    setPref(d);
    // let storageData = getStorageData(STORAGE_KEY);
    // setCoinsLocal(storageData);
  };

  const { langs } = useGetLangs();

  const loginOrCreateUser = async (userLogged = {}, partnerData) => {
    const { inviteId } = userLogged;
    let userId = getStorageData('userId');
    if (userId) { setWaitLoadingUser(false); }

    let user = await getUser(null, null, partnerData);
    const langIdFromLocal = langs?.find((lang) => lang.code === locale)?.id;

    if (!user?.googleUid) {
      user = await createUser({
        picture: userLogged?.photoURL || "",
        name: userLogged?.displayName || userLogged?.email?.replace(/@\w+.\w+/g, ''),
        email: userLogged?.email,
        googleUid: userLogged?.uid || user?.uid,
        currencyId: 1,
        langId: langIdFromLocal,
        emailVerified: !!inviteId || !!userLogged?.emailVerified,
        ...(!!inviteId && {inviteId})
      });
      user = { ...user, isNew: true };
      trackPartneroSignup(user.id, user.name, user.email);
      // if (!userLogged.photoURL) {
      //   userLogged.sendEmailVerification()
      //     .then(() => {
      //       openNotification('topLeft', {
      //         title: `Verify your email: ${userLogged.email}`,
      //         description: `We have sent you the link to your email, click on it.`,
      //         icon: '✉️',
      //         duration: 10,
      //       });
      //     })
      //     .catch((error) => {
      //       console.error('Error ErrorErrorErrorError', error);
      //       openNotification('topLeft', {
      //         title: `${error}`,
      //         icon: '✉️',
      //         duration: 10,
      //       });
      //     });
      // }

    }

    if (user.hidden) {
      await firebase.auth().signOut().then();
      window.location = "/";
      return null;
    }

    setUser({ ...user, ...(!!inviteId && { isInvited: true }) });
    setStorageData('userId', user.id);
    return user;
  };

  const signUpUserEmailPass = async (userCred) => {
    try {
      const resp = await firebase.auth().createUserWithEmailAndPassword(userCred.email, userCred.password);
      const user = resp.user;
      const { inviteId } = userCred;
      // eslint-disable-next-line
      inProgressStatus = true;
      const userData = await loginOrCreateUser({ ...user, ...(inviteId && { inviteId })});
      trackEvent("success-login");
      // eslint-disable-next-line
      inProgressStatus = false;
      return userData;

    } catch (err) {
      await setError(err.message);
      trackEvent(err?.code);
      // eslint-disable-next-line
      inProgressStatus = false;
      return err;
    }
  };


  const loginUserEmailPass = async (history, partnerData, userCred, signIn) => {
    let userData = {};

    if (signIn) {
      return await firebase.auth().signInWithEmailAndPassword(userCred.email, userCred.password)
        .then(async (userCredential) => {
          let user = userCredential.user;
          const { inviteId } = userCred;
          // eslint-disable-next-line
          inProgressStatus = true;
          userData = await loginOrCreateUser({ ...user, ...(inviteId && { inviteId }) });
          trackEvent("success-login");
          return userData;
        })

        .catch((error) => {
          setError(error.message);
          trackEvent(error?.code);
        });
    } else {
      await firebase.auth().createUserWithEmailAndPassword(userCred.email, userCred.password)
        .then(async (userCredential) => {
          let user = userCredential.user;
          // eslint-disable-next-line
          inProgressStatus = true;
          userData = await loginOrCreateUser(user, partnerData);
          const isNew = isNewUser(userData.createDate);
          const actionName = isNew ? "success-login-new-user" : "success-login";
          trackEvent(actionName);
        })
        .catch((error) => {
          trackEvent(error?.code);
          setError(error.message);
        });
      return userData;

    }
    // eslint-disable-next-line
    inProgressStatus = false;
  };

  const loginUser = async (history, partnerData, forceRedirect) => {
    let userData = {};
    const isForceRedirect = forceRedirect && (typeof forceRedirect === 'boolean');

    if (isRedirect || isForceRedirect) {
      console.log('REDIRECT')
      await firebase.auth().signInWithRedirect(googleProvider);
      return;
    }

    if (!isRedirect) {
      console.log('NOT REDIRECT');
      try {
        const result = await firebase.auth().signInWithPopup(googleProvider);
        // eslint-disable-next-line
        inProgressStatus = true;
        userData = await loginOrCreateUser(result, partnerData);
        const isNew = isNewUser(userData.createDate);
        const actionName = isNew ? "success-login-new-user" : "success-login";
        trackEvent(actionName);
      } catch (error) {
        trackEvent(error?.code);
        console.warn(error, "Error auth");
      }
    }
    // eslint-disable-next-line
    inProgressStatus = false;
    return userData;
  };

  const updateNewAnswer = (task) => {
    dispatch({ type: NEW_ANSWER, payload: task });
  };

  const getCurrentLang = useCallback(
    (id) => langs.find((lang) => lang.id === id),
    [langs]
  );

  const getCurrentLangByCode = useCallback(
    (code) => {
      return langs.find((l) => l.code === code.toLowerCase());
    },
    [langs]
  );

  const handleGenerateCourse = async (event, currentLink) => {
    setLoadingCourse(true);
    const key = 'updatable';
    message.config({ placement: 'topRight', });
    message.loading({
      content: 'Course is generating, check your email in 10-15 minutes...',
      key,
    });

    localStorage.setItem('buttonPressTime', Date.now().toString());
    setTimeout(() => {
      localStorage.removeItem('buttonPressTime');
      setLoadingCourse(false);

    }, 15 * 60 * 1000);

    try {
      const resp = await generateCourse(event);
      setGeneratedCourse(resp?.event);

      message.success({
        content: 'Course is Ready! Click here to access the course.',
        key,
        duration: 30,
        onClick: () => {
          window.open(currentLink, '_blank');
        }
      });
      setLoadingCourse(false);
    } catch (err) {
      message.error({ content: err.message, key, });
      setLoadingCourse(false);
    }
  }

  const onSetUser = async () => {
    await setUser((prev) => {
      const { isNew } = prev;
      const newParam = isNew ? { isNew } : {};
      return { ...getUserData, ...newParam };
    });
    loadingOff();
  };

  const onUpdateWorkspace = async () => {
    const {id} = workspace || {};
    await getWorkspace({
      variables: {
        ...(id ? {id} : workspaceCustomDomain ? {customDomain: workspaceCustomDomain} : {host: hostname})
      },
      fetchPolicy: "network-only"
    })
  };

  useEffect(() => {
    if (hostname || workspaceCustomDomain) {
      (async () => onUpdateWorkspace())()
    }
  }, [hostname, workspaceCustomDomain]);

  useEffect(() => {
    if (getUserData) {
      (async () => await onSetUser())();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUserData]);

  useEffect(() => {
    if (getUserData && lang) {
      const { lang: userDataLang } = getUserData;

      if (userDataLang?.id && userDataLang?.id !== lang?.id) {
        redirectToUserLocale(userDataLang?.id, langs);
      }
    }

    if (getUserData && user?.id) {
      setWaitLoadingUser(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, getUserData]);

  useEffect(() => {
    (async () => fetchIp())();
    if (!isRedirect) return;
    (async () => {
      let userData = 1;
      try {
        const result = await firebase.auth().getRedirectResult();
        userData = await loginOrCreateUser(result);
        const isNew = isNewUser(userData.createDate);
        const actionName = isNew ? "success-login-new-user" : "success-login";
        trackEvent(actionName);
      } catch (error) {
        trackEvent(error?.code);
        console.warn(error);
      }
      // eslint-disable-next-line
      inProgressStatus = false;
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!langs) return;

    if (urlLocale) {
      const currentLang = getCurrentLangByCode(urlLocale);
      setLocale(currentLang?.code);
      setLang(currentLang);
      setLoading(false);
      return;
    }

    if (!loadingData) {
      const { lang } = user;
      let currentLang;
      if (lang) {
        const { id } = lang;
        currentLang = getCurrentLang(id) || getCurrentLang(DEFAULT_LANG_ID);
      } else {
        const sessionLangId = window.sessionStorage.getItem("langId");
        if (sessionLangId) {
          currentLang = getCurrentLang(Number(sessionLangId));
        } else {
          const loc = locale || "en";
          const langId =
            langs?.find((lang) => lang?.code === loc)?.id || DEFAULT_LANG_ID;

          currentLang = getCurrentLang(Number(langId));
        }
      }
      setLocale(currentLang?.code);
      setLang(currentLang);
      setLoading(false);
    }
  }, [
    loadingData,
    langs,
    urlLocale,
    getCurrentLangByCode,
    user,
    getCurrentLang,
    locale,
  ]);

  useEffect(() => {
    const startTime = localStorage.getItem('buttonPressTime');
    if (startTime) {
      const elapsed = Date.now() - parseInt(startTime, 10);
      const remainingTime = 15 * 60 * 1000 - elapsed;

      if (remainingTime > 0) {
        setLoadingCourse(true);
        const timerId = setTimeout(() => {
          setLoadingCourse(false);
          localStorage.removeItem('buttonPressTime');
        }, remainingTime);

        return () => clearTimeout(timerId);
      } else {
        localStorage.removeItem('buttonPressTime');
        setLoadingCourse(false);
      }
    }
  }, []);

  return (
    <FirebaseContext.Provider
      value={{
        loginUser,
        loginUserEmailPass,
        signUpUserEmailPass,
        fetchUser,
        updateNewAnswer,
        loading,
        user,
        updateUser,
        newAnswer: state.newAnswer,
        locale,
        lang,
        langs,
        coinsLocal,
        setCoinsLocal,
        fetchCoinsLocal,
        currencyId,
        setCurrencyId,
        currencyCode,
        setCurrencyCode,
        loadingUser,
        pref,
        preference,
        fetchPref,
        handleGenerateCourse,
        loadingCourse,
        generatedCourse,
        error,
        updateUserData: getUser,
        workspace,
        workspaceLoading,
        onUpdateWorkspace,
        inProgress,
        setInProgress
      }}
    >
      {children}
    </FirebaseContext.Provider>
  );
};
