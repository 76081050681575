import React from 'react';
import {useIntl} from 'react-intl';
import classNames from 'classnames';
import {Link} from 'react-router-dom';
import {LazyLoadImage} from 'react-lazy-load-image-component';

import {useResolutions} from '../common/utils';
import {useLocalePrefix} from '../lang';
import {
    CircleIndexComponent,
    VideoIframe,
    Svg,
    youtubeParser,
    TagComponent,
} from '../common';

export const TaskCardComponent = (props) => {
    const {task, handleDelete, user, size, topLevel, btnClass, ...other} =
        props;
    const {isSM} = useResolutions();

    const [localePrefix] = useLocalePrefix();
    const cardClass = classNames('card_result  card_generate card', {
        card_30: size === 30,
        card_50: size === 50,
        'card-promo': task?.id,
    })

    return (
        <Link
            className={cardClass}
            to={`${localePrefix}/tutorials/${task?.id}`}
        >
            <div className='task-component__info'>
                <h4>
                    {task.title.length > 80
                        ? task.title.substr(0, 80) + '…'
                        : task.title}
                </h4>

                {task?.cover?.type === 'YOUTUBE'
                    ? null
                    : // <div className='task-info-component__img'>
                      //     <VideoIframe
                      //         isAvailable={task?.isAvailable}
                      //         videoSrc={youtubeParser(task?.cover.source)}
                      //         timestampsList={task?.timestamps}
                      //     />
                      // </div>
                      task.cover?.type === 'IMAGE' && (
                          <div className='task-info-component__img'>
                              <div className='wrapper_image_card'>
                                  <LazyLoadImage
                                      className='picture_absolute'
                                      src={task.cover?.source}
                                      alt={task?.title}
                                  />
                              </div>
                          </div>
                      )}
                <div
                    className='card_result_html'
                    dangerouslySetInnerHTML={{
                        __html: task?.description?.replaceAll("#", ''),
                    }}
                />
            </div>
            <div className='task-component__tags'>
                {task?.tags?.map((tag) => (
                    <TagComponent
                        key={tag.id}
                        tag={tag}
                        color={tag?.subject?.color}
                        colorful={true}
                    />
                ))}
            </div>
        </Link>
    );
};
