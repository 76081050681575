import React from 'react';
import {useIntl} from 'react-intl';
import { Button } from 'antd';
import { noop } from 'lodash';
import { Svg } from "../../common";

export const MentorEditSwitcherComponent = (props) => {
    const {onEdit = noop,} = props;
    const {formatMessage} = useIntl();

    return (
        <Button type='text' onClick={onEdit}>
            <Svg name=':icon/edit' width={24} className='margin_right'/>
            <span>{formatMessage({ id: 'expert_edit' })}</span>
        </Button>
    );
};
