import './scss/index.scss';
import React from 'react';
import { useIntl } from 'react-intl';
import { ProfileLink } from '../../common';
import { ReactMarkdownComponent } from '../../task/ReactMarkdown.component/ReactMarkdown.component';

export const ResultComponent = (props) => {
    const { result, answer, state, isMe } = props;
    const { formatMessage } = useIntl();

    const testString =
        state === 'INCORRECT'
            ? `— ${formatMessage({ id: 'result_wrong_answer' })} ❌`
            : `— ${formatMessage({ id: 'result_right_answer' })} ✅`

    return (
        <div className='result wrapper_inner-section'>
            {isMe?.id !== result?.user?.id && (
                <div className='wrapper_inner-section'>
                    <ProfileLink
                        user={result?.user}
                    />
                    <small>{testString}</small>
                    <>
                        <ReactMarkdownComponent children={result?.description} />
                        {result?.cover?.source && <a href={result.cover.source}>{result?.cover?.title || result.cover.source}</a>}
                        <ReactMarkdownComponent children={answer?.answer} />
                        <hr />
                    </>
                </div>
            )}
        </div>
    );
};
