import React, { useEffect } from 'react';
import { Svg } from '../Svg';
let audio = new Audio('https://cdn.unschooler.me/web_app/tech_pictures/star.mp3');

export const Confetti = ({ fullscreen }) => {
    const classnames = fullscreen
        ? `wrapper_confetti wrapper_confetti_fullscreen`
        : `wrapper_confetti wrapper_confetti_fullscreen`;

    useEffect(() => {
        audio?.play();
    }, [])
    return (
        <div className={classnames}>
            <div className='confetti'>
                <div className='confetti-piece'>
                    <Svg name=':icon/star_1' />
                </div>
                <div className='confetti-piece'>
                    <Svg name=':icon/star_2' />
                </div>
                <div className='confetti-piece'>
                    <Svg name=':icon/star_3' />
                </div>
                <div className='confetti-piece'>
                    <Svg name=':icon/star_3' />
                </div>
                <div className='confetti-piece'>
                    <Svg name=':icon/star_2' />
                </div>
                <div className='confetti-piece'>
                    <Svg name=':icon/star_1' />
                </div>
                <div className='confetti-piece'>
                    <Svg name=':icon/star_1' />
                </div>
                <div className='confetti-piece'>
                    <Svg name=':icon/star_2' />
                </div>
                <div className='confetti-piece'>
                    <Svg name=':icon/star_4' />
                </div>
                <div className='confetti-piece'>
                    <Svg name=':icon/star_1' />
                </div>
                <div className='confetti-piece'>
                    <Svg name=':icon/star_2' />
                </div>
                <div className='confetti-piece'>
                    <Svg name=':icon/star_1' />
                </div>
                <div className='confetti-piece'>
                    <Svg name=':icon/star_3' />
                </div>
            </div>
        </div>
    );
};
