import './scss/index.scss'
import React, { useState } from 'react';
import { Button, Modal } from 'antd';
import { reportBrokenFile } from '../../../context/firebase/firebaseRequests';
import { TextToAudioContainer } from '../../audio/';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlag, faArrowsRotate } from '@fortawesome/free-solid-svg-icons';
import { notification } from 'antd';
import { useIntl } from 'react-intl';

const openReportNotification = () => {
    notification.open({
        message: 'Report Received',
        description: 'We have received your report and will contact you via email after the review.',
        icon: <FontAwesomeIcon icon={faFlag} />,
        duration: 3,
    });
};

export const AudioPlayerComponent = ({ entityId, typeEntity, audioUrl, user, text, isCreator }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const { formatMessage } = useIntl();

    return (
        <div className="audio-player-component ">
            <audio className="custom-audio-player" src={audioUrl} controls={true} controlsList={!isCreator && "nodownload"} />

            <Modal
                title={formatMessage({ id: 'regenerate' })}
                visible={isModalVisible}
                footer={null}
                onCancel={() => setIsModalVisible(false)}
            >
                {
                    typeEntity &&
                    <TextToAudioContainer
                        text={text}
                        questionId={typeEntity === 'question' ? entityId : null}
                        taskId={typeEntity === 'task' ? entityId : null}
                    />
                }
                <div className='wrapper_content-section'>
                    <p>{formatMessage({ id: 'audio_broken' })}</p>
                    <Button
                        className='edit-task-component__report '
                        type='text'
                        size='small'
                        onClick={() => {
                            reportBrokenFile(audioUrl, user?.id, entityId);
                            openReportNotification();
                        }}
                    >
                        <span>
                            <FontAwesomeIcon icon={faFlag} className='margin_right' />
                            {formatMessage({ id: 'report_bug' })}
                        </span>
                    </Button>
                </div>
            </Modal >
            {isCreator && (
                <Button
                    className='btn_report'
                    type='text'
                    onClick={() => setIsModalVisible(true)}
                    size='small'
                >
                    <FontAwesomeIcon icon={faArrowsRotate} style={{ color: 'black' }} />
                </Button>
             )} 
        </div >
    )
}