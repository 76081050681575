import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { QuestionsFormContainer, Thread, TaskProgressContainer, TaskPlaceholderComponent } from '../task';
import { getSelectionCoords, useQueryParams } from '../common';
import { HeaderTutorial } from '../headers';
import { TaskActions } from './TaskActions'

export const TaskCourseArticleComponent = (props) => {
    const {
        task,
        results = [],
        connectedTasks,
        user,
        event,
        handleSayMeAnswers,
        updateTask,
        result,
        highlightedRequest,
        handlePlanGenerate,
        inProgress,
        inProgressNextStep,
        stepId,
        isJoinRequired,
        userIsAllowGenerateContent,
        ...other
    } = props;
    const { isSingleMode, isSingleLesson } = useQueryParams();
    const coordsMemo = useMemo(
        () => getSelectionCoords(),
        [highlightedRequest, getSelectionCoords]
    );

    const editFunctions = (user?.id === task?.creator?.id) || (user?.role?.id === 1) || userIsAllowGenerateContent;
    const { formatMessage } = useIntl();

    return (
        <div className="task-article-component">
            {(!isSingleMode && !isSingleLesson) &&
            <div className="flex-right-column">
                <Thread
                    event={event}
                    task={task}
                    taskId={task?.id}
                    stepId={stepId}
                    result={result}
                    inProgressNextStep={inProgressNextStep}
                    inProgress={inProgress}
                    user={user}
                    canEdit={editFunctions}
                    {...other}
                />
            </div>
            }
            <div className="flex-column">
                {editFunctions &&
                <HeaderTutorial task={task}
                                type={"small"}
                                event={event}
                                updateTask={updateTask}
                                stepId={stepId}
                                customComponent={TaskActions}
                                user={user}
                                {...other} />}


                {isJoinRequired && (
                    <TaskPlaceholderComponent
                        task={task}
                        event={event}
                        user={user}
                        type={isJoinRequired}
                        {...other}
                    />
                )}

                {!isJoinRequired && (
                    <section className="article">
                        {!!task?.questions?.length && event?.isSubscribe &&
                            <TaskProgressContainer event={event} task={task} result={result} formatMessage={formatMessage} />
                        }

                        <QuestionsFormContainer
                            {...other}
                            id={task?.id}
                            task={task}
                            user={user}
                            event={event}
                            sayMeAnswers={handleSayMeAnswers}
                            updateTask={updateTask}
                            result={result}
                            results={results}
                            inProgress={inProgress}
                            inProgressNextStep={inProgressNextStep}
                            highlightedRequest={highlightedRequest}
                            coordsMemo={coordsMemo}
                            stepId={stepId}
                            singleMode={isSingleMode}
                            singleLesson={isSingleLesson}
                            userIsAllowGenerateContent={userIsAllowGenerateContent}
                        />
                    </section>
                )}
            </div>
        </div>
    );
}
