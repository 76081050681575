import { useCallback, useMemo, useEffect, useContext } from 'react';
import { recordCurrentCredits, fetchData } from '../../../context/firebase/firebaseRequests';
import { FirebaseContext } from '../../../context/firebase/firebaseContext';
import { PRICING_MODELS } from '../../constant';
import { useAiTokensStatistics } from '../../statistics';
import { useIsAllow } from './';

let DATA;
let IN_PROGRESS = false;

export const useTrackCredits = () => {
    const { user, workspace } = useContext(FirebaseContext);
    const { ownerId: workspaceOwnerId } = workspace || {};

    const isOwner = useMemo(() => !workspaceOwnerId || user?.id === workspaceOwnerId, [user?.id, workspaceOwnerId]);
    const ownerId = useMemo(() => workspaceOwnerId || user?.id, [workspaceOwnerId, user?.id]);
    const dataState = useMemo(() => DATA, [DATA]);

    const checkIsAllowed = useIsAllow();
    const isAllowUniversity = checkIsAllowed("UNIVERSITY");
    const isUniversity = !!isAllowUniversity && typeof isAllowUniversity === "boolean";
    const { getAiTokensStatistics } = useAiTokensStatistics();

    const saveInnerData = (prop, val) => {
        const newProp = {};
        newProp[prop] = val;
        DATA = {...(DATA && DATA), ...newProp};
    };

    const getCredits = useCallback(async (isUpdate = false) => {
        if ((ownerId && !DATA) || isUpdate) {
            IN_PROGRESS = true;
            const dbPath = `/currentCredits/${ownerId}/`;
            const dbData = await fetchData(dbPath);
            const values = dbData.val() || 0;
            
            if (values) {
                const {currentCredits, currentCourses, history} = values;
                if (currentCredits) {
                    saveInnerData('currentCredits', currentCredits);
                }
                if (currentCourses) {
                    saveInnerData('currentCourses', currentCourses);
                }
                
                if (history && Object.keys(history).length > 0) {
                    const lastPurchaseDate = Object.keys(history).sort((a, b) => new Date(b) - new Date(a))[0];
                    saveInnerData('lastPurchase', history[lastPurchaseDate]);
                }
            }
            IN_PROGRESS = false;
        }
    }, [ownerId]);

    const trackCredits = useCallback(async (audioTokens, model) => {
        if (!isUniversity) {
            const dbPath = `/currentCredits/${ownerId}/currentCredits`;
            const dbData = await fetchData(dbPath);
            const c = dbData.val() || 0;
            const price = Math.round((PRICING_MODELS[model] * audioTokens) * PRICING_MODELS.dollarsToCredits);
            await recordCurrentCredits(ownerId, c - price);
            await getCredits(true);
        }
    }, [getCredits, ownerId, isUniversity]);

    useEffect(() => {
        if (ownerId && isUniversity) {
            (async () => {
                const tokensData = await getAiTokensStatistics({ ownerId });
                saveInnerData('currentCredits', Math.round(5000 - tokensData.data.getAiTokens.totalCredits));
            })();
        }
        if (!IN_PROGRESS && ownerId && !isUniversity) {
            (async () => await getCredits())();
        }
    }, [ownerId, isUniversity]);

    return {
        trackCredits,
        isOwner,
        isLimitReached: !dataState?.currentCredits || dataState?.currentCredits <= 0,
        currentCredits: dataState?.currentCredits,
        currentCourses: dataState?.currentCourses,
        lastPurchase: dataState?.lastPurchase
    };
};

