/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from 'react';
import { noop } from 'lodash';
import { MentorEditFormComponent } from '../MentorEditForm.component';
import { useUpdateTeacher } from '../../common/graphQL';

import { FirebaseContext } from '../../../context/firebase/firebaseContext';
export const MentorEditFormContainer = (props) => {
    const { user, onClose = noop, setTeacherState, ...other } = props;
    const { updateTeacher, loading: loadingUpdateTeacher } = useUpdateTeacher();
    const { updateUser } = useContext(FirebaseContext);

    const handleUpdateUser = async (data) => {
        const { subjectIds, description, picture, cost, lessonType, listTimeData, name } = data;
        const newData = {
            subjectIds: subjectIds || [],
            description: description || '',
            cost: cost || 0,
            lessonType: lessonType || 'FREE',
            listTimeData: listTimeData || [],
            picture: picture || '',
            name: name || ''
        };
        await updateTeacher(newData);
        setTeacherState(newData)
        updateUser();
    };

    const handleSubmit = async (data) => {
        await handleUpdateUser({
            ...data,
            canTeach: true
        });
        setTeacherState(data)
        onClose(data);
    };

    return (
        <MentorEditFormComponent
            {...other}
            onClose={onClose}
            onSubmit={handleSubmit}
            updateUser={updateUser}
        />
    );
};
