import { useCallback } from 'react';
import { useAiChatResponse } from '../graphQL/useAiChatResponse';

export const useGetSummaryLongForm = () => {
    const { getChatResponse, error } = useAiChatResponse();

    const getSummaryLongForm = useCallback(
        async (longForm, goal, type = 'summariseLongForm', returnMaxNumberOfSymbols = 2000) => {
            const settings = {
                args: {
                    goal: goal,
                    request: longForm,
                    number: returnMaxNumberOfSymbols,
                },
                type: type,
            };

            const response = await getChatResponse({
                variables: {
                    input: settings,
                },
            });

            const responseJSON = response?.data?.requestChatApi && JSON.parse(response?.data?.requestChatApi);
            if (responseJSON?.choices?.[0]) {
                let responseTrimmed = responseJSON?.choices?.[0]?.message?.content?.trim();
                return responseTrimmed;
            } else {
                return '';
            }
        },
        [getChatResponse]
    );

    return { getSummaryLongForm, error };
};
