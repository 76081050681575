/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';    
import { Drawer } from 'antd';
import { useIntl } from 'react-intl';

import { MentorEditSwitcherComponent } from '../MentorEditSwitcher.component';
import { useGetTeacher, useGetUser } from '../../common/graphQL';
import { MentorCardContainer } from '../../event/MentorBlock.component/index';
import { useSubscribeEvent } from '../../event/hooks/useSubscribeEvent';
import { useUnsubscribeEvent } from '../../event/hooks/useUnsubscribeEvent';

export const MentorEditContainer = (props) => {
    const {
        onGetEvent,
        user,
        workspace,
        event,
        instructorSubscriptions,
        ...other
    } = props;
    const [isEdit, setIsEdit] = useState();
    const { getUser } = useGetUser();
    const { getTeacher, loading: loadingGetTeacher } = useGetTeacher();
    const [teachersState, setTeacherState] = useState();
    const instructors = workspace?.members?.filter(member => member?.permission === 'Instructor')?.map(member => member.id);
    const [selectedTeachers, setSelectedTeachers] = useState(instructorSubscriptions?.map(el => (el.studentId || el.id)));
    const { subscribeEvent } = useSubscribeEvent();
    const { unsubscribeEvent } = useUnsubscribeEvent();
    const { formatMessage } = useIntl();

    const handleChangeTeacher = async (selectedOption) => {
        if (!selectedTeachers.includes(selectedOption)) {
            setSelectedTeachers([...selectedTeachers, selectedOption]);
            await subscribeEvent({
                variables: {
                    eventId: event?.id,
                    type: 'FULL',
                    role: 'INSTRUCTOR',
                    studentId: selectedOption,
                },
            });
        }
        else {
            await unsubscribeEvent({
                variables: {
                    eventId: event?.id,
                    userId: selectedOption,
                },
            });
            setSelectedTeachers(selectedTeachers.filter(id => id !== selectedOption));
        }
    };

    const checkMentor = async () => {
        if (instructors?.length) {
            const teacherPromises = instructors.map(async memberId => {
                try {
                    const resp = await getTeacher(memberId);
                    if (!resp) {
                        const resp2 = await getUser(memberId);
                        return resp2;
                    } else {
                        return resp;
                    }
                } catch (err) {
                    console.warn(`Failed to fetch teacher for memberId ${memberId}:`, err);
                    return null;
                }
            });

            const teachers = await Promise.all(teacherPromises);
            setTeacherState(teachers.filter(teacher => teacher !== null));
        }
    };
    useEffect(() => {
        if (isEdit) {
            (async () => await checkMentor())();
        }
    }, [isEdit]);

    const handleEdit = () => {
        setIsEdit(true);
    };

    const handleClose = (data) => {
        setIsEdit(false);
        onGetEvent();
    };

    return (
        <>
            <MentorEditSwitcherComponent
                {...other}
                onEdit={handleEdit}
            />

            <Drawer
                visible={isEdit}
                onClose={handleClose}
                width={760}
                closable={true}
                maskClosable={false}
            >
                <h2>{formatMessage({ id: 'expert_edit' })}</h2>
                <MentorCardContainer
                    teacher={user}
                    user={user}
                    isEdit={isEdit}
                    handleChangeTeacher={handleChangeTeacher}
                    selectedTeachers={selectedTeachers}
                    {...other}
                />

                {teachersState && teachersState.length && teachersState.map(instructor => (
                    <MentorCardContainer
                        key={instructor.id}
                        teacher={instructor}
                        user={user}
                        handleChangeTeacher={handleChangeTeacher}
                        selectedTeachers={selectedTeachers}
                        isEdit={isEdit}
                    />
                ))}
                {workspace &&
                <Link to={`/students`}>
                    {formatMessage({ id: 'permissions_inviteInstructors' })}
                </Link>}
            </Drawer>
        </>
    );
};
