import './scss/index.scss';
import React from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { TagStringComponent } from './TagString.component';
import { useLocalePrefix } from '../../lang';
import { ProgramsSubjectIconComponent } from '../../program/ProgramsSubjectIcon.component';

export const TagComponent = ({
    tag,
    link,
    countResults,
    color,
    colorful,
    string,
    linkUrl,
}) => {
    const tagColor = tag?.subject?.color || tag?.color || color;

    const [localePrefix] = useLocalePrefix();
    const styleSpan = classnames('tag', {
        'wrapper__tag-link': link,
        'wrapper__tag-link_backcolor': countResults || colorful,
        'wrapper__tag-link_inlineblock': colorful,
        'wrapper__tag-link_color': colorful,
    });
    const stringUrl = string ? string : `tag/${tag?.id}`;
    const linkUrll = linkUrl ? linkUrl : `${localePrefix}/tutorials/${stringUrl}`;
    const progress = (countResults?.done / (countResults?.all / 100));
    return (
        <>
            <span
                className={styleSpan}
                style={{
                    color: tagColor && tagColor !== '#fff' ? tagColor : '#666',
                    width: progress + '%',
                }}
            >
                {!linkUrl && link ? (
                    <Link
                        to={{
                            pathname: linkUrll,
                            state: { tag },
                        }}
                        className='tag-link'
                    >
                        <TagStringComponent
                            tag={tag}
                            countResults={countResults}
                        />
                    </Link>
                ) : linkUrl && link ? (
                    <a className='tag-link' href={linkUrl}>
                        <TagStringComponent
                            tag={tag}
                            countResults={countResults}
                        />
                    </a>
                ) : (
                    <span className='tag-link'>
                        <TagStringComponent
                            tag={tag}
                            countResults={countResults}
                        />
                    </span>
                )}

            </span>
        </>
    );
};
