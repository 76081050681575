import './../scss/index.scss';
import React, { useState, useEffect, useMemo, useRef } from 'react';
import { Button } from 'antd';
import { PauseCircleFilled, PlayCircleFilled } from '@ant-design/icons';
import { useIntl } from 'react-intl';

import classnames from 'classnames';
import { useHistory } from 'react-router-dom';
import { useGetTaskCover } from '../../task/hooks/useGetTaskCover';
import { getChunksFromData } from './getChunksFromData'
import { VideoCustomPlayerContent } from './VideoCustomPlayerContent';
import { Svg } from '../Svg';

export const VideoCustomPlayerPreview = ({
    match,
}) => {
    const history = useHistory();
    const queryParams = new URLSearchParams(history.location.search);
    const { formatMessage } = useIntl();
    const audioRef = useRef(null);
    const { task, getTask } = useGetTaskCover();
    const [isPlay, setIsPlay] = useState(false);
    const [light, setLight] = useState(false);
    const [isDiagram, setIsDiagram] = useState(false);
    const [errors, setErrors] = useState()
    const [chunks, setChunks] = useState()
    const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
    const [currentVisual, setCurrentVisual] = useState(null);
    const [visualIndex, setVisualIndex] = useState(0);
    const [imageError, setImageError] = useState(false);

    const { mode, isMobile, isHideButtons, wmrk } = useMemo(() => {
        const mode = queryParams.get('mode') || 'black';
        const isMobile = queryParams.get('mobile') || '';
        const isHideButtons = !!queryParams.get('hideButtons');
        const wmrk = queryParams.get('wmrk');
        return {
            mode,
            isMobile,
            isHideButtons,
            wmrk,
        }
    }, [queryParams]);

    const currentSlide = useMemo(() => {
        return chunks?.length && chunks[currentSlideIndex];
    }, [chunks, currentSlideIndex]);

    const visualOptions = useMemo(() => {
        let options = [];
        if (currentSlide?.ill) {
            options.push("illustration");
        }
        if (currentSlide?.diagram && !errors) {
            options.push("diagram");
        }
        if (currentSlide?.images?.length && currentSlide.images[0]?.url) {
            options.push("image");
        }

        return options;
    }, [currentSlideIndex, errors, chunks]);

    useEffect(() => {
        if (!errors) {
            setVisualIndex(0);

        }
    }, [currentSlideIndex, errors]);

    useEffect(() => {
        setCurrentVisual(visualOptions[visualIndex]);
    }, [visualIndex, visualOptions]);

    const blockClasses = classnames('slides-right-panel slides-right-panel_imgs', {
        'slides-right-panel_img-centered': currentVisual === "images"
    });
    const videoClasses = classnames('videoContainerStyle', {
        light: light === true,
        dark: !light,
    });
    const leftColumnClasses = classnames('slides-content', {
        "slides-left-panel_wide": currentVisual === 'diagram',
    });

    useEffect(() => {
        if (mode === "simple" || mode === "tufte") {
            setLight(true);
        }
        if (mode === "black") {
            setLight(false);
        }

    }, [mode])

    useEffect(() => {
        setErrors(null);
        setImageError(null)
    }, [currentSlideIndex, isPlay]);

    useEffect(() => {
        (async () => {
            if (currentVisual === "diagram") {
                await setIsDiagram(false);
                setIsDiagram(true);
            }

        })();
    }, [currentVisual, currentSlideIndex])

    const modeClasses = classnames('', {
        simple: mode === "simple",
        tufte: mode === "tufte",
    });
    const sizeClasses = classnames('', {
        mobile: isMobile === true || isMobile === "true",
    });

    const mainIdeaNormalised = (main_idea) => {
        if (Array.isArray(main_idea)) {
            return main_idea;
        } else if (typeof main_idea === 'string') {
            return main_idea.split(',');
        }
    }
    useEffect(() => {
        if (match?.params?.id) {
            (async () => await getTask({
                variables: {
                    taskId: Number(match.params.id),
                }
            }))();
        }
    }, [match?.params?.id])

    useEffect(() => {
        let cover = task?.cover;
        if (cover?.type === 'VIDEO' && cover?.source === '') {
            let transformedData = getChunksFromData(cover)
            setChunks(transformedData);
        }
    }, [task?.cover?.id]);

    return (
        <div className={sizeClasses}>
            <div className={modeClasses}>
                <div className={videoClasses} >
                    <div className='sliderContainerStyle'>
                        <VideoCustomPlayerContent
                            currentSlide={currentSlide}
                            currentVisual={currentVisual}
                            isDiagram={isDiagram}
                            errors={errors}
                            setErrors={setErrors}
                            imageError={imageError}
                            setImageError={setImageError}
                            currentSlideIndex={currentSlideIndex}
                            leftColumnClasses={leftColumnClasses}
                            blockClasses={blockClasses}
                            mainIdeaNormalised={mainIdeaNormalised}
                            wmrk={wmrk}
                        />
                        <div className=' slides-toolbar'>
                            <audio
                                className="audio-player"
                                ref={audioRef}
                                src={currentSlide?.audio}
                                onEnded={() => {
                                    if (currentSlideIndex === chunks.length - 1) {
                                        setIsPlay(false);
                                    } else {
                                        setCurrentSlideIndex(currentSlideIndex + 1);
                                    }
                                }}
                                autoPlay={isPlay}
                            >
                                {formatMessage({ id: 'audio_compatability_warning' })}
                            </audio>
                            {!isHideButtons && (
                                <Button icon={
                                    isPlay ? <PauseCircleFilled style={{ fontSize: '30px' }} /> : <PlayCircleFilled style={{ fontSize: '30px' }} />}
                                    type="text"
                                    id="video-play"
                                    onClick={() => {
                                        if (isPlay) {
                                            audioRef.current?.pause();
                                            setIsPlay(false);
                                        } else {
                                            audioRef.current?.play();
                                            setIsPlay(true);
                                        }
                                    }} />
                            )}

                            {!isHideButtons && (
                                <div className="slider-navigation">
                                    {chunks?.map((item, index) => (
                                        <Button
                                            key={`button_${index}`}
                                            size="small"
                                            type="text"
                                            className={index === currentSlideIndex ? 'slider-navigation_btn slider-navigation_active' : 'slider-navigation_btn '}
                                            onClick={() => setCurrentSlideIndex(index)}
                                        >
                                            <hr />
                                        </Button>
                                    ))}
                                </div>
                            )}

                            {!isHideButtons && (
                                <div>
                                    <Button type="text" disabled={currentSlideIndex === 0} onClick={() => setCurrentSlideIndex(currentSlideIndex === 0 ? 0 : currentSlideIndex - 1)}>  <Svg name=':icon/arrow-left' fill="#FFFFFF" width={24} /></Button>
                                    <Button type="text" disabled={currentSlideIndex === chunks?.length - 1} onClick={() => setCurrentSlideIndex(currentSlideIndex === chunks?.length - 1 ? 0 : currentSlideIndex + 1)}>  <Svg name=':icon/arrow-right' fill="#FFFFFF" width={24} /></Button>
                                </div>
                            )}

                            {isHideButtons && (
                                <button type="button"
                                    className="opacity-hidden"
                                    id="next-slide"
                                    onClick={() => setCurrentSlideIndex(currentSlideIndex === chunks?.length - 1 ? 0 : currentSlideIndex + 1)} />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
