import './scss/index.scss';
import '../../rating/StudentsTable.component/scss/index.scss';

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Button, Table, Checkbox } from 'antd';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { noop } from 'lodash';
import { WorkspaceSelectSeparate } from '../../workspace/WorkspaceSelectSeparate.container';
import { useGetWorkspaces } from '../../workspace/hooks/useGetWorkspaces';

import {
    useTrackClick,
    TagComponent,
    Svg,
    ActionTableMenuComponent,
    useConvertedPrice
} from '../../common';
import { getGalleriesInEvent } from '../../event/getGalleriesInEvent';
import { useLocalePrefix } from '../../lang';
import { StudentsTable } from '../../rating/StudentsTable';
import { useEditEvent } from '../../event/hooks/useEditEvent';
import { EventsBatchEditContainer } from '../EventsBatchEdit.container';

export const EventsTableComponent = (props) => {
    const {
        events,
        // onUpdateWorkspace = noop,
        setIsFakeLoading = noop,
        setEvents = noop,
        isNotAllow,
        user,
        updateEvents = noop,
        ...other
    } = props;

    const trackClick = useTrackClick();
    const [localePrefix] = useLocalePrefix();
    const { getPrice } = useConvertedPrice();
    const { formatMessage } = useIntl();
    const { editEvent } = useEditEvent();
    const { getWorkspaces, workspaces, loading } = useGetWorkspaces();

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [expandedRowKeys, setExpandedRowKeys] = useState([]);

    const onExpand = (record, event) => {
        const newExpandedRowKeys = expandedRowKeys.includes(record.key)
            ? expandedRowKeys.filter(key => key !== record.key)
            : [...expandedRowKeys, record.key];
        setExpandedRowKeys(newExpandedRowKeys);
    };
    const reloadThePage = () => {
        setIsFakeLoading(true);
        updateEvents();
        // onUpdateWorkspace();
    }

    const handleChangeEvent = async (newData, event) => {
        await editEvent({
            variables: {
                eventId: event.id,
                eventData: newData,
            },
        })
        setEvents((prev) => prev.map(ev => ev.id === event.id
            ? { ...ev, workspaceIds: newData.workspaceIds }
            : ev));
        reloadThePage();
    };

    const processEvent = (event) => {
        const { title: tagTitle, subject: tagSubject, color: tagColorData } = event?.tagData || {};
        const finalTagColor = tagSubject?.color || tagColorData;
        let isBundle = event?.type === "BUNDLE";
        let eventIsNotAllow = isNotAllow && events?.[0]?.id !== event?.id
        let LinkClick = (eventIsNotAllow) ? `${localePrefix}/payments` : `${localePrefix}/${isBundle ? "bundles" : 'online-courses'}/${event.alias}-${event.id}`;

        const setEventDifficulty = () => {
            let difficulty = 0;
            event?.tasks?.forEach((task) => { difficulty += task?.difficultyId });

            let avg = difficulty / event?.tasks?.length;
            if (avg >= 1 && avg < 1.6) {
                return { level: 'easy', color: '#000000' };
            } else if (avg > 1.6 && avg < 2.4) {
                return { level: 'medium', color: '#000000' };
            } else if (avg > 2.5) {
                return { level: 'hard', color: '#000000' };
            } else {
                return { level: 'easy', color: '#000000' };
            }
        }
        let dif = setEventDifficulty();
        const options = { day: '2-digit', month: 'short', };
        let filter = eventIsNotAllow ? `grayscale(1)` : !event?.imgUrl ? `hue-rotate(${event?.deg}deg)` : ``;
        let progress = { allCount: event?.tasks?.length, count: 0 };

        let imgUrl = event?.galleriesInEvent?.[0]?.gallery?.type === "PHOTO" && event?.galleriesInEvent?.[0]?.gallery?.source;
        event?.tasks?.forEach(task => {
            if (task.stateResult === 'CORRECT' || task.stateResult === "INCORRECT") {
                progress.count += 1;
            }
        });

        return {
            ...event,
            tagTitle,
            finalTagColor,
            isBundle,
            LinkClick,
            dif,
            filter,
            imgUrl,
            progress,
            createDate: new Date(event?.createDate)?.toLocaleString('en-GB', options),
            tags: event?.tags,
            numberOfTasks: event?.tasks?.length,
            isDraft: event?.isDraft,
            price: event?.price?.forStudent,
            certificate: event?.certificate,
            drip: event?.drip,
            workspaceId: event?.workspaceId,
            workspaceIds: event?.workspaceIds
        };
    };

    const processedEvents = events?.filter(event => !!event).map(event => processEvent(event));

    const data = processedEvents?.map(event => ({
        key: event?.id,
        title: event?.title?.substring(0, 30),
        userSubscriptions: event.userSubscriptions,
        imgUrl: event?.imgUrl,
        event: event,
        // tagColor: event.finalTagColor,
        filter: event?.filter,
        isBundle: event?.isBundle,
        LinkClick: event?.LinkClick,
        createDate: event?.createDate,
        tags: event?.tags,
        numberOfTasks: event?.numberOfTasks,
        isDraft: event?.isDraft ? "❌" : "✅",
        price: getPrice(event?.price) || (!event?.isDraft && "Free"),
        certificate: !!event?.certificate && "🏆",
        drip: !!event?.drip && "💧",
        workspaceId: event?.workspaceId,
        workspaceIds: event?.workspaceIds
    }));
    const handleRedirect = (workspace) => {
        const URL =
            // res?.customDomain ? `https://${res?.customDomain}` :
            `https://${workspace?.host}.unschooler.me`
        window.open(URL, '_blank');

    };

    const columns = [
        {
            title: <Checkbox
                indeterminate={selectedRowKeys.length > 0 && selectedRowKeys.length < data.length}
                onChange={(e) => {
                    e.stopPropagation(); // Prevent default action
                    if (e.target.checked) {
                        setSelectedRowKeys(data.map(item => item.key));
                    } else {
                        setSelectedRowKeys([]);
                    }
                }}
                checked={selectedRowKeys.length === data.length}
            />,
            dataIndex: 'checkbox',
            render: (_, record) => (
                <Checkbox
                    checked={selectedRowKeys.includes(record.key)}
                    onChange={(e) => {
                        e.stopPropagation(); // Prevent default action
                        const newSelectedRowKeys = selectedRowKeys.includes(record.key)
                            ? selectedRowKeys.filter(key => key !== record.key)
                            : [...selectedRowKeys, record.key];
                        setSelectedRowKeys(newSelectedRowKeys);
                    }}
                />
            ),
        },

        {
            title: formatMessage({ id: 'event_title' }),
            dataIndex: 'title',
            key: 'title',
            sorter: (a, b) => a.title.localeCompare(b.title),
            render: (text, record) => (
                <Link
                    className='table_title'
                    onClick={() =>
                        trackClick(
                            'click-event-card',
                            record.event.id,
                            record.LinkClick
                        )
                    }
                    to={{ pathname: record.LinkClick, state: { workspace: record.workspace } }}
                >
                    <LazyLoadImage
                        src={record.imgUrl || getGalleriesInEvent(record.event?.id)}
                        alt={record.event?.title}
                        style={{
                            color: record.tagColor?.color,
                            filter: record.filter,
                            width: '30px',
                            borderRadius: '4px',
                        }}
                        className='margin_right'
                    />

                    <span className='table_title__text'
                        style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                        {text}
                    </span>
                    <p className='text_secondary'>
                        {record.isBundle && `${formatMessage({ id: 'bundle' })}`}
                    </p>
                </Link>
            ),
        },

        {
            title: formatMessage({ id: 'create' }),
            dataIndex: 'createDate',
            key: 'createDate',
            sorter: (a, b) => new Date(a.createDate) - new Date(b.createDate),
        },

        {
            title: formatMessage({ id: 'modules' }),
            dataIndex: 'numberOfTasks',
            key: 'numberOfTasks',
            sorter: (a, b) => a.numberOfTasks - b.numberOfTasks,
        },
        {
            title: formatMessage({ id: 'published' }),
            dataIndex: 'isDraft',
            key: 'isDraft',
            sorter: (a, b) => (a.isDraft ? 1 : 0) - (b.isDraft ? 1 : 0),
        },
        {
            title: formatMessage({ id: 'price_threebutton' }),
            dataIndex: 'price',
            key: 'price',
            sorter: (a, b) => (a.price || 0) - (b.price || 0),
        },
        // {
        //     title: 'Certificate',
        //     dataIndex: 'certificate',
        //     key: 'certificate',
        //     sorter: (a, b) => a.certificate.localeCompare(b.certificate),
        // },
        {
            title: formatMessage({ id: 'create_event_tab_4' }),
            dataIndex: 'drip',
            key: 'drip',
            sorter: (a, b) => (a.drip ? 1 : 0) - (b.drip ? 1 : 0),
        },
        {
            title: formatMessage({ id: 'school' }),
            dataIndex: 'workspaceIds',
            key: 'workspaceIds',
            sorter: (a, b) => a.workspaceIds?.length - b.workspaceIds?.length,
            render: (text, record) =>
            (
                <div style={{ cursor: 'pointer', width: '100%' }}>
                    <WorkspaceSelectSeparate
                        selectedWorkspaceIds={text}
                        onChange={handleChangeEvent}
                        event={record.event}
                        workspaces={workspaces}
                    />
                </div>
            ),
        },
        {
            title: formatMessage({ id: 'nav_skills' }),
            dataIndex: 'Tags',
            key: 'Tags',
            sorter: (a, b) => {
                if (a.tags?.length && b.tags?.length) {
                    return a.tags[0]?.title.localeCompare(b.tags[0]?.title);
                }
                return 0;
            },
            render: (text, record) => (
                <div className='table_tags'>
                    {record.tags?.map((tag, i) => (
                        <div
                            key={`skill-tag-${tag?.id}`}
                            className='skill_progress_inline'
                        >
                            <TagComponent
                                key={`tag_${i}`}
                                tag={tag}
                                // colorful={true}
                                link={false}
                                // link={!isWhiteLabeled}

                                {...other}
                            />
                        </div>

                    ))}
                </div>
            ),
        },
        {
            title: formatMessage({ id: 'students' }),
            dataIndex: 'userSubscriptions',
            key: 'userSubscriptions',
            sorter: (a, b) => a.userSubscriptions?.length - b.userSubscriptions?.length,
            render: (text, record) => (
                <div className='table_student '>
                    <span className=' margin_right'>
                        {record.event.userSubscriptions?.length}
                    </span>
                    {!!record.event.userSubscriptions?.length &&
                        <Button
                            type='text'
                            onClick={e => {
                                onExpand(record, e);
                                e.stopPropagation(); // Prevent row click event
                            }}
                        >

                            <span className=' link-btn_light'>
                                <Svg name=':icon/arrow_down' className='icon_16' />
                            </span>

                        </Button>
                    }

                </div>
            ),
        },
    ];

    useEffect(() => {
        if (user?.id) {
            getWorkspaces({
                variables: { ownerId: user?.id }
            });

        }
    }, [user?.id]);

    const getEventsFromSelectedRows = () => {
        let fd = []
        if (selectedRowKeys?.length > 0 && !!events) {
            selectedRowKeys.forEach(key => {
                fd.push(events.find(event => event.id === key))
            })
            return fd;
        }
        return fd;
    };
    const actions = [
        { component: EventsBatchEditContainer, },
    ];

    return (

        <div className="table-wrapper">
            {selectedRowKeys?.length > 0 && !!actions &&
                <ActionTableMenuComponent
                    actions={actions}
                    user={user}
                    events={getEventsFromSelectedRows()}
                    reloadThePage={reloadThePage}
                    {...other}
                />}
            <Table
                columns={columns}
                dataSource={data}
                pagination={false}
                expandable={{
                    expandedRowKeys,
                    onExpand: (expanded, record) => {
                        onExpand(record);
                    },
                    expandedRowRender: record => (
                        <>
                            <StudentsTable
                                userList={record?.event?.userSubscriptions}
                                event={record.event}
                                workspace={record.workspace}
                            />
                        </>
                    ),
                    expandRowByClick: false, // Disable expand on row click
                    expandIconColumnIndex: -1,
                }}
            />
        </div>
    )
};