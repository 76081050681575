
export const circles2 = (p) => {
    let circles = [];
    let circlesNum = 10;
    let w, h;

    p.setup = function () {
        if (p.canvas) {
            w = p.canvas?.parentElement?.offsetWidth || 400; // Fallback width if parent element is not found
            h = p.canvas?.parentElement?.offsetHeight || 400; // Fallback height
            p.createCanvas(w, h);
            for (let i = 0; i < circlesNum; i++) {
                let circle = new Circle();
                circles.push(circle);
            }
        }
    };

    p.draw = function () {
        p.clear();
        // First update and draw circles
        for (let i = 0; i < circles.length; i++) {
            let c = circles[i];
            c.update();
            c.draw();
        }
        // Then check for intersections and draw intersection points
        for (let i = 0; i < circles.length; i++) {
            for (let j = i + 1; j < circles.length; j++) {
                let c1 = circles[i];
                let c2 = circles[j];
                let d = p.dist(c1.x, c1.y, c2.x, c2.y);
                if ((d < c1.r + c2.r) && (d > Math.abs(c1.r - c2.r))) {
                    getIntersection(c1, c2, d);
                }
            }
        }
    
    };

    p.windowResized = function () {
        w = p?.canvas?.parentElement?.offsetWidth || 400; // Fallback width if parent element is not found
        h = p?.canvas?.parentElement?.offsetHeight || 400; // Fallback height
        p.resizeCanvas(w, h);
        for (let circle of circles) {
            circle.r = randomIntFromInterval(25, 170);
            circle.x = randomIntFromInterval(circle.r, p.width - circle.r);
            circle.y = randomIntFromInterval(circle.r, p.height - circle.r);
        }
    };

    class Circle {
        constructor() {
            this.r = randomIntFromInterval(25, 170);
            this.x = randomIntFromInterval(this.r, p.width - this.r);
            this.y = randomIntFromInterval(this.r, p.height - this.r);
            this.vx = randomIntFromInterval(25, 100) / 100;
            this.vy = randomIntFromInterval(25, 100) / 100;
        }

        update() {
            if (this.x < this.r || this.x > p.width - this.r) {
                this.vx *= -1;
            }
            if (this.y < this.r || this.y > p.height - this.r) {
                this.vy *= -1;
            }
            this.x += this.vx;
            this.y += this.vy;
        }

        draw() {
            p.stroke(255);   // Change this line
            p.noFill();
            p.ellipse(this.x, this.y, this.r * 2);
        }
    }

    function getIntersection(p1, p2, d) {
        // omitted for brevity: essentially the same as the original, but using p5 API calls

        // Isolate the parameters required.
        let a = (Math.pow(p1.r, 2) - Math.pow(p2.r, 2) + Math.pow(d, 2)) / (2 * d);
        let h = Math.sqrt(Math.pow(p1.r, 2) - Math.pow(a, 2));

        // Store the positional values of p3, p4, and p5.
        let p3 = {
            x: p1.x + a * (p2.x - p1.x) / d,
            y: p1.y + a * (p2.y - p1.y) / d,
        };
        let p4 = {
            x: p3.x + h * (p2.y - p1.y) / d,
            y: p3.y - h * (p2.x - p1.x) / d,
        };
        let p5 = {
            x: p3.x - h * (p2.y - p1.y) / d,
            y: p3.y + h * (p2.x - p1.x) / d,
        };

        // Mark p4 and p5.
        markPoint(p4);
        markPoint(p5);
    }

    function randomIntFromInterval(mn, mx) {
        return Math.floor(p.random(mn, mx + 1));
    }

    function markPoint(point) {
        p.fill(0);      // Set the fill for dots here
        p.noStroke();   // No stroke for dots
        p.ellipse(point.x, point.y, 5, 5);
        p.noFill();     // Disable fill for subsequent drawings
    }
}