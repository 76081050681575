import gql from 'graphql-tag';

export const GetResults = gql`
    query GetResults($filter: FilterResult) {
        results(filter: $filter) {
            id
            user {
                id
                name
                picture
                googleUid
            }
            cover {
                source
                type
                id
            }
            createDate
            description
            answers {
                answer
                options {
                    isCorrect
                    id
                }
                question {
                    id
                }
                questionId
            }
            state
            isLiked
            task {
                id
                title
                isAutogenerated
                tags {
                    subject {
                        countResults
                        title
                        id
                    }
                countResults
                title
                id
                }
            }
        }
    }
`;
