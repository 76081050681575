import React, { useState, useMemo } from 'react';
import { FreeResultFormComponent } from '../FreeResultForm.component';
import { LoginContainer } from '../../common';

export const FreeResultFormContainer = (props) => {
    const {
        as: Component = FreeResultFormComponent,
        user,
        task,
        result,
        setShowFreeResultForm,
        showFreeResultForm,
        loadingUpdateResult,
        isEmbed,
        ...other
    } = props;
    const [showLogin, setShowLogin] = useState();
    const questionsLength = useMemo(() => task?.questions?.length, [task?.id]);

    if (!!isEmbed) {
        return null;
    }

    return (
        <div className='wrapper_content-section'>
            <Component
                loadingUpdateResult={loadingUpdateResult}
                user={user}
                task={task}
                result={result}
                isSingleMode={!questionsLength}
                setShowFreeResultForm={setShowFreeResultForm}
                {...other}
            />

            {showLogin && (
                <LoginContainer
                    showLogin={showLogin}
                    setCancelLogin={setShowLogin}
                />
            )}
        </div>
    );
};
