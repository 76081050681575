// import React from 'react';
// import {LazyLoadImage} from 'react-lazy-load-image-component';

// export const ProgramsSubjectIconComponent = (props) => {
//     const {achievement, classImage, width, deg} = props;

//     if (!achievement) return null;
//     console.log(achievement, 'achievement')
//     return (
//         <div
//             className='program-subject__item'
//             style={{
//                 width: width,
//                 color: achievement?.color,
//                 filter: `invert(10%) sepia(10%) saturate(158%) hue-rotate(${deg}deg)`,
//             }}
//         >
//             <LazyLoadImage
//                 className={classImage}
//                 src={`https://storage.googleapis.com/cdn.unschooler.me/web_app/achievement/badges/${achievement?.icon}.svg`}
//                 alt='subject logo'
//                 width={width}
//             />
//         </div>
//     );
// };

import React, {useEffect, useState} from "react";
import {LazyLoadImage} from 'react-lazy-load-image-component';

export const ProgramsSubjectIconComponent = (props) => {
    const {source = 'default.svg', width, classImage} = props;

    const [icon, setIcon] = useState(null);
    const iconPath = `./resourses/media/svg/${source}`;

    useEffect(() => {
        (async () => {
            try {
                const iconSourse = await import(`${iconPath}`);
                setIcon(iconSourse.default);
            } catch (e) {
                console.warn(e);
            }
        })();
        return () => {
            setIcon(null);
        }
    }, [iconPath]);

    return (icon && <div className="program-subject__item program-subject__item_icon ">
            <LazyLoadImage className={classImage}
                           src={icon}
                           alt="subject logo"/>
        </div>
    );
};
