
import {noise} from "./assets/perlin";

export const vector = (p) => {
    const _noise = noise;
    let detail = 36;
    let lineLength = 36;
    let lines = [];
    let w, h;
    class Line {
        constructor(x, y) {
            this.x = x;
            this.y = y;
            this._x = this.x * 0.002;
            this._y = this.y * 0.002;
        }
        draw() {
            const noiseValue = _noise.simplex3(this._x, this._y, p.frameCount * 0.004);
            p.translate(this.x + detail / 2, this.y + detail / 2);
            p.rotate(p.map(noiseValue, -1, 1, 0, p.TWO_PI));
            p.stroke(p.map(noiseValue, -1, 1, 0, 255));
            p.line(-lineLength * 0.5, 0, lineLength * 0.5, 0);
            p.resetMatrix();
        }
    }

    p.setup = () => {
        w = p?.canvas?.parentElement?.offsetWidth || 400; // Fallback width if parent element is not found
        h = p?.canvas?.parentElement?.offsetHeight || 400; // Fallback height
        p.createCanvas(w, h);
        p.stroke(255);
        p.strokeWeight(2);
        p.blendMode(p.BLEND);

        for (let y = 0; y < p.height; y += detail) {
            for (let x = 0; x < p.width; x += detail) {
                lines.push(new Line(x, y));
            }
        }
    }

    p.draw = () => {
        p.clear();
        lines?.forEach(l => l.draw());
    }

    p.windowResized = () => {
        w = p?.canvas?.parentElement?.offsetWidth || 400; // Fallback width if parent element is not found
        h = p?.canvas?.parentElement?.offsetHeight || 400; // Fallback height
        p.resizeCanvas(w, h);
        noise.seed(p.random(100));
        lines = [];
        for (let y = 0; y < p.height; y += detail) {
            for (let x = 0; x < p.width; x += detail) {
                lines.push(new Line(x, y));
            }
        }
        p.draw();
    }
}
