/* eslint-disable import/no-anonymous-default-export */
import en from './en_US.json';
import ru from './ru.json';
import es from './es.json';
import hi from './hi.json';
import de from './de.json';
import zhc from './zhc.json';
import zht from './zht.json';
import ptb from './ptb.json';
import pl from './pl.json';
import fr from './fr.json';
import ar from './ar.json';
import sv from './sv.json';
import fn from './fn.json';
import ml from './ml.json';
import it from './it.json';
import he from './he.json';
import ja from './ja.json';
import nl from './nl.json';
import id from './id.json';
import ro from './ro.json';
import ms from './ms.json';
import tr from './tr.json';
import fil from './fil.json';
import no from './no.json';
import hr from './hr.json';
import cs from './cs.json';
import ko from './ko.json';
import km from './km.json';
import uk from './uk.json';
import pte from './pte.json';

export default {
    ...en,
    ...ru,
    ...es,
    ...hi,
    ...de,
    ...zhc,
    ...zht,
    ...ptb,
    ...fr,
    ...pl,
    ...ar,
    ...sv,
    ...fn,
    ...ml,
    ...it,
    ...he,
    ...ja,
    ...nl,
    ...id,
    ...ro,
    ...ms,
    ...tr,
    ...fil,
    ...no,
    ...hr,
    ...cs,
    ...ko,
    ...km,
    ...uk,
    ...pte,
};
