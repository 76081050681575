import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';
import Intercom from '@intercom/messenger-js-sdk';

import classnames from 'classnames';
import { RequestsCounterContainer } from './admin/payments';
import { LangSwitcherContainer, useLocalePrefix } from './lang';
import {
    ThemeSwitcherComponent, useIsAllow, SignInComponent, Svg,
    useResolutions, AvatarCustom
} from './common';

export const NavBar = (props) => {
    const { user, isAllowPro } = props;
    const { pathname } = useLocation();
    const { isSMdown } = useResolutions();
    const [visible, setVisible] = useState(!isSMdown);
    const [localePrefix] = useLocalePrefix();
    const { formatMessage } = useIntl();

    Intercom({
        app_id: 'wdkadmmv',
        user_id: user?.id,
        name: user?.name,
        subscription: user?.subscription?.type,
        email: user?.email,
    });

    const handleToggleSidebar = () => {
        setVisible((prev) => !prev);
    };

    const sidebarClassName = classnames('sidebar', {
        sidebar_hidden: !visible,
    });

    const sidebarButtonClassName = classnames('sidebar-button ', {
        'sidebar-button_active': visible,
    });

    useEffect(() => {
        setVisible(!isSMdown);
    }, [isSMdown]);

    useEffect(() => {
        if (isSMdown && visible) {
            setVisible(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);
    const useIsAllowd = useIsAllow();
    const userIsAllow = useIsAllowd("START");

    return (
        <>
            {isSMdown && (
                <div
                    className={sidebarButtonClassName}
                    onClick={handleToggleSidebar}
                >
                    {/* {visible && <span className='nav-list__item__link__text inline-el_margin'>{formatMessage({id: 'close'})}</span>} */}
                    <Svg name=':icon/hamburger' className='menu-icon' />
                </div>
            )}

            <nav className={sidebarClassName}>
                <div className='nav-list'>

                    {!user?.id && (
                        <NavLink
                            exact
                            className='nav-list__item__link'
                            to={`${localePrefix}/`}
                            activeClassName='active'
                        >
                            <Svg name=':icon/logo-full_star' className='menu-icon' />
                            <span className='nav-list__item__link__text'>
                            {formatMessage({ id: 'nav_home' })}
                        </span>
                        </NavLink>
                    )}
                    <NavLink
                        exact
                        className='nav-list__item__link'
                        to={`${localePrefix}/schools/`}
                        activeClassName='active'
                    >
                        <Svg name=':icon/logo-full_star' className='menu-icon' />
                        <span className='nav-list__item__link__text'>
                            {formatMessage({ id: 'nav_workspace' })}
                        </span>
                    </NavLink>


                    <NavLink
                        exact
                        className='nav-list__item__link '
                        to={`${localePrefix}/online-courses/`}
                        activeClassName='active'
                    >
                        {/* <Svg name=':icon/rating' className='menu-icon' /> */}
                        <span
                            className='menu-icon_type'
                            style={{ fontSize: 26 }}
                        >
                            ✦
                        </span>

                        <span className='nav-list__item__link__text'>
                            {formatMessage({ id: 'nav_ask' })}
                        </span>
                    </NavLink>

                    {user?.id &&
                        <>
                            {/*<NavLink*/}
                            {/*    exact*/}
                            {/*    className='nav-list__item__link '*/}
                            {/*    to={`${localePrefix}/skills/`}*/}
                            {/*    activeClassName='active'*/}
                            {/*>*/}
                            {/*    <span className='menu-icon_type' style={{ fontSize: 22 }}>*/}
                            {/*        ★*/}
                            {/*    </span>*/}
                            {/*    <span className='nav-list__item__link__text'>*/}
                            {/*        {formatMessage({ id: 'nav_skills' })}*/}
                            {/*    </span>*/}
                            {/*</NavLink>*/}

                            <NavLink
                                exact
                                className='nav-list__item__link'
                                to={`${localePrefix}/careers/`}
                            >
                                <span className='menu-icon_type' style={{ fontSize: 22 }}>
                                    ▲
                                </span>
                                <span className='nav-list__item__link__text'>
                                    {formatMessage({ id: 'nav_tutorials' })}
                                </span>
                            </NavLink>
                        </>
                    }

                    <NavLink
                        className='nav-list__item__link'
                        to="/online-courses/unschooler-guides-30917"
                    >

                        <Svg name=':icon/quiz' className='menu-icon' />
                        <span className='nav-list__item__link__text'>
                            {formatMessage({ id: 'nav_help' })}
                        </span>
                    </NavLink>

                    <NavLink
                        className='nav-list__item__link'
                        to={`${localePrefix}/payments`}
                        exact
                        activeClassName='active'
                    >
                        <Svg name=':icon/crown' className='menu-icon' />

                        <span className='nav-list__item__link__text'>
                            {userIsAllow
                                ? formatMessage({ id: 'nav_premium' })
                                : formatMessage({ id: 'nav_upgrade' })}
                        </span>
                    </NavLink>
                </div>

                <div className='nav-list nav-list_bottom'>
                    {user.id ? (
                        <NavLink exact
                            type='text'
                            className='nav-list__item__link '
                            to={`${localePrefix}/profile`}
                        >
                            <AvatarCustom user={user} size={24} />
                            {isSMdown &&
                                <span className='nav-list__item__link__text'>
                                    {user.name}
                                </span>
                            }
                        </NavLink>
                    )
                        : isSMdown ?
                            <div className='nav-list__item__link'>
                                <SignInComponent />
                            </div>

                            // <AuthButtonComponent />
                            : null}

                    {user && user?.role?.id === 1 && (
                        <>
                            <NavLink
                                exact
                                className='nav-list__item__link'

                                to={`${localePrefix}/admin`}
                                activeClassName='active'
                            >
                                <Svg name=':icon/admin' className='menu-icon' />
                                {isSMdown && (
                                    <span className='nav-list__item__link__text'>
                                        админка
                                    </span>
                                )}
                            </NavLink>
                        </>
                    )}
                    <div className='nav-list__item__link'>
                        <LangSwitcherContainer user={user} />
                    </div>
                    <div className='nav-list__item__link'>
                        <ThemeSwitcherComponent />
                    </div>

                    {user?.role?.id === 1 && <RequestsCounterContainer />}
                </div>
            </nav>

        </>
    );
};
