import React from 'react';
import { Link } from 'react-router-dom';

import { TaskInfoComponent } from './';
import { useTrackClick } from '../common';
import { useLocalePrefix } from '../lang';

export const TaskLink = (props) => {
    const {
        task,
        actionHidden,
        index,
        user,
        viewer,
        handleParticipate,
        taskUrl,
        workspace,
        onSetTheme,
        isEmbed,
        ...other
    } = props;
    const trackClick = useTrackClick();
    const [localePrefix] = useLocalePrefix();
    const taskLink = isEmbed ? `${localePrefix}/embed/tutorials/${taskUrl}` : `${localePrefix}/tutorials/${taskUrl}`;
    return (
        <Link
            className='answer-component_card'
            to={{ pathname: taskLink, state: { workspace } }}
            onClick={() =>
                trackClick(
                    'click-task',
                    task.id,
                    `https://tasks${localePrefix}/tutorials/${taskUrl}`
                )
            }
        >
            <TaskInfoComponent
                task={task}
                onSetTheme={onSetTheme}
                actionHidden={actionHidden}
                index={index}
                user={user}
                viewer={viewer}
                handleParticipate={handleParticipate}
                {...other}
            />
        </Link>
    );
};
