import './scss/index.scss';
import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { LandingPromoComponent } from '../LandingPromo.component/LandingPromo.component';
import { StepCardComponent } from '../StepCard.component/StepCard.component';
import { BeltComponent } from '../Belt.component/Belt.component';

import { FeatureComponent } from '../Feature.component/Feature.component';
import { LandingTextCards } from '../LandingTextCards.component/LandingTextCards.component';
import { SketchP5Component } from '../../graph/SketchP5Component';
import { FooterContainer } from '../../footer';
import { SimpleEventsListContainer } from '../../events';
import { LoginContainer, useTrackClick } from '../../common';

import {
    FEATURES_DESCRIPTION,
    LOGOS,
    MENTORS,
    HOW_IT_WORKS,
    SCREENS,
    LANDING_EVENTS_LIST,
} from '../../constant';
import { InfoCard } from '../../common';

export const LandingEnComponent = (prop) => {
    const trackClick = useTrackClick();
    const scrollToClasses = useRef();
    const handleScrollClick = () => {
        scrollToClasses.current.scrollIntoView();
    };
    const [showLogin, setShowLogin] = useState();
    const handleLogin = () => {
        trackClick('click-login', 'course-tutorial');
        setShowLogin(true);
    };

    return (
        <div className='landing_dark '>
            <div className='first_promo'>
                <LandingPromoComponent
                    handleScrollClick={handleScrollClick}
                    trackClick={trackClick}
                    handleLogin={handleLogin}
                />

            </div>
            <div className=' wrapper_hero '>
                <h2 >
                    Create your AI-course in minutes
                </h2>

                <Link
                    to='/payments'
                    className='link-btn_primary ant-btn ant-btn-primary ant-btn-lg margin_right '
                >
                    <span>Start free trial</span>
                </Link>
            </div>

            {/* courses */}
            <div className='wrapper_promo-section'>
                <LandingTextCards
                    title='AI takes your boring job'
                    description="Yet you still get paid: Collapse the course creation from months to minutes."
                    icon='how-promo'
                />
                <SimpleEventsListContainer eventsList={LANDING_EVENTS_LIST} openFirtsLesson={true} />
            </div>

            <div className='wrapper_promo-section promo_red'>
                <LandingTextCards
                    icon='learning-space'
                    title='AI LMS for colleges and universities'
                    description='It takes 10 minutes to create a school, 2 of which will be spent reading this page. Embed courses into your platform, share with students for free or sell.'
                />

                <div className='cards_info wrapper_outer-section'>
                    <div className='step_wrapper_workspace'>
                        <div className='step_workspace'>
                            <LazyLoadImage
                                src={SCREENS[0].url}
                                alt={SCREENS[0].alt}
                            />
                        </div>
                    </div>

                    <div className='step_wrapper_workspace step_2'>
                        <div className='step_workspace'>
                            <LazyLoadImage
                                src={SCREENS[2].url}
                                alt={SCREENS[2].alt}
                            />
                        </div>
                    </div>

                    {/* <div className='step_wrapper_workspace step_3'>
                        <div className='step_workspace'>
                            <LazyLoadImage
                                src={SCREENS[2].url}
                                alt={SCREENS[2].alt}
                            />
                        </div>
                    </div> */}

                </div>

                <LandingTextCards
                    title='Turn knowledge into system'
                    description="Unschooler generates videos, diagrams, pictures, illustrations, quizzes, practical tasks, and organizes them into an educational system, that leads students towards goals."
                    icon='your-content'
                />

                <div>


                    <p className='text_large'>Join 60,000+ students, 300+ institutions from 40+ countries</p>
                    {/* <div className='cards wrapper_outer-section'>
                        {LOGOS.map((feature, i) => (
                            <div key={`${feature.title}-${i}`} className='logo_customer'>
                                <LazyLoadImage
                                    src={feature.src}
                                    alt={feature.title}
                                    height={70}
                                />
                            </div>
                        ))}
                    </div> */}
                </div>
            </div>
            <div className=' wrapper_hero '>
                <h2 >
                    Create your online-school with courses
                </h2>

                <Link
                    to='/payments'
                    className='link-btn_primary ant-btn margin_right '
                >
                    <span>Start free trial</span>
                </Link>
            </div>


            {/* how it works */}
            <div className='wrapper_promo-section'>
                <LandingTextCards
                    title='Your content, your voice'
                    description='Convert your articles, YouTube channel or website into step-by-step courses, with quizzes, practical tasks and assessments.'
                    icon='your-content'
                />
                {HOW_IT_WORKS.map((step, i) => (
                    <StepCardComponent
                        key={`how-${i}`}
                        description={step.description}
                        src={step.src}
                        index={i + 1}
                        color={step.color}
                        shear={step.shear}
                    />
                ))}

            </div>

            {/* why learn*/}
            <div className='wrapper_promo-section'>
                <div className='cards grow-cards wrapper_outer-section'>
                    {FEATURES_DESCRIPTION.map((feature, i) => (
                        <div
                            key={`${feature?.title}-${i}`}
                            className='card_30 card_margin'
                        >
                            <FeatureComponent
                                title={feature?.title}
                                src={feature.src}
                                color={feature.color}
                                description={feature.description}
                            />
                        </div>
                    ))}
                </div>
                {/* <LandingTextCards
                    // imgSrc='https://cdn.unschooler.me/landing/stick-who.svg'
                    title='Why Unschooler?'
                    description='We are a startup based in Estonia that help people find a passion by moving beyond their information bubble.'
                // icon='who-promo'
                /> */}

            </div>

            <BeltComponent string={'free trial ✴︎ free trial ✴︎ free trial ✴︎ free trial ✴︎ free trial ✴︎ '} />
            <div className='btn_center wrapper_promo-section'>
                <div>
                    <p className='wrapper_outer-section'>
                        Unsure if this meets your needs? <br />Book a live 15-minute demo today and start a 14-day free trial for your school ↓
                    </p>
                    <a
                        href='https://calendly.com/lena_unschooler/interview'
                        target='_blank'
                        rel='noreferrer'
                        className='link-btn_primary'
                    >
                        <span>Book the demo for educators</span>
                    </a>
                </div>
            </div>
            <div className='page'>
                <FooterContainer />
            </div>

            <LoginContainer
                showLogin={showLogin}
                setCancelLogin={setShowLogin}
            />
        </div>
    );
};
