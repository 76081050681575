
export const checkResult = (result, question) => {
    const answer = result?.answers?.find((el) => el?.questionId === question?.id);
    if (answer) {
        if (question.type === "SINGLE_SELECT" || question.type === "MULTI_SELECT") {
            return answer?.options[0]?.isCorrect ? 'CORRECT' : 'INCORRECT';

        } else if (question.type === "TEXT" || question?.type === 'DEFAULT') {
            if (answer?.answer?.includes("1/10") || answer?.answer?.includes("2/10")) {
                return 'INCORRECT';
            } else {
                return 'CORRECT';

            }
        }
    }
}

export const checkAnswer = (answer) => {
    if (answer.question.type === "SINGLE_SELECT" || answer.question.type === "MULTI_SELECT") {
        return answer?.options[0]?.isCorrect ? '✅' : '❌';
    } else if (answer.question.type === "TEXT") {
        if (answer?.answer?.includes("1/10") || answer?.answer?.includes("2/10")) {
            return '❌';
        } else {
            return '✅';

        }
    }
}
export const checkTask = (result) => {
    if (result === "INCORRECT") {
        return '❌';
    } else if (result === "CORRECT") {
        return '✅';
    }
}