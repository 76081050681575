import { gql } from '@apollo/client';

export const GetUserCertificate = gql`
    query GetUserCertificate($id: Int, $userId: Int, $certificateId: Int) {
        getUserCertificate(id: $id, userId: $userId, certificateId: $certificateId) {
            id
            userName
            userId
            user {
                id
                picture
            }
            certificateId
            certificate {
                event {
                    title
                    alias
                    id
                    creator {
                        name
                    }
                }
                description
                title
                label
                id
                icon
            }
            createdDate
        }
    }
`;