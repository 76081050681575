import { noise } from "./assets/perlin";

export const vector_static = (p) => {
    let detail = 36;
    const _noise = noise;
    let lineLength = 36;
    let lines = [];
    let w, h;
    let hoverRadius = 100; // Radius around the cursor for interaction

    class Line {
        constructor(x, y) {
            this.x = x;
            this.y = y;
            this._x = this.x * 0.002;
            this._y = this.y * 0.002;
            this.angle = this.calculateAngle(); // Initial static angle
        }

        calculateAngle() {
            // Calculate the static angle based on the position
            // Adjust this to fit the pattern in the uploaded image
            let angle = (Math.atan2(this.y - h / 2, this.x - w / 2) + p.HALF_PI) * 4;
            return angle;
        }

        draw() {
            p.push();

            p.translate(this.x, this.y);

            // Check the distance from the cursor to the line
            if (p.dist(p.mouseX, p.mouseY, this.x, this.y) < hoverRadius) {
                // If within hoverRadius, rotate based on noise
                let noiseValue = noise.simplex3(this.x * 0.005, this.y * 0.005, p.frameCount * 0.005);
                p.rotate(
                    noiseValue * 
                    p.HALF_PI);
            } else {
                // Else, keep the line at its initial static angle
                p.rotate(this.angle);
            }
            const noiseValue = _noise.simplex3(this._x, this._y, p.frameCount * 0.004);
            const alpha = p.map(noiseValue, -1, 7, 0, 255);
            // Set the stroke to white with the calculated alpha value for opacity
            p.stroke(255, 255, 255, alpha);
            p.line(-lineLength / 2, 0, lineLength / 2, 0);
            p.pop();
        }
    }

    p.setup = () => {
        w = p?.canvas?.parentElement?.offsetWidth || p.windowWidth;
        h = p?.canvas?.parentElement?.offsetHeight || p.windowHeight;
        p.createCanvas(w, h);
        p.strokeWeight(2);
        p.background(0); // Set a background color if needed
 
        // Create the lines array
        for (let y = 0; y < h; y += detail) {
            for (let x = 0; x < w; x += detail) {
                lines.push(new Line(x, y));
            }
        }
    }

    p.draw = () => {
        // Clear the background if you want the lines to leave a trail,
        // otherwise remove this line to have a static background
        p.clear();

        // Redraw lines every frame
        lines.forEach(line => line.draw());
    }

    // Update the canvas size when the window is resized
    p.windowResized = () => {
        w = p?.canvas?.parentElement?.offsetWidth || p.windowWidth;
        h = p?.canvas?.parentElement?.offsetHeight || p.windowHeight;
        p.resizeCanvas(w, h);
    }
}
