import './scss/index.scss';
import React from 'react';
import { ResultContainer } from '../Result.container';
import { useIsPermit } from '../../roles/hook/useCheckPermission';

export const ResultListContainer = (props) => {
    const { results, task, user,userIsAllowGenerateContent, workspace, ...other } = props;
    const creatorOrAdmin =
        user?.id === task.creator.id ||
        (user && user?.role?.id === 1);
        
        const { checkPermit } = useIsPermit()
        const inviteStudents = checkPermit(workspace, 'inviteStudents')

    return (
        <div className='result-list-container'>
            {(creatorOrAdmin || inviteStudents) &&
                results.map((result) => (
                    <ResultContainer
                        key={result?.id}
                        {...other}
                        result={result}
                        user={user}
                    />
                ))}

        </div>
    );
};
